'use client';

import { createContext, useReducer, useMemo, type ReactNode } from 'react';
import {
  ToastNotification,
  type ToastNotificationProps,
} from '../../ui/Notification/ToastNotification';

const MAX_TOASTS = 3;

type Action =
  | { type: 'add'; payload: ToastNotificationProps }
  | { type: 'remove'; payload: { toastId: ToastNotificationProps['toastId'] } }
  | { type: 'init' };

export type NotificationContextType = {
  addNotification: (toast: Omit<ToastNotificationProps, 'toastId'>) => void;
  removeNotification: (toastId: ToastNotificationProps['toastId']) => void;
  init: () => void;
};

const reducer = (state: ToastNotificationProps[], action: Action): ToastNotificationProps[] => {
  switch (action.type) {
    case 'add':
      return [...state, { ...action.payload }].slice(-MAX_TOASTS);
    case 'remove':
      return state.filter((toast) => toast.toastId !== action.payload?.toastId);
    case 'init':
      return [];
    default:
      return state;
  }
};

export const NotificationContext = createContext<NotificationContextType>({
  addNotification: () => {},
  removeNotification() {},
  init: () => {},
});

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [notifications, dispatch] = useReducer(reducer, []);

  const addNotification = (toast: Omit<ToastNotificationProps, 'toastId'>) => {
    const toastId = Date.now();

    dispatch({ type: 'add', payload: { ...toast, toastId } });
  };

  const removeNotification = (toastId: ToastNotificationProps['toastId']) =>
    dispatch({ type: 'remove', payload: { toastId } });

  const value = useMemo(
    () => ({
      addNotification,
      removeNotification,
      init: () => dispatch({ type: 'init' }),
    }),
    [],
  );

  return (
    <NotificationContext.Provider value={value}>
      <div
        className="fixed top-3 z-[1001] flex max-h-screen w-[90%] -translate-x-1/2 flex-col gap-1 sm:left-1/2 md:max-w-[600px]"
        role="presentation"
      >
        {notifications.map((notification) => (
          <ToastNotification
            {...notification}
            key={notification.toastId}
            removeNotification={removeNotification}
          />
        ))}
      </div>
      {children}
    </NotificationContext.Provider>
  );
};
