import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import Box from '@mui/material/Box';
import { registerFlexibleComponentsFooter } from '../../components/ComponentRecognizer/registerFlexibleComponentsFooter';
import type { CmsData } from '../../../interfaces/webapp_json';
import { FooterAccordion } from './FooterAccordion';
import { BlockContent } from '../../components/Footer/BlockContent';
import { AreaTop } from '../../components/Footer/AreaTop';
import { AreaSocket } from '../../components/Footer/AreaSocket';

// register the dynamic components
registerFlexibleComponentsFooter();

type PageRendererProps = {
  data: CmsData;
  socketOnly?: boolean;
};

export enum PageRendererTestIds {
  main = 'page-renderer',
  mainMob = 'page-renderer-mob',
}

export const PageRenderer = ({ data, socketOnly = false }: PageRendererProps) => {
  const { isMobile } = useDeviceType();

  return (
    <>
      {!socketOnly && <AreaTop data={data} />}
      <Box
        sx={({ spacing }) => ({
          backgroundColor: 'footer.main',
          '& div, & a, & p, & span, & .dangerously-html p a': {
            color: 'footer.contrastText',
          },
          '& a:hover': {
            textDecoration: 'underline',
            color: 'footer.contrastText',
          },
          ...(isMobile
            ? {
                m: spacing(socketOnly ? 11.5 : 6.5, 'auto', 0, 'auto'),
                p: 0,
                typography: 'body1',
              }
            : {
                typography: 'caption',
                p: {
                  margin: 0,
                  '+ p': {
                    pt: 1,
                  },
                },
                '& .footer_block_badges': {
                  display: 'flex',
                  gap: 1.5,
                },
              }),
        })}
        data-testid={isMobile ? PageRendererTestIds.mainMob : PageRendererTestIds.main}
      >
        {!socketOnly && (isMobile ? <FooterAccordion data={data} /> : <BlockContent data={data} />)}
        <AreaSocket data={data} />
      </Box>
    </>
  );
};
