'use client';

import { PageRenderer } from './PageRenderer';
import { useFooterData } from '../../hooks/useFooterData';
import type { FooterProps } from './types';

/**
 * Footer component description displayed in storybook
 *
 * @param data Object contains data
 * @returns {JSX.Element|null} to be placed in @apps/shopping pages or null if no data exist
 */
export const Footer = ({ type = 'full' }: FooterProps): JSX.Element | null => {
  const data = useFooterData();

  if (data) {
    return (
      <PageRenderer key="page-renderer-footer-module" data={data} socketOnly={type === 'socket'} />
    );
  }

  return null;
};
