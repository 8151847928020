import DOMPurify from 'isomorphic-dompurify';

/**
 * configures and exports a DOMPurify instance with custom settings.
 *
 * Example usage:
 * ```typescript
 * import DOMPurify from '@packages/config/utils/dompurify';
 * const sanitizedHTML = DOMPurify.sanitize(`<a href="test.com" target="_blank">test</a>`);
 * ```
 */
DOMPurify.setConfig({
  ADD_ATTR: ['target'],
});

export default DOMPurify;
