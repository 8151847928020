import { useCallback, useRef } from 'react';
import { useAbTesting } from '../useAbTesting';
import { useFirstMouseMovement } from '../useFirstMouseMovement/useFirstMouseMovement';

/**
 * Sends an outcome of {MOUSEMOVEMENT: 1} for all active tests, if the mouse has been moved at least once. This is used to discard bots from the test results.
 *
 * This hook listens for the first mouse movement and then starts an interval that runs every 1000ms.
 * The interval checks for any new test IDs that might have been added to the cookie afterwards and sends the mouse movement outcome for those tests.
 *
 * It is intented to be used only once on a central location and not as needed on multiple components.
 */
export const useAutomaticMouseMovementOutcome = () => {
  const { setOutcome, getAllTestIds } = useAbTesting();
  const testIdsAlreadySent = useRef<Set<string>>(new Set());

  const setOutcomeForAllActiveTests = useCallback(() => {
    const allActiveTestIds = getAllTestIds();

    allActiveTestIds.forEach((testId) => {
      if (!testIdsAlreadySent.current.has(testId)) {
        setOutcome(testId, { MOUSEMOVEMENT: 1 });
        testIdsAlreadySent.current.add(testId);
      }
    });
  }, [getAllTestIds, setOutcome]);

  const mouseMovementCallback = useCallback(async () => {
    setOutcomeForAllActiveTests();
    setInterval(() => {
      setOutcomeForAllActiveTests();
    }, 1000);
  }, [setOutcomeForAllActiveTests]);

  useFirstMouseMovement(mouseMovementCallback);
};
