import { Box } from '@packages/shared';
import type { FC } from 'react';
import { keyframes } from '@mui/system';
import type { NavigationData, NavItem } from '../types';
import { NavListItem } from './NavItem';
import { SubNavigation } from './SubNavigation';

const fadeIn = keyframes`
  0% { left: 100%; }
`;

export const NavigationList: FC<{
  navigation: NavigationData;
  activeItem?: NavItem;
  setActiveItem: (item?: NavItem) => void;
}> = ({ navigation, activeItem, setActiveItem }) => (
  <>
    <Box
      component="nav"
      sx={{
        pl: 2,
        position: 'absolute',
        inset: 0,
        overflow: 'auto',
      }}
    >
      {navigation.map((item) => (
        <NavListItem
          item={item}
          key={item.uri}
          setActiveItem={setActiveItem}
          isActive={item === activeItem}
          showIcon
        />
      ))}
    </Box>
    {activeItem && (
      <Box
        sx={{
          backgroundColor: 'common.white',
          position: 'absolute',
          left: '4.25rem',
          top: 0,
          bottom: 0,
          width: 'calc(100% - 4.25rem)',
          animation: `${fadeIn} 0.25s 0s ease-in-out`,
          boxShadow: 1,
          overflow: 'hidden',
        }}
        data-testid="nav-overlay"
      >
        <SubNavigation item={activeItem} />
      </Box>
    )}
  </>
);
