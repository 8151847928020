import { useAssignLinkTargetBasedOnBotStatus } from './useAssignLinkTargetBasedOnBotStatus';

/**
 * A custom React Hook that processes an HTML string and replaces all href attributes of anchor tags with the result of `useAssignLinkTargetBasedOnBotStatus`.
 *
 * @returns {Function} A function that takes an HTML string as input and returns the processed HTML string with updated href attributes.
 */
export const useProcessHtmlLinks = (): Function => {
  const assignLinkTarget = useAssignLinkTargetBasedOnBotStatus();

  return (html: string): string => {
    const linkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"/g;
    let match = linkRegex.exec(html);
    let processedHtml = html;

    while (match !== null) {
      const originalLink = match[1];
      const processedLink = assignLinkTarget(originalLink);

      processedHtml = processedHtml.replace(originalLink, processedLink);
      match = linkRegex.exec(html);
    }

    return processedHtml;
  };
};
