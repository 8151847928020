'use client';

import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useMemo } from 'react';
import config from '@packages/config/src';
import type { Config } from '@packages/config/src';

export type ConfigProviderProps = {
  config?: Config;
  children: ReactNode;
};

export type ConfigContextT = {
  config: Config;
};

export const ConfigContext = createContext<ConfigContextT>({ config });

export const ConfigProvider: FunctionComponent<ConfigProviderProps> = ({
  config: newConfig,
  children,
}) => {
  const providerConfig = useMemo(
    () => ({
      config: newConfig || config,
    }),
    [newConfig],
  );

  return <ConfigContext.Provider value={providerConfig}>{children}</ConfigContext.Provider>;
};
