/**
 * split url by '?' and return first part.
 * @param url the url to split
 * @returns the canonical url
 */
export const getCanonicalUrl = (url: string): string => {
  const canonicalUrl = url.split('?')[0];
  const slug = url.match(/slug=([^&]*)/)?.[1];
  const akl = url.match(/akl=([^&]*)/)?.[1];

  return canonicalUrl
    .replace('[slug]', slug ?? '')
    .replace('[akl]', akl ?? '')
    .replace('[object Object]', '');
};
