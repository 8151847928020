import type { FC, ReactNode } from 'react';

import { Stack } from '@packages/shared';

export type SlotsContainerProps = {
  children?: ReactNode;
};

// TODO remove once tailwind is stable for all pages, promote SlotsContainer.tailwind.tsx to SlotsContainer.tsx
export const SlotsContainer: FC<SlotsContainerProps> = ({ children }) => (
  <Stack
    data-testid="header-slots"
    direction="row"
    spacing={[1.5, 1.5, 1.5, 3]}
    marginRight={[1, 1, 1, 1, 0]}
  >
    {children}
  </Stack>
);
