import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWarningCircle32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><circle cx={16} cy={16} r={12} fill="#F5A623" /><path fill="#fff" d="M14.75 21.5h2.5V19h-2.5zm0-4.5h2.5v-6.25h-2.5z" /></SvgIcon>;
};
const Memo = memo(SvgWarningCircle32);
export default Memo;