import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgInformationCircleFilled32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20" sx={{
    ...sx
  }} {...other}><defs><circle id="information-circle-filled-32_svg__a" cx={10} cy={10} r={10} /><path id="information-circle-filled-32_svg__c" d="M0 2.25h2.25V0H0zM0 9h2.25V4.5H0z" /></defs><g fill="none" fillRule="evenodd"><mask id="information-circle-filled-32_svg__b" fill="#fff"><use xlinkHref="#information-circle-filled-32_svg__a" /></mask><g fill="#F1F2F3" mask="url(#information-circle-filled-32_svg__b)"><path d="M0 0h20v20H0z" /></g><circle cx={10} cy={10} r={9.5} stroke="#626566" /><g transform="translate(8.875 5.5)"><mask id="information-circle-filled-32_svg__e" fill="#fff"><use xlinkHref="#information-circle-filled-32_svg__c" /></mask><use xlinkHref="#information-circle-filled-32_svg__c" fill="#000" /><g fill="#626566" mask="url(#information-circle-filled-32_svg__e)"><path d="M-3.375 0h9v9h-9z" /></g></g></g></SvgIcon>;
};
const Memo = memo(SvgInformationCircleFilled32);
export default Memo;