import config from '@packages/config';
import { getEndpoint } from './cmsFetcher';
import { UrlType } from './apiUrl.staticPage';

export const getUspEndpoint = (language: string): string =>
  getEndpoint(
    config.staticContent.apiEndpoints.bucket,
    'bucket-usp-bar',
    language,
    'bucket-usp-bar',
    UrlType.MENU,
  );
