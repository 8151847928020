export const apiUrl =
  // Due to CORS settings, we can't use apigateway.empirie.<env> in the browser
  // We rely on the default external ingress routing to reach the correct API server from the client
  // On the server-side, we can't use https, because the sidecars automatically wrap requests in https, and apparently that would cause issues otherwise
  // eslint-disable-next-line no-nested-ternary
  typeof window === 'undefined'
    ? process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging'
      ? 'http://apigateway.empirie.dev/api/lackmus'
      : 'http://apigateway.empirie.cloud/api/lackmus'
    : '/api/lackmus';
