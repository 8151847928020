import type { LinkProps, StackProps } from '@packages/shared';
import { Link, Stack } from '@packages/shared';
import type { FC, KeyboardEventHandler, ReactNode } from 'react';

export type ResponsiveNavigationItemBaseProps = {
  /** The href to link to. If provided, the nav item acts as a simple link */
  href?: string;
  /** If `true`, the category corresponding to the item is the current page */
  allowNavigation?: boolean;
  children: ReactNode;
  onHover?: () => void;
  onClick?: () => void;
  onNavigate?: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLAnchorElement>;
  /** Additional styles for the `li` element, which by default is a row flex */
  sxListItem: StackProps['sx'];
  /** Additional styles for the `a` element, which by default uses no decoration, default colors, and is a row flex */
  sxLink: LinkProps['sx'];
};

/**
 * Base component with shared functionality for navigation items
 */
export const ResponsiveNavigationItemBase: FC<ResponsiveNavigationItemBaseProps> = ({
  children,
  href,
  allowNavigation,
  sxListItem,
  sxLink,
  onHover,
  onClick,
  onNavigate,
  onKeyDown,
}) => (
  <Stack component="li" direction="row" sx={sxListItem}>
    <Link
      sx={{
        flex: 1,
        display: 'flex',
        textDecoration: 'none',
        color: 'inherit',
        ...sxLink,
      }}
      href={href ?? '#'}
      onMouseEnter={() => {
        onHover?.();
      }}
      onKeyDown={onKeyDown}
      onClick={(event) => {
        if (allowNavigation) {
          onNavigate?.();
        } else {
          event.preventDefault();
          onClick?.();
        }
      }}
      prefetch={false}
    >
      {children}
    </Link>
  </Stack>
);
