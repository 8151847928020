/**
 * Convert URLSearchParams to a plain object, similar to the behavior of the `query` object in `next/router` (which uses `querystring` under the hood)
 *
 * Specifically useful because storybook does not seem to support `querystring` for parsing
 *
 * When the app router is fully operational, all code using this should just use the URLSearchParams directly
 */
export const searchParamsToObject = (searchParams?: URLSearchParams | null) => {
  if (!searchParams) return {};

  return Array.from(searchParams.entries()).reduce(
    (acc, [key, value]) => {
      const existing = acc[key];

      if (existing) {
        if (Array.isArray(existing)) {
          existing.push(value);
        } else {
          acc[key] = [existing, value];
        }
      } else {
        acc[key] = value;
      }

      return acc;
    },
    {} as Record<string, string | string[]>,
  );
};
