const categoryPrefixRegex = /^(\/\w+)?(\/c)\//;
const trailingSlashRegex = /(\/)?$/;

export type NormalizeInternalPathnameOptions = {
  trailingSlash: boolean;
};

/**
 * Normalize an internal pathname to ensure it is well-formed for public use
 *
 * This includes removing the internal-only /c/ prefix, converting empty strings to `/`, and adding or removing the trailing slash as necessary
 *
 * NOTE: still keeps `/` for the root URL, even with `trailingSlash` set to `false`
 */
export const normalizeInternalPathname = (
  pathname: string | null,
  { trailingSlash }: NormalizeInternalPathnameOptions,
) => {
  const checkedPathname = pathname ?? '/'; // null check is necessary because in pages/app router compatibility mode, pathname can be null

  // currently the /c/ prefix is internal-only, and rewritten in the middleware, so we need to remove it
  return (
    checkedPathname
      .replace(categoryPrefixRegex, '$1/')
      .replace(trailingSlashRegex, trailingSlash ? '/' : '') || '/' // ensure a non-empty string, matching default behavior of `location.pathname`
  );
};
