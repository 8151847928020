import type { Resolver } from '@urql/exchange-graphcache';
import { stringifyVariables } from 'urql';
import type { ReviewsDataQuery } from '../generated/shopping/graphql';

type RatingProduct = NonNullable<ReviewsDataQuery['rating']>['product'];
type RatingReview = NonNullable<
  NonNullable<ReviewsDataQuery['rating']>['product']
>['reviews'][number];

export const reviewsPagination = (): Resolver => (_parent, fieldArgs, cache, info) => {
  // parentKey/entityKey is "Query"
  // fieldName is "rating"
  const { parentKey: entityKey, fieldName } = info;

  // all "Query" fields from cache (e.g. product("arguments"), query("arguments"))
  const allFields = cache.inspectFields(entityKey);
  const fieldInfos = allFields.filter((field) => {
    if (fieldArgs.filter) {
      return (
        field.fieldName === fieldName &&
        field.fieldKey.indexOf(fieldArgs.filter as string) > -1 &&
        field.fieldKey.indexOf(fieldArgs.id as string) > -1
      );
    }
    return (
      field.fieldName === fieldName &&
      field.fieldKey.indexOf('filter') === -1 &&
      field.fieldKey.indexOf(fieldArgs.id as string) > -1
    );
  });

  const size = fieldInfos.length;
  if (size === 0) {
    return undefined;
  }

  const fieldKey = `${fieldName}(${stringifyVariables(fieldArgs)})`;
  // get __typename for "Query" and "rating" (should be 'RatingResult')
  const typename = cache.resolve(
    cache.resolve(entityKey, fieldKey) as string,
    '__typename',
  ) as string;

  const isItInTheCache = cache.resolve(cache.resolve(entityKey, fieldKey) as string, typename);
  // eslint-disable-next-line no-param-reassign
  info.partial = !isItInTheCache;

  const mergedReviews: Array<RatingReview> = [];
  const ratingProduct: Partial<RatingProduct> = {
    __typename: 'RatingProduct',
  };

  fieldInfos.forEach((fi) => {
    const key = cache.resolve(entityKey, fi.fieldKey) as string;
    const reviews = cache.resolve(
      cache.resolve(key, 'product') as string,
      'reviews',
    ) as Array<RatingReview>;
    if (reviews) {
      mergedReviews.push(...reviews);
    }
  });

  ratingProduct.reviews = mergedReviews;

  return {
    __typename: typename,
    product: ratingProduct,
  };
};
