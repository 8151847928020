import type { EcState } from '@packages/shared/src/utils/ecState/ecState';
import { Audiences } from '../../interfaces/components';

/**
 * Returns the main audience group of a customer based on the EcState data.
 *
 * @param {EcState} ecState - The EcState data (by cookie "ecState") containing customer information.
 * @returns {string} The main audience group of the customer.
 */
export const getMainAudience = (ecState?: EcState): Audiences => {
  if (!ecState?.loggedIn) {
    return Audiences.anonymous;
  }
  if (ecState.UserOhnePasswort) {
    return Audiences.UserOhnePasswort;
  }
  if (ecState.creditAffinity) {
    return Audiences.creditAffinity;
  }
  if (ecState.bonusProgram) {
    return Audiences.bonusProgram;
  }

  return Audiences.loggedIn;
};

/**
 * Returns an array containing all the audiences the customer is in, based on the EcState data.
 *
 * @param {EcState} ecState - The EcState data (by cookie "ecState") containing customer information.
 * @returns {string[]} An array of all the audiences the customer is in.
 */
export const getAllAudiences = (ecState?: EcState): Audiences[keyof Audiences][] => {
  if (!ecState?.loggedIn) {
    return [Audiences.anonymous];
  }

  const result = [Audiences.loggedIn];

  if (ecState.bonusProgram) {
    result.push(Audiences.bonusProgram);
  }
  if (ecState.creditAffinity) {
    result.push(Audiences.creditAffinity);
  }
  if (ecState.UserOhnePasswort) {
    result.push(Audiences.UserOhnePasswort);
  }
  return result;
};
