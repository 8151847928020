import dynamic from 'next/dynamic';

export const DynamicSearchTermSuggestions = dynamic(
  () => import(/* webpackChunkName: 'SearchTermSuggestions' */ './SearchTermSuggestions'),
  {
    ssr: false,
  },
);

export * from './SearchTermSuggestions.Loading';
