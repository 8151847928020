import { logger } from '../logger/logger';

export const execParallel = async (fetchers: Array<() => Promise<any>>) => {
  const results = await Promise.allSettled(fetchers.map((f) => f()));

  results.forEach((result) => {
    if (result.status === 'rejected') {
      logger.error(`Error on initial fetching data: ${result.reason}`);
    }
  });

  return results.map((result) => (result.status === 'fulfilled' ? result.value : undefined));
};
