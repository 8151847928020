import { usePathname } from 'next/navigation';

/** Check if the actual route is a checkout route */
const isCheckoutRoute = (pathname: string) => pathname.includes('/(fr/)?kasse');

export const useIsCheckoutRoute = () => {
  const pathname = usePathname();

  // NOTE: null check is for pages/app router compat mode, later for pure app router this can never be null
  return isCheckoutRoute(pathname ?? '/');
};
