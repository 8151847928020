import { Dialog } from '@packages/shared';
import type { DialogProps } from '@packages/shared';
import { Suspense, lazy } from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  modalHeadline: {
    id: 'LoginModal.modal.headline',
    defaultMessage: 'Schnell anmelden',
  },
});

// dynamic import to avoid loading the mybuy stuff on initial render
const LoginForm = lazy(() => import(/* webpackChunkName: 'LoginForm' */ './LoginForm'));

export interface LoginDialogProps
  extends Omit<DialogProps, 'dialogContent' | 'dialogActions' | 'title'> {
  /** required to close dialog after successful login */
  handleClose: () => void;
}

/** Dialog with user login form in two modes: 'e-mail & password login' or 'customer number & birthday login' */
export const LoginDialog = ({ handleClose, ...props }: LoginDialogProps) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog
      {...props}
      title={formatMessage(messages.modalHeadline)}
      handleClose={handleClose}
      dialogContent={
        <Suspense>
          <LoginForm onLogin={() => handleClose()} />
        </Suspense>
      }
      displayTrackingProps={{ category: 'Login' }}
    />
  );
};
