import dynamic from 'next/dynamic';

export const DynamicSearchHistory = dynamic(
  () => import(/* webpackChunkName: 'SearchHistory' */ './SearchHistory'),
  {
    ssr: false,
  },
);

export * from './SearchHistory.Loading';
