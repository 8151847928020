import type { CardProps as MuiCardProps } from '@mui/material';
import { Card as MuiCard } from '@mui/material';
import { forwardRef } from 'react';

export type CardProps = MuiCardProps;

/** Card component. Based on MUI Card: https://mui.com/material-ui/react-card/ */
export const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => (
  <MuiCard {...props} ref={ref} data-testid="card" />
));
