import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import type { Language, Locale } from '@packages/config';

import { useConfig } from '../useConfig/useConfig';
import { localizeUrl } from '../../utils/localizeUrl/localizeUrl';

/**
 * Use i18n language information, as a standardized companion to `useIntl` which only returns locales
 *
 * Previously this was part of useRouter (next/router version, even if incorrectly named `locale`)
 *
 * Also includes a bound version of `localizeUrl` for the current config, to avoid passing the config around if you don't have it already
 */
export const useI18n = () => {
  const {
    i18n: { defaultLocale, languages, locales },
  } = useConfig();
  const { locale } = useIntl();

  return {
    locale: locale as Locale,
    language: locale.split('-')[0] as Language,
    defaultLocale,
    defaultLanguage: defaultLocale.split('-')[0] as Language,
    availableLocales: locales,
    availableLanguages: languages,
    /**
     * Bound version of `localizeUrl` for the current config, to avoid passing the config around if you don't have it already
     */
    localizeUrl: useCallback(
      (href: string, language: Language) =>
        localizeUrl(href, language, { i18n: { defaultLocale, languages } }),
      [defaultLocale, languages],
    ),
  };
};
