import { getEcState } from '@packages/shared/src/utils/ecState/ecState';
import { getMainAudience } from '../../../../utils';
import { fetchContentSnippetData } from './fetchContentSnippetData';
import { doTheAbTest } from '../../ab-test/abTest';
import type { PromotionDataFetchInformation } from './fetch.types';

export const fetchShopPromotionData = async ({
  query,
  cookies = {},
  context,
  locale,
  callOwnApi,
}: PromotionDataFetchInformation) => {
  const { shopId = '-1', BonusNumber, TestDate: testDate, platform, AffiliateID } = query;
  const audience = getMainAudience(getEcState(cookies));
  const abTestData = await doTheAbTest({
    BonusNumber,
    AffiliateID,
    cookies,
    context,
    locale,
    testDate,
    audience,
    platform,
    callOwnApi,
  });
  const { fetchedShopPromotionData, mvtTreatment } = abTestData;
  if (mvtTreatment) {
    return mvtTreatment === 'test' ? undefined : fetchedShopPromotionData;
  }

  return fetchContentSnippetData(
    {
      shopId,
      locale,
      testDate,
      audience,
      platform,
    },
    callOwnApi,
  );
};
