'use client';

import { Button as MuiButton, IconButton } from '@mui/material';
import type { ButtonProps as MuiButtonProps, IconButtonProps } from '@mui/material';

import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import {
  type GlycerinButtonClickPayload,
  type GlycerinFormSubmitClickPayload,
} from '@packages/tracking/src/types/payloads';
import {
  type GTMEventGlycerinFormSubmitClick,
  type GTMEventGlycerinButtonClick,
} from '@packages/tracking/src/types/events';
import { useIsBot } from '@packages/tracking/src/hooks/useIsBot/useIsBot';
import React, { type ForwardedRef, type HTMLAttributeAnchorTarget } from 'react';
import { getCanonicalUrl } from '../../utils/getCanonicalUrl/getCanonicalUrl';

export type ButtonProps = MuiButtonProps & {
  target?: HTMLAttributeAnchorTarget;
  clickTrackingProps?: GlycerinButtonClickPayload | GlycerinFormSubmitClickPayload | null;
};

export type MuiIconButtonProps = IconButtonProps & {
  href?: string;
  clickTrackingProps?: GlycerinButtonClickPayload | GlycerinFormSubmitClickPayload | null;
};

export const Button = React.forwardRef(
  (
    { clickTrackingProps, onClick, ...props }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement | null>,
  ) => {
    const dispatchGTMEvent = useTracking();
    const { href, type } = props;
    const { category, target, detail, label, placement, variantIds, custom } =
      clickTrackingProps ?? {};
    let linkTarget = href;
    const isBot = useIsBot();
    if (isBot) {
      linkTarget = getCanonicalUrl(href ?? '');
    }

    return (
      <MuiButton
        onClick={(event) => {
          if (clickTrackingProps !== null) {
            if (type === 'submit') {
              dispatchGTMEvent<GTMEventGlycerinFormSubmitClick>({
                event: 'FormSubmitClick',
                FormSubmitClickData: {
                  category: category ?? 'form-submit-click',
                  target: target ?? href,
                  detail,
                  label,
                  placement,
                  formId:
                    clickTrackingProps && 'formId' in clickTrackingProps
                      ? clickTrackingProps.formId
                      : undefined,
                  variantIds,
                  custom,
                },
              });
            } else {
              dispatchGTMEvent<GTMEventGlycerinButtonClick>({
                event: 'ButtonClick',
                ButtonClickData: {
                  category: category ?? 'button-click',
                  target: target ?? href,
                  detail,
                  label,
                  placement,
                  variantIds,
                  custom,
                },
              });
            }
          }

          if (onClick) {
            onClick(event);
          }
        }}
        {...props}
        ref={ref}
        href={href ? linkTarget : undefined}
      />
    );
  },
);

export const MuiIconButton: React.FC<MuiIconButtonProps> = ({
  clickTrackingProps,
  href,
  onClick,
  ...props
}) => {
  const dispatchGTMEvent = useTracking();
  const { type } = props;
  const { category, target, detail, label, placement, variantIds, custom } =
    clickTrackingProps ?? {};

  return (
    <IconButton
      onClick={(event) => {
        if (clickTrackingProps !== null) {
          if (type === 'submit') {
            dispatchGTMEvent<GTMEventGlycerinFormSubmitClick>({
              event: 'FormSubmitClick',
              FormSubmitClickData: {
                category: category ?? 'form-submit-click',
                target: target ?? href,
                detail,
                label,
                placement,
                formId:
                  clickTrackingProps && 'formId' in clickTrackingProps
                    ? clickTrackingProps.formId
                    : undefined,
                variantIds,
                custom,
              },
            });
          } else {
            dispatchGTMEvent<GTMEventGlycerinButtonClick>({
              event: 'ButtonClick',
              ButtonClickData: {
                category: category ?? 'icon-button-click',
                target: target ?? href,
                detail,
                label,
                placement,
                variantIds,
                custom,
              },
            });
          }
        }

        if (onClick) {
          onClick(event);
        }
      }}
      {...props}
    />
  );
};
