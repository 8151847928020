import { isTestRuleset } from '../isTestRuleset/isTestRuleset';
import type { TestRuleset } from '../types';

export const parseTestRuleset = (data: string): TestRuleset | null => {
  try {
    const parsedData = JSON.parse(data);

    if (isTestRuleset(parsedData)) {
      return parsedData;
    }

    return null;
  } catch {
    return null;
  }
};
