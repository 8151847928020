import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

/**
 * Writes scrid query parameter to localStorage if present
 */
export const usePersistentScrid = () => {
  const searchParams = useSearchParams();
  const scrid = searchParams?.get('scrid');

  useEffect(() => {
    if (scrid) {
      const createdAt = new Date();
      localStorage.setItem(
        'scrid',
        JSON.stringify({
          createdAt,
          expiresAt: new Date(createdAt.getTime() + 1000 * 60 * 60 * 6),
          value: scrid.toString(),
        }),
      );
    }
  }, [scrid]);
};
