'use client';

import { Box } from '@packages/shared/src/components/Box/Box';

/**
 * on PromotionBannerError or empty there should explicitly shown nothing despite of a small border top
 *
 * @returns Box with small border top
 */
export const PromotionBannerEmpty = () => (
  <Box
    component="div"
    sx={{
      margin: '0 auto',
      maxWidth: 'xl',
      borderBottom: '0.0625rem solid black',
      borderBottomColor: 'grey.light',
    }}
  />
);
