import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';

import { SlotsContainer } from './SlotsContainer';
import { AccountSlot } from './AccountSlot';
import { WishlistSlot } from './WishlistSlot';
import { PaybackSlot } from './PaybackSlot';
import { JoeSlot } from './JoeSlot';

export const SlotsMinimal = () => {
  const {
    headerSlots: { payback, joe },
  } = useConfig();

  return (
    <SlotsContainer>
      {payback && <PaybackSlot />}
      {joe && <JoeSlot />}
      <AccountSlot />
      <WishlistSlot />
    </SlotsContainer>
  );
};
