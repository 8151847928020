import { useEffect, useState } from 'react';

import type { GTMEventGlycerinSessionCartInformation } from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import type { BasketSlotQuery } from '@packages/gql/generated/shopping/graphql';

declare global {
  interface Window {
    [key: string]: number;
  }
}

/**
 * This hook is used to notify Glycerin of the cart information at the start of a new session.
 */
export const useSessionCartInfoTracking = ({
  fetching,
  data,
}: {
  fetching: boolean;
  data?: BasketSlotQuery | null;
}) => {
  const dispatchGtmEvent = useTracking();

  const [newSessionHasStarted, setNewSessionHasStarted] = useState(false);

  useEffect(() => {
    const scheduleEvent = () => {
      setNewSessionHasStarted(true);
    };

    document.addEventListener('window.glycerin.session.start', scheduleEvent);

    return () => {
      document.removeEventListener('window.glycerin.session.start', scheduleEvent);
    };
  }, []);

  useEffect(() => {
    if (fetching) return;

    const basket = data?.basket;

    if (typeof window !== 'undefined') {
      window.basketCount = basket?.itemCount || 0;
    }

    if (!newSessionHasStarted) return;
    // fetching state is initial false and gets to true when the data is fetched
    // dispatch the event only when the fetching state is false and data is available
    if (!basket) return;

    dispatchGtmEvent<GTMEventGlycerinSessionCartInformation>({
      event: 'SessionCartInformation',
      SessionCartInformationData:
        basket && basket.itemCount > 0
          ? {
              persistentCart: true,
              currency:
                basket.subTotal.currency === 'EUR' || basket.subTotal.currency === 'CHF'
                  ? basket.subTotal.currency
                  : 'EUR',
              persistentValue: basket.subTotal.amount,
              custom: {
                gtmData: {
                  // NOTE using 'unknown' for missing values is a spot decision, was not defined in MOBV-604
                  products: basket.lineItems.map((lineItem) => ({
                    productName: lineItem.extra?.description ?? 'unknown',
                    variant: lineItem.extra?.variation ?? 'unknown',
                    size: lineItem.extra?.size ?? 'unknown',
                    orderNumber: lineItem.product.orderNumber,
                    promotion: lineItem.extra?.promotion ?? 'unknown',
                    quantity: lineItem.quantity,
                    categoryShops: lineItem.extra?.breadcrumbs?.map((name, index, breadcrumbs) => {
                      const isLastEntry = index === breadcrumbs.length - 1;
                      // according to the type definition, the id only exists for the last breadcrumb entry
                      const id =
                        isLastEntry && lineItem.extra?.category
                          ? lineItem.extra.category
                          : undefined;

                      return {
                        name,
                        id,
                      };
                    }),
                    categoryMkz: lineItem.extra?.mkz ?? undefined,
                  })),
                },
              },
            }
          : {
              persistentCart: false,
            },
    });

    setNewSessionHasStarted(false);
  }, [data, fetching, dispatchGtmEvent, newSessionHasStarted]);
};
