import type { CustomerRatingsConfig } from './customerRatings.types';

export const customerRatingsConfig: CustomerRatingsConfig = {
  ratingButtonEnabled: true,
  forceEnableRatingButtonParam: null,
  api: {
    feedback: '/api/inform/rating/all/get',
    filter: '/api/inform/rating/feedback',
    new: '/api/inform/rating/new/get',
    verifiedRatingsLayerURL: '/fragment/inspire/servicelayer/<language>/<layerid>',
    submitRatingURL: '/api/inform/rating/new/store',
  },
  termsId: 'hint_nutzungsbedingungen_kdbeweformular',
  pagingCount: 10,
};
