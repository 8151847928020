import { useEffect } from 'react';

type PromotionTriggerFunction = (functionToTrigger: string) => void;
declare global {
  interface Window {
    triggerPromotion?: PromotionTriggerFunction;
  }
}
export const usePromotionTrigger = (functionToTrigger: PromotionTriggerFunction) => {
  useEffect(() => {
    window.triggerPromotion = functionToTrigger;
    // Cleanup function
    return () => {
      // Remove the function from the window object
      delete window.triggerPromotion;
    };
  }, [functionToTrigger]);
};
