import type { SxProps } from '@mui/material';
import type { Theme } from '@mui/system';
import type { PageTemplate } from '@packages/recommendations';
import type { ParagraphType } from '../../../interfaces/components';

/**
 * The ComponentMap type is an object that has keys of type ParagraphType['type'] and optional values
 * that are functions that accept an object of type any as props and return a JSX.Element instance.
 *
 * `K in`: Iterates over all possible keys (or types) in ParagraphType['type'].
 */
type ComponentMap = {
  [K in ParagraphType['type']]?: ({
    data,
  }: {
    data: Extract<ParagraphType, { type: K }>;
  }) => JSX.Element;
};

/**
 * Initializes an empty object named componentRegistry of type ComponentMap.
 */
const componentRegistry: ComponentMap = {};

/**
 * The main purpose of the function is to register the component function in a global componentRegistry object where the key is the type.
 * @param map
 */
export const registerComponents = (map: ComponentMap): void => {
  Object.assign(componentRegistry, map);
};

/**
 * Gets a previously registered component from the componentRegistry object `registerComponent`.
 * The function uses the type parameter to retrieve the corresponding component function from the componentRegistry object.
 * If the type is not present in the componentRegistry object, the function returns undefined.
 * @param type
 */
export const getComponent = <T extends ParagraphType['type']>(
  type: T,
):
  | (({
      data,
      sx,
      pageTemplate,
      recoIndex,
      token,
    }: {
      data: Extract<ParagraphType, { type: T }>;
      sx?: SxProps<Theme>;
      pageTemplate?: PageTemplate;
      recoIndex?: number;
      token?: string;
    }) => JSX.Element)
  | undefined => componentRegistry[type];
