import {
  cookiePrefix,
  participantSuffix,
  getParticipantCookieName,
  getTestCookieName,
  errorSuffix,
} from '../cookieNames/cookieNames';
import { parseTestRuleset } from '../parseTestRuleset/parseTestRuleset';

export const getAllTestParticipations = (
  cookies: Record<string, string>,
): Array<{
  testId: string;
  participantId: string;
  variant?: Record<string, string>;
}> => {
  const testIds = Object.keys(cookies).reduce((accumulator, key) => {
    if (!key.startsWith(cookiePrefix)) return accumulator;

    const testId = key
      .replace(cookiePrefix, '')
      .replace(participantSuffix, '')
      .replace(errorSuffix, '');

    if (!accumulator.includes(testId)) {
      accumulator.push(testId);
    }

    return accumulator;
  }, [] as string[]);

  return (
    testIds
      .map((testId) => {
        const testCookieName = getTestCookieName(testId);
        const ruleset = cookies[testCookieName] && parseTestRuleset(cookies[testCookieName]);

        const participantCookieName = getParticipantCookieName(testId);
        const participantId = cookies[participantCookieName];

        return {
          testId,
          ruleset,
          participantId,
        };
      })
      // either constellation is ok, either a valid particpant cookie or a valid ruleset, but if both are missing, we can't return anything useful
      .filter(({ ruleset, participantId }) => ruleset || participantId)
      .map(({ testId, ruleset, participantId }) => {
        if (ruleset) {
          const { participant, variant } = ruleset;

          return { testId, participantId: participant, variant };
        }

        return {
          testId,
          participantId,
        };
      })
  );
};
