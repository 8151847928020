'use client';

import { Snackbar as MuiSnackbar, Box } from '@mui/material';
import { useSnackbar } from './SnackbarContext';
import { Toast } from './Toast';

export const Snackbar = () => {
  const { toasts } = useSnackbar();

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={toasts && true}
      autoHideDuration={null}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        sx={{ xs: { right: '0px', left: '0px' }, maxWidth: '48rem' }}
      >
        {toasts?.map((toast) => <Toast key={toast.key} toast={toast} />)}
      </Box>
    </MuiSnackbar>
  );
};
