'use client';

import type { PropsWithChildren } from 'react';
import { useMemo, createContext, useContext } from 'react';
import { usePathname } from 'next/navigation';

type PageviewContextValue = string;

export const PageviewContext = createContext<PageviewContextValue | undefined>(undefined);

export const PageviewProvider = ({
  initialPageviewId,
  children,
}: PropsWithChildren<{ initialPageviewId?: string }>) => {
  const pathname = usePathname();

  // reinitialize only on pathname change
  const pageviewId = useMemo(
    () => {
      if (initialPageviewId) {
        return initialPageviewId;
      }
      // create pageview id only in browser
      if (typeof window !== 'undefined') {
        if ('crypto' in window && window.isSecureContext) {
          return crypto.randomUUID();
        }
        // if no "secure context" or crypto web api not supported use fallback generation
        return URL.createObjectURL(new Blob()).split('/').slice(-1)[0];
      }
      return undefined;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, initialPageviewId],
  );

  return <PageviewContext.Provider value={pageviewId}>{children}</PageviewContext.Provider>;
};

export const usePageviewId = () => useContext(PageviewContext);
