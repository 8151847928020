import type { NextPage } from 'next';
import { useIntl } from 'react-intl';
import { StaticErrorContent } from '@packages/modules/src/StaticErrorContent/StaticErrorContent';
import { PageMetaData } from '@packages/modules/src/PageMetaData/PageMetaData';

/**
 * getInitialProps or getServerSideProps which is not allowed for 404.js
 * https://nextjs.org/docs/messages/404-get-initial-props
 */
const Custom404: NextPage = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <PageMetaData page={{ type: 'error404', template: 'error404' }} />
      <StaticErrorContent
        title={formatMessage({
          id: 'page.404.title',
          defaultMessage: '404 - SEITE WURDE NICHT GEFUNDEN',
        })}
        description={formatMessage({
          id: 'page.404.description',
          defaultMessage:
            'Leider gibt es die Seite, die du gesucht hast, auf unserer Website nicht.',
        })}
        info={formatMessage({
          id: 'page.404.info',
          defaultMessage:
            'Doch vielleicht haben wir trotzdem etwas für dich. Besuche unsere Startseite.',
        })}
        buttonLabel={formatMessage({
          id: 'page.404.button.label',
          defaultMessage: 'zur Startseite',
        })}
      />
    </>
  );
};

// this function enables Incremental Static Regeneration for this page
export async function getStaticProps() {
  return {
    props: {},
    revalidate: 3600,
  };
}

// eslint-disable-next-line import/no-default-export
export default Custom404;
