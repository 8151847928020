import { Box } from '@packages/shared';
import { ComponentRecognizer } from '../../ComponentRecognizer';
import { registerComponents } from '../../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../../interfaces';
import type { FooterBlockBadgesType } from '../types';

type BlockBadgesProps = {
  data: FooterBlockBadgesType;
};

export enum BlockBadgesTestIds {
  main = 'block-badges',
}

export const BlockBadges = ({ data }: BlockBadgesProps) => (
  <Box
    sx={{
      display: 'flex',
      gap: '0.8rem',
    }}
    data-testid={BlockBadgesTestIds.main}
    key={`${BlockBadgesTestIds.main}-${data.id}`}
  >
    {data.paragraph?.map((item, index) => (
      <ComponentRecognizer
        // eslint-disable-next-line react/no-array-index-key
        key={`${BlockBadgesTestIds.main}-component-recognizer-${index}`}
        item={item}
      />
    ))}
  </Box>
);

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.BlockBadges]: BlockBadges });
