'use client';

import { useState, useCallback, useEffect } from 'react';
import { Alert, Grow, Typography } from '@mui/material';
import {
  ErrorCircle32,
  InformationCircle32,
  SuccessCircle32,
  WarningCircle32,
} from '@packages/themes/icons';
import type { ToastProps } from './SnackbarContext';
import { useSnackbar } from './SnackbarContext';
import { CloseIconButton } from '../CloseIconButton';

export const Toast = ({ toast }: { toast: ToastProps }) => {
  const [open, setOpen] = useState(true);
  const { removeToast } = useSnackbar();

  const closeNotification = useCallback(() => {
    setOpen(false);

    setTimeout(() => {
      removeToast(toast.key);
    }, 300);

    if (toast.onClose) {
      toast.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toast.key, removeToast]);

  useEffect(() => {
    if (toast.duration) {
      setTimeout(() => {
        closeNotification();
      }, toast.duration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grow in={open} timeout={300}>
      <Alert
        data-testid="notification-alert"
        severity={toast.severity}
        iconMapping={{
          error: <ErrorCircle32 color="error" />,
          warning: <WarningCircle32 color="warning" />,
          info: <InformationCircle32 color="info" />,
          success: <SuccessCircle32 color="success" />,
        }}
        action={
          toast.onClose && (
            <CloseIconButton
              sx={{ position: 'relative', left: '0.75rem' }}
              onClick={closeNotification}
            />
          )
        }
      >
        <Typography component="b">{toast.title}</Typography>
        {toast.message}
      </Alert>
    </Grow>
  );
};
