import { Box } from '@packages/shared';
import { useFilterTimeControl } from '@packages/shared/src/utils/filterTimeControl/filterTimeControl';
import { posContainerStyle, posContainerVerticalStyle, transformStyle } from './utils/styleHelpers';
import type { GroupType } from '../CmsGroup/types';
import { ComponentRecognizer } from '../ComponentRecognizer';

export const GroupOnImage = ({ paragraph }: { paragraph?: GroupType[] }) => {
  const filterTimeControl = useFilterTimeControl();
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {paragraph
        ?.filter((item) => filterTimeControl(item))
        ?.map((item) => {
          const { field_alignment: alignment, field_vertical_alignment: verticalAlignment } = item;
          return (
            <Box
              component="span"
              sx={{
                position: 'absolute',
                containerType: 'size',
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
                '> div': {
                  position: 'absolute',
                  '&.cms-group': {
                    ...posContainerStyle(alignment),
                    ...posContainerVerticalStyle(verticalAlignment),
                    ...transformStyle(alignment, verticalAlignment),
                    width: 'auto',
                  },
                },
              }}
              key={item.id}
            >
              <ComponentRecognizer item={item} />
            </Box>
          );
        })}
    </>
  );
};
