import useSWR from 'swr/immutable';

export const isMonitoringCacheKey = 'isMonitoring';

/**
 * @returns if the user agent is monitoring(weikatecmon)
 */
export const useIsMonitoring = () => {
  const { data: isMonitoring } = useSWR<boolean>(isMonitoringCacheKey, () => false);

  return isMonitoring ?? false;
};
