import pino from 'pino';

// https://getpino.io/#/docs/help?id=mapping-pino-log-levels-to-google-cloud-logging-stackdriver-severity-levels
const PinoLevelToSeverityLookup: { [key: string]: string } = {
  trace: 'DEBUG',
  debug: 'DEBUG',
  info: 'INFO',
  warn: 'WARNING',
  error: 'ERROR',
  fatal: 'CRITICAL',
};

const sharedOptions: Parameters<typeof pino>[0] = {
  messageKey: 'message',
  formatters: {
    level(label, number) {
      return {
        severity: PinoLevelToSeverityLookup[label] || PinoLevelToSeverityLookup.info,
        level: number,
      };
    },
    log(object) {
      // transform errors to conform to https://cloud.google.com/error-reporting/reference/rest/v1beta1/projects.events/report#ReportedErrorEvent
      if (object.stack) {
        const {
          time,
          message: originalMessage,
          stack: message,
          msg: errorMessage,
          type: errorType,
          ...rest
        } = object;

        return {
          ...rest,
          time,
          ...(typeof time === 'number' && { eventTime: new Date(time).toISOString() }),
          message,
          errorMessage,
          errorType,
          originalMessage,
          // TODO fill service context to conform to https://cloud.google.com/error-reporting/reference/rest/v1beta1/projects.events/report#ReportedErrorEvent
          // serviceContext: {
          //   service: '', // get service name from somewhere
          //   version: '', // get commit hash maybe
          // }
        };
      }

      return object;
    },
  },
};

export const serverOnlyLogger = pino({
  ...sharedOptions,
  browser: { disabled: true },
});

export const middlewareLogger = pino({
  ...sharedOptions,
  browser: {
    // eslint-disable-next-line no-console
    write: (o) => console.log(JSON.stringify(o)),
    formatters: sharedOptions.formatters, // regular formatters are apparently ignored for `browser` (which the nextjs middleware runs as)
    serialize: true, // as the middleware is not really a `browser`, we just handle it like it isn't
  },
});

export const logger = pino({
  ...sharedOptions,
});
