import type {
  TabProps as MuiTabProps,
  TabsProps as MuiTabsProps,
  TabsTypeMap,
} from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';

export type TabProps = MuiTabProps;
export type TabsProps = MuiTabsProps;

/** Tab component. Based on MUI Tab https://mui.com/material-ui/api/tab/ */
export const Tab = (props: TabProps) => <MuiTab {...props} data-testid="tab" />;

/** Tabs organize and allow navigation between groups of content that are related and at the same level of hierarchy. Based on MUI Tabs https://mui.com/material-ui/react-tabs/ */
export const Tabs: OverridableComponent<TabsTypeMap & MuiTabsProps> = (props: TabsProps) => (
  <MuiTabs {...props} data-testid="mui-tabs" />
);
