export const memoryCache: Record<string, { timestamp: number; data: any }> = {};
export const cached =
  <T>(name: string, func: () => Promise<T>, ttl = 1000 * 60): (() => Promise<T>) =>
  async () => {
    if (
      !memoryCache[name] ||
      !memoryCache[name].data ||
      memoryCache[name].timestamp + ttl < Date.now()
    ) {
      memoryCache[name] = {
        data: await func(),
        timestamp: Date.now(),
      };
    }
    return memoryCache[name].data;
  };
