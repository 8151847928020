import { cva } from 'class-variance-authority';

export const notificationVariants = cva(
  'flex w-full items-start gap-0.5 rounded-[4px] border p-0.5 [&>span]:h-4 [&>span]:w-4 [&>span]:shrink-0',
  {
    variants: {
      severity: {
        error:
          'bg-color-error-light border-color-error [&>span]:icon-emp-error-circle [&>span]:text-color-error',
        warning:
          'bg-color-warning-light border-color-warning [&>span]:icon-emp-warning-circle [&>span]:text-color-warning',
        success:
          'bg-color-success-light border-color-success [&>span]:icon-emp-success-circle [&>span]:text-color-success',
        info: 'bg-color-info-light border-color-info [&>span]:icon-emp-information-circle [&>span]:text-color-info',
      },
      size: {
        default: 'gap-1 [&>div]:py-[5px] [&>span]:h-4 [&>span]:w-4',
        small: 'gap-0.5 [&>div]:pt-[2px] [&>span]:h-3 [&>span]:w-3',
      },
      variant: {
        default: 'border-0 border-t-[3px]',
        outline: 'border-t',
      },
    },
    defaultVariants: {
      size: 'default',
      severity: 'info',
      variant: 'outline',
    },
  },
);
