import { Skeleton, Stack } from '@packages/shared';

export const AccountOverviewFallback = () => (
  <Stack direction="column" gap={2}>
    <Stack direction="column" gap={1} sx={{ p: 1 }}>
      <Stack gap={2} direction="row">
        <Skeleton variant="circular" width={50} height={50} />
        <Stack direction="column" gap={1} flexGrow={1}>
          <Skeleton variant="rectangular" height={20} />
          <Skeleton variant="rectangular" height={20} />
        </Stack>
      </Stack>
      <Skeleton variant="rectangular" height={40} />
      <Skeleton variant="rectangular" height={40} />
      <Skeleton variant="rectangular" height={40} />
    </Stack>
    <Stack direction="column" gap={1} sx={{ p: 1 }}>
      <Skeleton variant="rectangular" height={40} />
      <Skeleton variant="rectangular" height={40} />
      <Skeleton variant="rectangular" height={40} />
      <Skeleton variant="rectangular" height={40} />
    </Stack>
  </Stack>
);
