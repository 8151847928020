import { Box, Button, FlatIconButton } from '@packages/shared';
import type { FC } from 'react';
import { startTransition, useState } from 'react';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';
import { Cancel32, ArrowMediumLeft32 } from '@packages/themes/icons';

const closeAriaLabel = defineMessage({
  id: 'navigation.mobile.close.aria-label',
  defaultMessage: 'schließen',
});

const backAriaLabel = defineMessage({
  id: 'navigation.mobile.back.aria-label',
  defaultMessage: 'zurück',
});

enum Tab {
  menu,
  account,
}

export const MobileNavigationContent: FC<{
  menuSlot: JSX.Element;
  accountSlot: JSX.Element;
  onClose: () => void;
  resetNavigation?: () => void;
}> = ({ onClose, menuSlot, accountSlot, resetNavigation }) => {
  const { formatMessage } = useIntl();

  const [activeTab, setActiveTab] = useState<Tab>(Tab.menu);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        height: '100%',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'grey.dark',
          color: 'text.light',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          button: {
            color: 'inherit',
            height: 'auto',
            padding: '0.75rem',
          },
        }}
      >
        {activeTab === Tab.menu && resetNavigation && (
          <FlatIconButton
            onClick={resetNavigation}
            aria-label={formatMessage(backAriaLabel)}
            key={`back-button-${activeTab}`}
          >
            <ArrowMediumLeft32 />
          </FlatIconButton>
        )}
        <div />
        <FlatIconButton
          onClick={onClose}
          data-testid="close-button"
          aria-label={formatMessage(closeAriaLabel)}
          key={`close-button-${activeTab}`}
        >
          <Cancel32 />
        </FlatIconButton>
      </Box>
      <Box
        sx={{
          padding: '1rem 2rem',
          display: 'flex',
          borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
          '> button': {
            flex: '1 1 100%',
            '&:first-of-type': {
              borderRight: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
            '&:last-of-type': {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
          },
        }}
      >
        <Button
          data-testid="menu-tab-button"
          disabled={activeTab === Tab.menu}
          onClick={() => startTransition(() => setActiveTab(Tab.menu))}
        >
          <FormattedMessage id="slot.account.menu" defaultMessage="Menü" />
        </Button>
        <Button
          data-testid="account-tab-button"
          disabled={activeTab === Tab.account}
          onClick={() => startTransition(() => setActiveTab(Tab.account))}
        >
          <FormattedMessage id="slot.account.label" defaultMessage="Mein Konto" />
        </Button>
      </Box>
      <Box
        sx={{
          overflow: 'auto',
          position: 'relative',
        }}
      >
        {activeTab === Tab.menu ? menuSlot : accountSlot}
      </Box>
    </Box>
  );
};
