'use client';

import type { ReactNode, FC } from 'react';
import { useMemo, createContext } from 'react';

export interface LoginStateContextProps {
  loggedIn: boolean;
  softLoggedIn: boolean;
}

export const LoginStateContext = createContext<LoginStateContextProps>({
  loggedIn: false,
  softLoggedIn: false,
});

export const LoginStateProvider: FC<{
  children: ReactNode;
  loggedIn: boolean;
  softLoggedIn: boolean;
}> = ({ children, loggedIn, softLoggedIn }) => {
  const value = useMemo(
    () => ({
      loggedIn,
      softLoggedIn,
    }),
    [loggedIn, softLoggedIn],
  );

  return <LoginStateContext.Provider value={value}>{children}</LoginStateContext.Provider>;
};
