import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgJoeLogo = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.5 283.5" sx={{
    ...sx
  }} {...other}><path fill="#d7ac53" d="M261 209.5c29.2-52 21.6-119-22.6-163.3C185-7.1 98.5-7.1 45.1 46.3c-53.3 53.3-53.3 139.8 0 193.1 44.3 44.2 111.3 51.8 163.3 22.6l69.9 17.5z" /><g fill="#fff"><path d="M206.9 85.2c0-8.9-8.1-16.9-16.9-16.9-8.9 0-16.9 8.1-16.9 16.9 0 8.9 8.1 16.9 16.9 16.9 8.9 0 16.9-8 16.9-16.9M169.3 197.3c24.5 0 44.4-19.9 44.4-44.4s-19.9-44.4-44.4-44.4-44.4 19.9-44.4 44.4 19.9 44.4 44.4 44.4m-18.9-41.4c4.4 5.9 11.5 10 18.9 10 7.4 0 14.5-4 18.9-10 .1.6.1 1.3.1 1.9 0 10-9 19-19 19s-19-9-19-19c0-.6 0-1.3.1-1.9M165.5 85.2c0-8.9-8.1-16.9-16.9-16.9-8.9 0-16.9 8.1-16.9 16.9 0 8.9 8.1 16.9 16.9 16.9 8.8 0 16.9-8 16.9-16.9M97.5 68.2c-8.9 0-16.9 8.1-16.9 16.9 0 8.9 8.1 16.9 16.9 16.9s16.9-8.1 16.9-16.9c.1-8.8-8-16.9-16.9-16.9M81.4 187.6c0 4.8-3.2 8.1-8.1 8.1-3.2 0-8.1-1.6-8.1-1.6L62 221.5s8.1 3.2 17.7 3.2c19.4 0 33.9-14.5 33.9-37.1v-75.8H81.4z" /></g></SvgIcon>;
};
const Memo = memo(SvgJoeLogo);
export default Memo;