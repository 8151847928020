import type { ReactNode, MouseEvent, KeyboardEvent } from 'react';
import type { SxProps } from '@mui/material';

import { Box } from '@packages/shared';

export type LinearSuggestionsItemProps = {
  children: ReactNode;
  /** MUI System sx prop */
  sx?: SxProps;
  onClick?: (event: MouseEvent<HTMLLIElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLLIElement>) => void;
};

export const LinearSuggestionsItem = ({
  children,
  sx,
  onClick,
  onKeyDown,
}: LinearSuggestionsItemProps) => (
  <Box
    component="li"
    role="menuitem"
    sx={{
      display: 'flex',
      alignItems: 'center',
      minHeight: 42,
      padding: 0,
      paddingLeft: 6,
      justifyContent: 'space-between',
      '&:hover': {
        // TODO find a fitting hover color, currently there is nothing specified in the design system, and grey.light is invisible for the history as it matches the default background color
        backgroundColor: 'grey.light',
      },
      ...sx,
    }}
    onClick={onClick}
    onKeyDown={onKeyDown}
  >
    {children}
  </Box>
);
