import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgArrowLargeLeft32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M20.56 25.142a.5.5 0 1 1-.706.707L10 15.995l9.854-9.849a.5.5 0 1 1 .707.708l-9.147 9.141z" className="arrow-large-left-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgArrowLargeLeft32);
export default Memo;