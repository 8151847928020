/** chunk an array into multiple arrays with a uniform max length
 *
 * @param arr The original array
 * @param size The max length of each chunk
 * @returns The items of the original array, grouped into chunks of the specified size
 */
export const chunk = <T>(arr: T[], size: number) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
    arr.slice(i * size, i * size + size),
  );
