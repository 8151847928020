import type { FC, ReactElement, ReactNode } from 'react';

export interface ConditionalWrapperProps {
  /** If condition true, wrapper will be rendered. Otherwise only children will be rendered */
  condition: boolean;
  /** Function returns wrapper of component */
  wrapper: (ch: ReactNode) => ReactElement<any, any> | null;
  /** Element which sould or should not be wrapper */
  children: ReactElement<any, any> | null;
}

/** Use this component to wrapper another component based on a condition */
export const ConditionalWrapper: FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps) => (condition ? wrapper(children) : children);
