import { useMemo } from 'react';
import type { NavigationItem, RawNavigationGroup, RawNavigationItem } from './navigationTypes';

const recursivelyStripGroups =
  (level: number) => (items: NavigationItem[], item: RawNavigationItem | RawNavigationGroup) => {
    if ('categoryId' in item) {
      items.push({
        ...item,
        level,
        children: (
          item.children
            ?.filter((child) => 'categoryId' in child && child.categoryId !== item.categoryId)
            .reduce(recursivelyStripGroups(level + 1), []) ?? []
        ).concat(
          item.children
            ?.filter((child) => !('categoryId' in child))
            ?.flatMap((child) => child.children ?? [])
            ?.reduce(recursivelyStripGroups(level + 1), []) ?? [],
        ),
      });
    }

    return items;
  };

const recursivelyFlattenItems = (items: NavigationItem[], item: NavigationItem) => {
  items.push(item);
  items.push(...item.children.reduce(recursivelyFlattenItems, []));
  return items;
};

/**
 * Pre-process navigation data from the old navigation to be better suited for the new navigation
 */
export const useCleanedNavData = (items: RawNavigationItem[]) => {
  const cleanItems = useMemo(() => items.reduce(recursivelyStripGroups(1), []), [items]);
  const flatItems = useMemo(() => cleanItems.reduce(recursivelyFlattenItems, []), [cleanItems]);

  return { cleanItems, flatItems };
};
