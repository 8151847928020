/* @jsxImportSource react */

import { PromotionBannerEmpty } from './PromotionBannerEmpty';

/**
 * on PromotionBannerError it should be displayed the same as on empty
 *
 * @returns Box with small border top
 */
export const PromotionBannerError = () => <PromotionBannerEmpty />;
