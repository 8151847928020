/*
 * How to use?
 *
import { getStaticBucketUrl } from '../utils/apiUrl.staticPage';

const finalUrl = getStaticBucketUrl({
  language: 'de',
  pathname: '/',
  url: '/api/inspire/pages/<locale>/<identifier>.json'
})
 * */

export type StaticBucketUrlType = {
  bucketUrl: string;
  language: string;
  pathname?: string;
  menu?: string;
  type: UrlType;
};

export enum Environment {
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export enum UrlType {
  PAGE = '',
  MENU = 'menus',
  PREVIEW = 'preview',
}

/**
 * build a static bucket url by given information
 *
 * @param bucketUrl basic url to the bucket
 * @param language
 * @param pathname path where the desired file is in
 * @param menu name of the menu if type is menu
 * @param environment development, staging or production
 * @param type choose page or menu
 *
 * @returns a static bucket url
 */
const getStaticBucketUrl = ({
  bucketUrl,
  language,
  pathname = '',
  menu = '',
  type,
}: StaticBucketUrlType): string => {
  let target = '';

  /* istanbul ignore else */
  if (type === UrlType.PAGE) {
    target = pathname.replace(/^\/+/, '');

    if (target?.startsWith(`${language}/`)) {
      target = target.replace(`${language}/`, '');
    }

    if ([language, '/', ''].includes(target)) {
      target = 'storefront';
    }

    target = target.replace(/\/$/, '').replace(/\//g, '-');
  } else if (type === UrlType.MENU) {
    target = menu;
  }

  const url = bucketUrl.replace(/\/+$/, '');
  const subFolder = type === UrlType.MENU ? '/menus' : '';
  const finalUri = url
    .replace('<subfolder>', subFolder)
    .replace('<locale>', language)
    .replace('<identifier>', target);

  // Example: 'https://storage.googleapis.com/drupal-pages/production/baurde/de/storefront.json'
  return finalUri;
};

export { getStaticBucketUrl };
