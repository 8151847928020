/**
 * This arrow function converts a string into a base64-encoded string,
 * treating the input string as a sequence of UTF-8 bytes.
 * If the input is null, undefined or empty, it returns undefined.
 *
 * @param input The input string to be encoded into base64
 * @returns Base64-encoded string or undefined
 */
export const encodeToBase64 = (input: string | null | undefined): string | undefined => {
  if (input) {
    // TextEncoder will create a Uint8Array of bytes representing the UTF-8 encoding of the string.
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    // Convert Uint8Array to a regular array, so it can be spread into String.fromCharCode
    // Then, the resulting string (UTF-8 byte sequence as a string) is base64-encoded using btoa.
    return btoa(String.fromCharCode(...Array.from(data)));
  }

  return undefined;
};
