import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgLoadReload32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M24.5 11a.5.5 0 0 1 .5.5v6a6.5 6.5 0 0 1-6.5 6.5H13l2.4 3.2a.5.5 0 0 1-.8.6l-3.225-4.3 3.225-4.3a.5.5 0 0 1 .8.6L13 23h5.5a5.5 5.5 0 0 0 5.5-5.5v-6a.5.5 0 0 1 .5-.5m-7.1-6.8 3.225 4.3-3.225 4.3a.5.5 0 0 1-.8-.6L18.999 9H13.5A5.5 5.5 0 0 0 8 14.5v6a.5.5 0 1 1-1 0v-6A6.5 6.5 0 0 1 13.5 8h5.499L16.6 4.8a.5.5 0 0 1 .8-.6" className="load-reload-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgLoadReload32);
export default Memo;