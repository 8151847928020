import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgArrowLargeRight32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M11.146 25.142a.5.5 0 0 0 .708.707l9.853-9.854-9.854-9.849a.5.5 0 1 0-.706.708l9.146 9.141z" className="arrow-large-right-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgArrowLargeRight32);
export default Memo;