import { Container } from '@packages/shared';
import type { CmsData } from '../../../../interfaces/webapp_json';
import { Html, ModalByTextlink } from '../..';
import type { FooterBlockType } from '../types';
import { ComponentTypes } from '../../../../interfaces';

type SocketProps = {
  data: CmsData;
};

export enum SocketTestIds {
  main = 'socket',
  noRenderComponentFound = 'socket-no-component-found',
}

export const AreaSocket = ({ data }: SocketProps): JSX.Element => {
  const comp = (data.paragraph as FooterBlockType[])
    ?.filter((item) => item.parent_field_name === 'field_socket')
    ?.map((item, index) => {
      const keyModalByTextlink = `socket-${ComponentTypes.TextlinkModal}-${index}`;
      const keyHtml = `socket-${ComponentTypes.Html}-${index}`;
      if (item.type === ComponentTypes.TextlinkModal) {
        return <ModalByTextlink key={keyModalByTextlink} data={item} />;
      }

      if (item.type === ComponentTypes.Html) {
        return <Html key={keyHtml} data={item} />;
      }

      return <div />;
    });

  if (data && comp.length > 0) {
    return (
      <Container
        sx={({ spacing }) => ({
          color: 'footer.contrastText',
          typography: ['body2', null, 'body3'],
          padding: [spacing(3, 2, 9, 2), null, spacing(3, 0, 9, 0)],
          '& p': {
            margin: 0,
            p: 0,
            '+ p': {
              pt: [1.5, 1.5, 1],
            },
          },
          '& a': {
            color: 'footer.contrastText',
            textDecoration: 'none',
          },
        })}
      >
        {comp}
      </Container>
    );
  }

  return <div data-testid={SocketTestIds.noRenderComponentFound} />;
};
