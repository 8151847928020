const arrayToString = (value: string | string[]): string =>
  Array.isArray(value) ? value[0] : value;

const parseTestDate = ({ testDate }: { testDate?: string | string[] | null }): Date | null => {
  if (testDate) {
    const testDateFixed = arrayToString(testDate);
    const parsedDate = Date.parse(testDateFixed);
    if (!Number.isNaN(parsedDate)) {
      const newDate = new Date(testDateFixed);
      if (testDate.indexOf('T') === -1) {
        newDate.setHours(12);
      }
      return newDate;
    }
  }
  return null;
};

export { arrayToString, parseTestDate };
