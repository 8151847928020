import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgAdoptarrow32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M18.75 9a.75.75 0 1 1 0 1.5h-7.189l10.97 10.97a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-1.06 0L10.5 11.561v7.189a.75.75 0 0 1-.648.743l-.102.007a.75.75 0 0 1-.75-.75v-9A.75.75 0 0 1 9.75 9Z" className="adoptarrow-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgAdoptarrow32);
export default Memo;