import { IconButton } from '@mui/material'; // TODO figure out how to reconcile that with the "IconButton" in packages/shared, that is something completely different

import { Adoptarrow32 } from '@packages/themes/icons';
import type { LinearSuggestionsItemProps } from './LinearSuggestions.Item';
import { LinearSuggestionsItem } from './LinearSuggestions.Item';

export type LinearSuggestionsAdoptableItemProps = LinearSuggestionsItemProps & {
  /** Callback fired when the user clicks the arrow to the right of the item, arrow is only shown if a callback exists */
  onAdopt?: () => void;
};

export const LinearSuggestionsAdoptableItem = ({
  onAdopt,
  children,
  sx,
}: LinearSuggestionsAdoptableItemProps) => (
  <LinearSuggestionsItem sx={sx}>
    {children}
    <IconButton
      disabled={!onAdopt}
      size="small"
      aria-label="Insert into search field"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onAdopt?.();
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          onAdopt?.();
        }
      }}
    >
      <Adoptarrow32 />
    </IconButton>
  </LinearSuggestionsItem>
);
