import type { KeyboardEvent } from 'react';

export const onKeys =
  (handlers: Record<string, (event: KeyboardEvent) => void>) => (event: KeyboardEvent) => {
    const handler = handlers[event.key];

    if (!handler) return;

    handler(event);
  };

export const onKey = (key: string, f: (event: KeyboardEvent) => void) => onKeys({ [key]: f });
