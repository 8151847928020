import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const colors = [
  'background-base',
  'primary',
  'primary-dark',
  'primary-light',
  'primary-texttransparent',
  'primary-contrasttext',
  'secondary-dark',
  'secondary-light',
  'secondary-texttransparent',
  'secondary-contrasttext',
  'additional',
  'additional-dark',
  'additional-texttransparent',
  'grey',
  'grey-dark',
  'grey-light',
  'grey-light-dark',
  'text',
  'text-disabled',
  'text-secondary',
  'text-dark',
  'text-darktransparent',
  'text-light',
  'text-lighttransparent',
  'sale',
  'sale-contrasttext',
  'sale-dark',
  'sale-texttransparent',
  'info',
  'info-light',
  'info-dark',
  'info-texttransparent',
  'info-contrasttext',
  'warning',
  'warning-light',
  'warning-dark',
  'warning-texttransparent',
  'warning-contrasttext',
  'error',
  'error-light',
  'error-dark',
  'error-texttransparent',
  'error-contrasttext',
  'success',
  'success-light',
  'success-dark',
  'success-texttransparent',
  'success-contrasttext',
  'transparent',
  'sustainability',
  'sustainability-light',
  'sustainability-dark',
  'sustainability-texttransparent',
  'sustainability-contrasttext',
  'payback',
  'payback-light',
  'payback-dark',
  'joe',
  'joe-light',
  'joe-dark',
  'action-disabled',
  'separator',
  'accordion-toggle-icon',
  'backgrop',
  'linear-progress',
  'eff-lbl-darkgreen',
  'eff-lbl-mediumgreen',
  'eff-lbl-lightgreen',
  'eff-lbl-yellow',
  'eff-lbl-lightorange',
  'eff-lbl-darkorange',
  'eff-lbl-red',
];

const sizes = [
  'body1',
  'body2',
  'body3',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'e-eff-lbl-lg',
  'e-eff-lbl-nm',
  'e-eff-lbl-sm',
  't-eff-lbl-lg',
  't-eff-lbl-nm',
  't-eff-lbl-sm',
  'e-eff-lbl-sup-lg',
  'e-eff-lbl-sup',
];

const fontFamilies = ['family-primary', 'family-secondary', 'family-eff-lbl'];

const customTwMerge = extendTailwindMerge<(typeof colors)[0]>({
  extend: {
    classGroups: {
      'text-color': [{ 'text-color': colors }],
      'font-family': [{ 'font-family': fontFamilies }],
      'text-size': [{ 'text-size': sizes }],
      'bg-color': [{ 'bg-color': colors }],
      'border-color': [{ 'border-color': colors }],
      'outline-color': [{ 'outline-color': colors }],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(inputs));
}
