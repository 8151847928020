import { Popover } from '@packages/shared';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import type { SxProps } from '@mui/material';
import type { ReactNode } from 'react';

interface SlotPopoverProps {
  /** Anchor element for proper popover position */
  anchorEl: HTMLDivElement;
  /** Popover close handler */
  onClose: () => void;
  /** Popover content */
  children: ReactNode;
}

const paperMobileStyles: SxProps = {
  top: '0px !important',
  left: 0,
  right: 0,
  width: '100%',
  maxWidth: '100%',
  overflow: 'visible',
};

const paperDesktopStyles: SxProps = {
  top: '0px !important',
  left: 'auto',
  right: 'auto',
  width: 250,
  maxWidth: 250,
};

/** Slot popover component. On desktop displayed under the slot with fixed width. On mobile devices displayed in full width */
export const SlotPopover = ({ anchorEl, onClose, children }: SlotPopoverProps) => {
  const { isDesktop } = useDeviceType();

  const top = anchorEl.getBoundingClientRect().bottom;

  return (
    <Popover
      open
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        top,
      }}
      PaperProps={{
        sx: isDesktop ? paperDesktopStyles : paperMobileStyles,
        onMouseLeave: isDesktop ? onClose : undefined, // Should not on be the Backdrop otherwise Enter Keypress wiil not open the popover if the Pointer is not in the Header.
      }}
      BackdropProps={{
        sx: {
          top,
          bgcolor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      {children}
    </Popover>
  );
};
