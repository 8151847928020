import { Button, Stack } from '@packages/shared';
import { LoadReload32 } from '@packages/themes/icons';

export const ResponsiveHeaderErrorFallback = () => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="center"
    padding={1}
    bgcolor="error.light"
  >
    <Button
      variant="outlined"
      onClick={() => {
        window.location.reload();
      }}
      aria-label="Reload page"
    >
      <LoadReload32 />
    </Button>
  </Stack>
);
