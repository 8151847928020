import config from '@packages/config';
import type { GetServerSidePropsContext } from 'next';
import type { ParsedUrlQuery } from 'querystring';
import type { Audiences } from '@packages/cms-components/interfaces';
import { isAbTestParticipant } from '@packages/shared/src/abtesting/useAbTesting/utilities.cookie';
import { getCachedTestRuleset } from '@packages/shared/src/abtesting/useAbTesting/utilities.api';
import { getTestCookieName } from '@packages/shared/src/abtesting/useAbTesting/cookieNames/cookieNames';
import { fetchContentSnippetData } from '../utils/fetch/fetchContentSnippetData';
import type { Promotion } from '../types';

type ReturnData = {
  mvtTreatment?: string;
  fetchedShopPromotionData?: (Promotion | undefined)[];
};

type DoTheAbTest = {
  BonusNumber?: string;
  AffiliateID?: string;
  cookies: {
    [x: string]: any;
  };
  context?: GetServerSidePropsContext<ParsedUrlQuery>;
  locale?: string;
  testDate?: string;
  audience?: Audiences;
  platform?: 'web' | 'app';
  callOwnApi?: boolean;
};

export const buildCustomTestRecognizerFunction = (
  affiliateId: string | undefined,
): (() => boolean) => {
  const affiliateIds = ['ladenzeile', 'shopzilla', 'shopzilla_css'];
  return () => {
    if (affiliateId && affiliateIds.includes(affiliateId)) {
      return true;
    }
    return !!(
      affiliateId &&
      affiliateId.startsWith('Google|Produktanzeigen_') &&
      !affiliateId.includes('AF')
    );
  };
};
/**
 * determine if the user is part of an ab test and return the treatment and the fetched shop promotion data
 * A/B-Test rules: user must have a valid test cookie or query parameter AffiliateID must match the rules:
 *  - AffiliateID must be one of the following: ladenzeile, shopzilla, shopzilla_css
 *  - AffiliateID must start with: Google|Produktanzeigen_
 * @param BonusNumber
 * @param AffiliateID
 * @param cookies
 * @param context
 * @param locale
 * @param testDate
 * @param audience
 * @param platform
 * @param callOwnApi
 */
export const doTheAbTest = async ({
  BonusNumber,
  AffiliateID,
  cookies,
  context,
  locale,
  testDate,
  audience,
  platform,
  callOwnApi,
}: DoTheAbTest): Promise<ReturnData> => {
  const testId = config.promotionBanner.abTest;
  let mvtTreatment;
  let fetchedShopPromotionData: (Promotion | undefined)[] | undefined;
  if (testId && !BonusNumber) {
    const testCookie: string = cookies[`${getTestCookieName(testId)}`];
    const customTestRecognizerFunction = buildCustomTestRecognizerFunction(AffiliateID);
    const isParticipant = isAbTestParticipant({
      testCookie,
      customTestRecognizerFunction,
    });
    if (isParticipant) {
      if (context) {
        const { tenantId } = config;
        const abTestData = await getCachedTestRuleset({ tenantId, testId, context, cookies });
        mvtTreatment = abTestData?.variant.treatment;
        if (mvtTreatment === 'control') {
          fetchedShopPromotionData = await fetchContentSnippetData(
            {
              shopId: 'AB-Test',
              locale,
              testDate,
              audience,
              platform,
            },
            callOwnApi,
          );
          return { mvtTreatment, fetchedShopPromotionData };
        }
        return {
          mvtTreatment,
        };
      }
    }
  }
  return {};
};
