'use client';

import { type FC } from 'react';
import { SWRConfig } from 'swr';

import { Box } from '@packages/shared/src/components/Box/Box';
import { Container } from '@packages/shared/src/components/Container/Container';
import { Divider } from '@packages/shared/src/components/Divider/Divider';
import { ErrorBoundary } from '@packages/shared/src/components/ErrorBoundary/ErrorBoundary';

import { SlotsMinimal } from '../Toolbar/Slots/SlotsMinimal';
import { headerDefaultFetcher } from '../api';
import { LinkedLogo } from '../Toolbar/LinkedLogo';

// TODO migrate to use MinimalHeader.layout.tsx once tailwind is stable for all pages, this should replace the Container/Box/Divider in here
export const MinimalHeader: FC = () => (
  // TODO: find out if this is even necessary without navigation in the minimal header, unsure why this default fetcher exists
  <SWRConfig value={{ fetcher: headerDefaultFetcher }}>
    {/* When no inner error boundary catches an error, this is the global boundary for the header. It exists only as backup. When possible, wrap every logical section with a boundary so this one will never show its fallback. */}
    <ErrorBoundary>
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          boxShadow: [1, 1, 1, 0],
          px: [0, 0, 0, 1, 0],
        }}
      >
        <Box
          component="header"
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: [0, 0, 0, 6.5],
            px: [1, 1, 1, 0],
          }}
        >
          <LinkedLogo />
          <ErrorBoundary>
            <SlotsMinimal />
          </ErrorBoundary>
        </Box>
      </Container>

      <Divider sx={{ borderColor: 'grey.main', display: { xs: 'none', lg: 'block' } }} />
    </ErrorBoundary>
  </SWRConfig>
);
