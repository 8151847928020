import { Box } from '@packages/shared';
import { ComponentRecognizer } from '../../ComponentRecognizer';
import { registerComponents } from '../../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../../interfaces';
import type { FooterBlockImageType } from '../types';

type BlockImageProps = {
  data: FooterBlockImageType;
};

export enum BlockImageTestIds {
  main = 'block-image',
}

export const BlockImage = ({ data }: BlockImageProps) => (
  <Box
    sx={{
      '& .dangerously-html': {
        width: 'auto',
        display: 'inline-flex',
        alignContent: 'center',
        flexShrink: 0,
        verticalAlign: 'top',
      },
    }}
    data-testid={BlockImageTestIds.main}
    key={`${BlockImageTestIds.main}-${data.id}`}
  >
    {data.paragraph?.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <ComponentRecognizer key={index} item={item} />
    ))}
  </Box>
);

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.BlockImage]: BlockImage });
