import { ModalByTextlinkTestIds, ModalChild } from './ModalByTextlink.child';
import type { ModalByTextlinkType } from './types';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';

/** Component that initializes multiple modal layers from texts from a CMS application with placeholders. Based on Shared Dialog: @packages/shared/Dialog */
export const ModalByTextlink = ({
  data: { field_content: content, paragraph },
}: {
  data: ModalByTextlinkType;
}) => (
  <div data-testid={ModalByTextlinkTestIds.main}>
    {paragraph &&
      paragraph.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ModalChild key={index} replaceText={content || ''} data={item} />
      ))}
  </div>
);

registerComponents({
  [ComponentTypes.TextlinkModal]: ModalByTextlink,
});
