import { useAuthQuery } from '@packages/gql/src/hooks/useAuthQuery/useAuthQuery';
import { CustomerInformationDataDocument } from '@packages/gql/generated/shopping/CustomerInformationDataDocument';

/* GraphQL */ `
  query CustomerInformationData {
    customerInformation {
      hashEmail
      tmpLegacyHashEmail
      reference
      debitBalance
      dunningThread
      dunningLevel
      creditWorthiness
      accountBlockSign
      blockadeMessageId
      amountDue
      deathSign
      visitor {
        login
        id
        a
        gen
        art
        stat
        loc
        postco
        employee
        rataff
        grp
        noret
        groaff
        sortaff
        gutaff
        appu
        pk
        createDate
        markCC
      }
      newsletter
      newsCount
    }
  }
`;

export const useCustomerInformation = () => {
  const [{ fetching, data }] = useAuthQuery({
    query: CustomerInformationDataDocument,
    requestPolicy: 'cache-and-network',
  });
  return { isLoading: fetching, customerInformation: data?.customerInformation };
};
