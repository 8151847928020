import { logger } from '@packages/shared/src/utils/logger/logger';
import type { Language } from '@packages/config/src/default/types';
import type { Promotion } from '../../types';

export const fetchAndActivatePromotion = async (
  promotionCode: string,
  setVoucher: (promotionData: Promotion) => void,
  language: Language,
) => {
  fetch(`/api/promotions/promotion/${promotionCode}/?locale=${language}`)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      if (response.status >= 500) {
        throw new Error(response.statusText);
      }
      return null;
    })
    .then((promotionData: Promotion) => {
      if (promotionData) {
        setVoucher(promotionData);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        logger.warn(`no promotion data for promotionCode ${promotionCode}`);
      }
    })
    .catch((error) => {
      logger.error({ error }, 'set remote promotion error');
    });
};
