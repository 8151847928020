'use client';

import type { Config } from '@packages/config/src/default/types';
import { useSearchParams } from 'next/navigation';
import { dateCompare } from '../dateCompare/dateCompare';
import { parseTestDate } from '../timeUtils/timeUtils';

export type TimeControl = {
  field_valid_from?: string | null;
  field_valid_to?: string | null;
  field_valid_until?: string | null;
  field_visible_from?: string | null;
  field_visible_until?: string | null;
  behavior_paragraph_time_control_valid_from?: string | null;
  behavior_paragraph_time_control_valid_until?: string | null;
  behavior_paragraph_visibility_officehours?:
    | 'unset'
    | 'during-office-hours'
    | 'outside-office-hours';
};

const weekday = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'] as const;

/**
 * Determines if the current time (or time in testDate) is within the configured office time range.
 * Example: {
      mon: '08:00-21:00',
      tue: '08:00-21:00',
      wed: '08:00-21:00',
      thu: '08:00-21:00',
      fri: '08:00-21:00',
      sat: '08:00-12:00',
    }
 * */
const isInsideOfficeTime = (
  officeTimes: Config['officeTimes'],
  testDate: string | string[] | undefined | null = undefined,
) => {
  const currentDate: Date = parseTestDate({ testDate }) || new Date();

  const currentDayConfig = officeTimes[weekday[currentDate.getDay()]];

  // if there is no config for today, today the office is closed.
  if (!currentDayConfig) {
    return false;
  }

  const hour = currentDate.getHours();
  const minute = currentDate.getMinutes();

  // "08:00-21:00" -> ["08:00", "21:00"]
  const [timeRangeStart, timeRangeEnd] = currentDayConfig.split('-');

  // "08:00" -> [8,0]
  const [timeRangeHourStart, timeRangeMinuteStart] = timeRangeStart
    .split(':')
    .map((str) => Number.parseInt(str, 10));

  // "21:00" -> [21,0]
  const [timeRangeHourEnd, timeRangeMinuteEnd] = timeRangeEnd
    .split(':')
    .map((str) => Number.parseInt(str, 10));

  // shop is open if we are inside opening hours
  if (timeRangeHourStart < hour && hour < timeRangeHourEnd) {
    return true;
  }

  // shop is closed if we are before or after opening hours
  if (hour < timeRangeHourStart || hour > timeRangeHourEnd) {
    return false;
  }

  // start/ending hours are equal to current hour
  const minutesSinceMidnight = hour * 60 + minute;
  const closingTimeMinutes = timeRangeHourEnd * 60 + timeRangeMinuteEnd;
  const openingTimeMinutes = timeRangeHourStart * 60 + timeRangeMinuteStart;

  return openingTimeMinutes <= minutesSinceMidnight && minutesSinceMidnight <= closingTimeMinutes;
};

export const filterTimeControl = (
  item: TimeControl,
  testDate: string | string[] | undefined | null = undefined,
  officeTimes: Config['officeTimes'] = {},
): boolean => {
  // support valid from and valid until fields from paragraphs and content snippets INSPIRE-2298
  const {
    field_valid_from: validFrom,
    field_valid_to: validTo,
    field_valid_until: validUntil,
    field_visible_from: visibleFrom,
    field_visible_until: visibleUntil,
    behavior_paragraph_time_control_valid_from: behaviorValidFrom,
    behavior_paragraph_time_control_valid_until: behaviorValidUntil,
    behavior_paragraph_visibility_officehours: visibilityOfficeHours = 'unset',
  } = item;

  // if there is a setting for office hours visibility, use only this and ignore normal time control.
  if (visibilityOfficeHours !== 'unset') {
    return visibilityOfficeHours === 'during-office-hours'
      ? isInsideOfficeTime(officeTimes, testDate)
      : !isInsideOfficeTime(officeTimes, testDate);
  }

  return dateCompare({
    validFrom: behaviorValidFrom || validFrom || visibleFrom,
    validTo: behaviorValidUntil || validTo || visibleUntil || validUntil,
    testDate: Array.isArray(testDate) ? testDate[0] : testDate,
  });
};

export const useFilterTimeControl = (): ((item: TimeControl) => boolean) => {
  const query = Object.fromEntries(useSearchParams()?.entries() ?? []);
  const { TestDate } = query;
  return (item: TimeControl, officeTimes: Config['officeTimes'] = {}): boolean =>
    filterTimeControl(item, TestDate, officeTimes);
};
