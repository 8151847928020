import { Snackbar } from '@mui/material';
import type { SnackbarProps } from '@mui/material';
import type { ReactNode } from 'react';
import type { AlertProps } from './Alert';
import { Alert } from './Alert';

export interface NotificationProps extends Omit<SnackbarProps, 'children' | 'onClick'> {
  children: ReactNode;
  severity?: AlertProps['severity'];
  onClose?: (event?: React.SyntheticEvent | Event, reason?: string) => void;
}

/** Alert in Snackbar notification. Based on MUI Snackbar: https://mui.com/material-ui/react-snackbar/ */
export const Notification = ({ severity, children, ...props }: NotificationProps) => (
  <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} {...props}>
    <Alert severity={severity} onClose={props.onClose}>
      {children}
    </Alert>
  </Snackbar>
);
