'use client';

import type { ReactNode, TransitionStartFunction } from 'react';
import { createContext, useMemo, useState, useTransition } from 'react';

export const PageTransitionProviderContext = createContext<{
  start: TransitionStartFunction;
  setUrl: (url: string) => void;
  loading: boolean;
  url?: string;
} | null>(null);

export const PageTransitionProvider = ({ children }: { children: ReactNode }) => {
  const [url, setUrl] = useState<string>();
  const [loading, start] = useTransition();

  return (
    <PageTransitionProviderContext.Provider
      value={useMemo(() => ({ start, loading, setUrl, url }), [loading, url])}
    >
      {children}
    </PageTransitionProviderContext.Provider>
  );
};
