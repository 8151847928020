import type { FC } from 'react';

import { useNavigationData } from '../useNavigationData';
import type { ResponsiveNavigationProps } from './ResponsiveNavigation';
import { ResponsiveNavigation } from './ResponsiveNavigation';
import type { RawNavigationItem } from './navigationTypes';

/**
 * Data fetching wrapper around {@link ResponsiveNavigation}
 */
export const ResponsiveNavigationLoader: FC<Pick<ResponsiveNavigationProps, 'mode'>> = (props) => {
  const { data, loadFullData } = useNavigationData();

  return (
    <ResponsiveNavigation
      {...props}
      items={data as RawNavigationItem[]}
      onFirstInteraction={loadFullData}
    />
  );
};
