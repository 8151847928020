import config from '@packages/config';

const isDarkStyle = config.styleVariant === 'dark';
const listIcon = isDarkStyle ? '\\2192' : '\\2713';

/**
 * The HTML paragraph from the Drupal JSON contains HTML elements with assigned CSS classes.
 * These elements will display graphical hooks based on their CSS classes.
 */
export const footerListStyles = {
  '& ul, & ol': {
    m: 0,
  },
  '& ul': {
    paddingLeft: 0,
    listStyleType: 'none',
    '& li': {
      mb: 1,
      '&.type-1': {
        display: 'flex',
        ml: 0,
        '&::before': {
          content: `"${listIcon}"`,
          ...(isDarkStyle
            ? {
                mr: 2,
                fontSize: '1.5rem',
              }
            : {
                fontWeight: 'bold',
              }),
          flexShrink: 0,
          flexBasis: '1rem',
          display: 'inline-flex',
          width: ['1.25rem', '1rem'],
          height: ['0.94rem', '0.8rem'],
        },
      },
      '&.type-3': {
        pl: [2.5, 2],
      },
    },
  },
};
