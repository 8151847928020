import type { FC } from 'react';
import { Suspense, lazy } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { User32 } from '@packages/themes/icons';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { useI18n } from '@packages/shared/src/hooks/useI18n/useI18n';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { getLocalizedPath } from '@packages/shared/src/utils/getLocalizedPath/getLocalizedPath';
import { useCustomerInformation } from '../../../hooks/useCustomerInformation/useCustomerInformation';
import { LoginDialogComposed } from '../../../LoginDialog/LoginDialogComposed/LoginDialogComposed';
import { Slot, type SlotProps } from './Slot';

const title = defineMessage({
  id: 'slot.account.label',
  defaultMessage: 'Mein Konto',
});

// dynamic import to avoid loading the mybuy stuff on initial render
const AccountPopoverContent = lazy(
  () => import(/* webpackChunkName: 'AccountPopoverContent' */ './AccountPopoverContent'),
);

export type AccountSlotProps = Pick<SlotProps, 'sx'>;

/** My account slot */
export const AccountSlot: FC<AccountSlotProps> = ({ sx }) => {
  const { customerInformation } = useCustomerInformation();
  const { formatMessage } = useIntl();
  const { locale } = useI18n();
  const { lifeSupportForBasicallyDeadTenant, order } = useConfig();

  const { isMobile } = useDeviceType();

  const href = getLocalizedPath(order.paths.myAccountOverview, locale);

  return isMobile ? (
    <Slot
      useRouterLink={false}
      href={href}
      icon={<User32 />}
      badgeCount={customerInformation?.newsCount ?? undefined}
      linkAriaLabel={formatMessage(title)}
      sx={sx}
    />
  ) : (
    <LoginDialogComposed>
      {(openLoginDialog) => (
        <Slot
          useRouterLink={false}
          href={href}
          icon={<User32 />}
          title={formatMessage(title)}
          badgeCount={customerInformation?.newsCount ?? undefined}
        >
          {lifeSupportForBasicallyDeadTenant ? null : (
            <Suspense>
              <AccountPopoverContent onLogin={openLoginDialog} />
            </Suspense>
          )}
        </Slot>
      )}
    </LoginDialogComposed>
  );
};
