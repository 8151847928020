import { Link } from '@mui/material';
import type { LinkProps as MuiLinkProps } from '@mui/material';
import type { GTMEventGlycerinLinkClick } from '@packages/tracking/src/types/events';
import type { GlycerinLinkClickPayload } from '@packages/tracking/src/types/payloads';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { useIsBot } from '@packages/tracking/src/hooks/useIsBot/useIsBot';
import { getCanonicalUrl } from '../../utils/getCanonicalUrl/getCanonicalUrl';

export type AnchorProps = MuiLinkProps & {
  clickTrackingProps?: Omit<GlycerinLinkClickPayload, 'target'>;
};

/** Shows simple anchor link around the children component. Based on MUI Link: https://mui.com/material-ui/react-link/ */
export const Anchor: React.FC<AnchorProps> = ({ clickTrackingProps, onClick, ...props }) => {
  const dispatchGTMEvent = useTracking();
  const { children, href } = props;
  let linkTarget = href;
  const isBot = useIsBot();
  if (isBot) {
    linkTarget = getCanonicalUrl(href ?? '');
  }
  const { category, label, detail, placement, variantIds, custom } = clickTrackingProps ?? {};
  return (
    <Link
      onClick={(event) => {
        dispatchGTMEvent<GTMEventGlycerinLinkClick>({
          event: 'LinkClick',
          LinkClickData: {
            category: category ?? 'link-click',
            target: href,
            label,
            detail,
            placement,
            variantIds,
            custom,
          },
        });
        if (onClick) {
          onClick(event);
        }
      }}
      href={linkTarget}
      {...props}
    >
      {children}
    </Link>
  );
};
