import { useEffect } from 'react';

/**
 * Custom hook that triggers only once a callback function on the first mouse movement or touch start event.
 *
 * @param onFirstMouseMovement - The callback function to be executed on the first mouse movement or touch start event.
 *
 * It checks the user agent string to ensure the code is not running on 'www.empiriecom.com'.
 *
 * @remarks
 * The `onFirstMouseMovement` callback has the possibility to be executed twice if a device supports both `mousemove` and `touchstart` events,
 * such as a touchscreen desktop. However, the mouse movement outcome would not be set twice for a test, because it already checks if it was sent or not.
 */
export const useFirstMouseMovement = (onFirstMouseMovement: () => void) => {
  useEffect(() => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      const { userAgent } = navigator;
      if (!userAgent.toLowerCase().includes('www.empiriecom.com')) {
        document.addEventListener('mousemove', onFirstMouseMovement, { once: true });
        document.addEventListener('touchstart', onFirstMouseMovement, { once: true });
      }
    }

    return () => {
      document.removeEventListener('mousemove', onFirstMouseMovement);
      document.removeEventListener('touchstart', onFirstMouseMovement);
    };
  }, [onFirstMouseMovement]);
};
