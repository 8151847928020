import type { Device } from '@packages/themes/src/default';
import useSWR from 'swr/immutable';

export const userAgentDeviceCacheKey = 'userAgentDevice';

/**
 * Detect the actual device type used by the user, separate from the media-query-related device size.
 *
 * @returns The detected device
 */
export const useUserAgentDevice = () => {
  const { data: userAgentDevice } = useSWR<Device>(
    userAgentDeviceCacheKey,
    (): Device => 'desktop',
  );

  return userAgentDevice ?? 'desktop';
};
