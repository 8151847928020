'use client';

import { useState, useEffect, useCallback } from 'react';

export type DeviceType = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

const getDeviceType = (width: number): DeviceType => {
  if (width < 600) {
    return { isMobile: true, isTablet: false, isDesktop: false };
  }
  if (width >= 600 && width < 900) {
    return { isMobile: false, isTablet: true, isDesktop: false };
  }
  return { isMobile: false, isTablet: false, isDesktop: true };
};

const debounce = <F extends (...args: any[]) => any>(func: F, wait: number) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  return (...args: Parameters<F>) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const useDevice = (
  initialValue: DeviceType = { isMobile: true, isTablet: false, isDesktop: false },
) => {
  const [deviceType, setDeviceType] = useState<DeviceType>(initialValue);

  const handleResize = useCallback(() => {
    setDeviceType(getDeviceType(window.innerWidth));
  }, []);

  useEffect(() => {
    const debouncedHandleResize = debounce(handleResize, 250);

    // Set the initial value on the client side
    handleResize();

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [handleResize]);

  return deviceType;
};
