import type { NavItem } from '../types';

export * from './getNavigationClusters';

export const findNode = (id: string, currentNode: any): NavItem | false => {
  let i;
  let currentChild;
  let result;

  if (id === currentNode.categoryId) {
    return currentNode;
  }
  for (i = 0; i < currentNode?.children?.length; i += 1) {
    currentChild = currentNode.children[i];
    result = findNode(id, currentChild);
    // Return the result if the node has been found
    if (result !== false) {
      return result;
    }
  }
  // The node has not been found and we have no more options
  return false;
};
