import { Tab, Tabs } from '@packages/shared';
import { defineMessages, useIntl } from 'react-intl';

export type SubMenuTabsProps = {
  value: 'menu' | 'account';
  onChange?: (tab: 'menu' | 'account') => void;
};

const messages = defineMessages({
  menu: {
    id: 'slot.account.menu',
    defaultMessage: 'Menü',
  },
  account: {
    id: 'slot.account.label',
    defaultMessage: 'Mein Konto',
  },
});

export const SubMenuTabs = ({ value, onChange }: SubMenuTabsProps) => {
  const { formatMessage } = useIntl();

  return (
    <Tabs
      sx={{
        display: { lg: 'none' },
        gridArea: 'toggle',
        borderBottom: '1px solid',
        borderColor: 'grey.main',
      }}
      value={value === 'menu' ? 0 : 1}
      onChange={(_, index) => {
        onChange?.(index === 0 ? 'menu' : 'account');
      }}
      variant="fullWidth"
    >
      <Tab label={formatMessage(messages.menu)} />
      <Tab label={formatMessage(messages.account)} />
    </Tabs>
  );
};
