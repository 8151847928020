import { useContext } from 'react';

import { TrackingContext } from '../../components/TrackingProvider/TrackingProvider';

export const useIsBot = (): boolean => {
  const context = useContext(TrackingContext);

  if (context === null) {
    // eslint-disable-next-line no-console
    console.error('[useTracking]: hook use outside TrackingProvider component.');
    return false;
  }

  const { isBot } = context;

  return isBot;
};
