import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import type {
  GlycerinImageClickPayload,
  GlycerinDisplayImagePayload,
} from '@packages/tracking/src/types/payloads';
import { type GTMEventGlycerinImageClick } from '@packages/tracking/src/types/events';
import { useRef, type FC } from 'react';
import type { ImageProps } from 'next/image';
import NextImage from 'next/image';
import { akamaiLoader } from './loader';
// import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';

export type NextImageProps = ImageProps & {
  clickTrackingProps?: GlycerinImageClickPayload;
  displayTrackingProps?: GlycerinDisplayImagePayload;
};

// TODO: img sizes and akamai settings tbd and custom akamai image loader has to be build
export const getImageSize = (src: NextImageProps['src']) => {
  const type = typeof src === 'string' && src?.split('?').pop();
  switch (type) {
    case '$PL_portrait_hq$':
      return { width: 339, height: 485 };
    case '$PL_landscape_hq$':
      return { width: 399, height: 236 };
    case '$PL_square_highlight$':
      return { width: 1000, height: 1000 };
    default:
      // TODO: check what if no case match
      // return { width: 339, height: 485 };
      return undefined;
  }
};

/**
 * Image component based on https://nextjs.org/docs/basic-features/image-optimization
 * */
export const Image: FC<NextImageProps> = ({
  src,
  width,
  height,
  onClick,
  clickTrackingProps,
  displayTrackingProps,
  ...props
}) => {
  const wrapper = useRef<HTMLImageElement>(null);
  const dispatchGTMEvent = useTracking();
  const { category, detail, imageId, label, placement, source, target, variantIds, custom } =
    clickTrackingProps ?? {};
  /* const {
    imageId: displayTrackingImageId,
    source: displayTrackingSource,
    category: displayTrackingCategory,
    custom: displayTrackingCustom,
    detail: displayTrackingDetail,
    label: displayTrackingLabel,
    placement: displayTrackingPlacement,
    target: displayTrackingTarget,
    variantIds: displayTrackingVariantIds,
  } = displayTrackingProps ?? {}; */
  let imagesrc: string;
  if (typeof src === 'object') {
    if ('default' in src) {
      imagesrc = src.default.src;
    } else {
      imagesrc = src.src;
    }
  } else {
    imagesrc = src;
  }

  // check if the image is fully visible
  /* useIntersectionObserver(wrapper, {
    intersectOnce: true,
    threshold: 1,
    callback: (isIntersecting) => {
      if (!isIntersecting) {
        return;
      }
      // intersectOnce is set to true, so this callback will only be called once
      dispatchGTMEvent<GTMEventGlycerinDisplayImage>({
        event: 'DisplayImage',
        DisplayImageData: {
          category: displayTrackingCategory ?? 'display-image',
          custom: displayTrackingCustom,
          detail: displayTrackingDetail,
          imageId: displayTrackingImageId,
          label: displayTrackingLabel,
          placement: displayTrackingPlacement,
          source: displayTrackingSource ?? imagesrc,
          target: displayTrackingTarget,
          variantIds: displayTrackingVariantIds,
        },
      });
    },
  }); */

  return (
    <NextImage
      ref={wrapper}
      onClick={(event) => {
        dispatchGTMEvent<GTMEventGlycerinImageClick>({
          event: 'ImageClick',
          ImageClickData: {
            category: category ?? 'image-click',
            detail,
            imageId,
            label,
            placement,
            source: source ?? imagesrc,
            target,
            variantIds,
            custom,
          },
        });
        if (onClick) {
          onClick(event);
        }
      }}
      src={src}
      width={props.fill ? undefined : width || getImageSize(src)?.width}
      height={props.fill ? undefined : height || getImageSize(src)?.height}
      loader={akamaiLoader}
      unoptimized={process.env.NODE_ENV === 'test'}
      {...props}
    />
  );
};
