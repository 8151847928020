import { Skeleton } from '@packages/shared';
import type { SxProps } from '@mui/material';
import { LinearSuggestionsGroup } from './LinearSuggestions.Group';
import { LinearSuggestionsItem } from './LinearSuggestions.Item';
import { LinearSuggestionsAdoptableItem } from './LinearSuggestions.AdoptableItem';

export const LinearSuggestionsFallback = ({ sx }: { sx?: SxProps }) => (
  <LinearSuggestionsGroup heading={<Skeleton width="40%" />} sx={sx}>
    {Array.from({ length: 3 })
      .map((_, i) => `example-placeholder${i}`)
      .map((id) => (
        <LinearSuggestionsAdoptableItem key={id}>
          <Skeleton variant="text" width="60%" />
        </LinearSuggestionsAdoptableItem>
      ))}
    <LinearSuggestionsItem>
      <Skeleton width="70%" />
    </LinearSuggestionsItem>
  </LinearSuggestionsGroup>
);
