import type { BoxProps } from '@packages/shared';
import { Box } from '@packages/shared';

export const ResponsiveBackground = ({ sx }: Pick<BoxProps, 'sx'>) => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 'var(--background-bleed)',
      right: 'var(--background-bleed)',
      zIndex: -1,
      ...sx,
    }}
  />
);
