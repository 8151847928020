import { defineMessage, useIntl } from 'react-intl';
import { PaybackColored32 } from '@packages/themes/icons';
import { Slot } from './Slot';

const title = defineMessage({
  id: 'slot.payback.label',
  defaultMessage: 'PAYBACK',
});

/** Service slot with list of helpers in popover */
export const PaybackSlot = () => {
  const { formatMessage } = useIntl();

  return (
    <Slot
      href="/service-hilfe/bestellen-beraten/payback"
      icon={<PaybackColored32 />}
      title={formatMessage(title)}
    />
  );
};
