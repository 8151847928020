import type { TableProps as MuiTableProps } from '@mui/material';
import { Table as MuiTable, TableContainer as MuiTableContainer } from '@mui/material';

/**
 * <p>Basic Table wrapper. It consists of:</p>
 * <ul>
 *  <li>`MuiTableContainer` component: https://mui.com/material-ui/api/table-container/</li>
 *  <li>`MuiTable` component: https://mui.com/material-ui/api/table/</li>
 * </ul>
 * <p>
 * Based on MUI Table: https://mui.com/material-ui/react-table/
 * </p>
 * */
export const Table = (props: MuiTableProps) => (
  <MuiTableContainer>
    <MuiTable {...props} />
  </MuiTableContainer>
);
