import dynamic from 'next/dynamic';

export const DynamicInspiringSearchSuggestions = dynamic(
  () => import(/* webpackChunkName: 'InspiringSearchSuggestions' */ './InspiringSearchSuggestions'),
  {
    ssr: false,
  },
);

export * from './InspiringSearchSuggestions.Loading';
