import { useEffect, useReducer, useRef } from 'react';

export type ToggleTransitionAction = {
  type: 'show' | 'hide' | 'tick' | 'done';
};

export type ToggleTransitionState =
  | 'enter-prepare'
  | 'enter-transition'
  | 'enter-done'
  | 'leave-prepare'
  | 'leave-transition'
  | 'leave-done';

const stateToClassNames = (state: ToggleTransitionState) => {
  switch (state) {
    case 'enter-prepare':
      return 'transition-enter-active transition-enter-from';
    case 'enter-transition':
      return 'transition-enter-active transition-enter-to';
    case 'leave-prepare':
      return 'transition-leave-active transition-leave-from';
    case 'leave-transition':
      return 'transition-leave-active transition-leave-to';
    default:
      return '';
  }
};

export const useToggleTransition = (
  durationMilliseconds: number = 300,
  startToggledOn: boolean = false,
) => {
  const [state, dispatch] = useReducer(
    (current: ToggleTransitionState, action: ToggleTransitionAction): ToggleTransitionState => {
      if (current.startsWith('leave') && action.type === 'show') {
        return 'enter-prepare';
      }

      if (current.startsWith('enter') && action.type === 'hide') {
        return 'leave-prepare';
      }

      if (current === 'enter-prepare' && action.type === 'tick') {
        return 'enter-transition';
      }

      if (current === 'leave-prepare' && action.type === 'tick') {
        return 'leave-transition';
      }

      if (current === 'enter-transition' && action.type === 'done') {
        return 'enter-done';
      }

      if (current === 'leave-transition' && action.type === 'done') {
        return 'leave-done';
      }

      return current;
    },
    startToggledOn ? 'enter-done' : 'leave-done',
  );

  const activeTimeout = useRef<ReturnType<typeof setTimeout>>();

  const cleanup = () => {
    if (activeTimeout.current) {
      clearTimeout(activeTimeout.current);
    }
    activeTimeout.current = undefined;
  };

  useEffect(() => {
    if (state.endsWith('prepare')) {
      activeTimeout.current = setTimeout(() => {
        dispatch({ type: 'tick' });
      });
    }
    if (state.endsWith('transition')) {
      activeTimeout.current = setTimeout(() => {
        dispatch({ type: 'done' });
      }, durationMilliseconds);
    }

    return () => {
      cleanup();
    };
  }, [durationMilliseconds, state]);

  return {
    state,
    shouldBeMounted: state !== 'leave-done',
    classNames: stateToClassNames(state),
    show: () => dispatch({ type: 'show' }),
    hide: () => dispatch({ type: 'hide' }),
    toggle: () =>
      state.startsWith('enter') ? dispatch({ type: 'hide' }) : dispatch({ type: 'show' }),
  };
};
