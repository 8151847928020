'use client';

import type { FC } from 'react';
import { useEffect } from 'react';
import { useLoginState } from '@packages/shared/src/hooks/useLoginState/useLoginState';
import type { GTMEventPageMetaData } from '@packages/tracking/src/hooks/useTracking/types';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { useIsBot } from '@packages/tracking/src/hooks/useIsBot/useIsBot';
import { GlycerinLoginStatus } from '@packages/tracking/src/types/enums';
import type { GTMEventGlycerinPageview } from '@packages/tracking/src/types/events';
import { mapPageTypeAndTemplate } from '@packages/tracking/src/utils/mapPageTypeAndTemplate';
import { useI18n } from '@packages/shared/src/hooks/useI18n/useI18n';
import { useCustomerInformation } from '../hooks/useCustomerInformation/useCustomerInformation';

export type PageMetaDataProps = Omit<
  GTMEventPageMetaData,
  'event' | 'eventAction' | 'eventCategory' | 'eventLabel' | 'eventValue' | 'page'
> & {
  /** page meta data information */
  page: {
    /** the page type (e.g. 'serp', 'detailview') */
    type: string;
    /** optional template information */
    template?: string;
  };
  /** optional url pathname for useEffect deps array */
  pathname?: string;
};

/**
 * This component dispatches the following GTM events:
 * - dataLayerReady
 * - pageMetaData
 * */
export const PageMetaData: FC<PageMetaDataProps> = ({
  page,
  categoryPath,
  pathname,
  query,
  searchId,
}) => {
  const dispatchGTMEvent = useTracking();
  const isBot = useIsBot();
  const { isLoading, customerInformation } = useCustomerInformation();
  const { loggedIn, softLoggedIn } = useLoginState();
  const { language, locale } = useI18n();

  const { type, template } = page;

  const getLoginStatus = (logged: boolean, softLogged: boolean): GlycerinLoginStatus => {
    if (softLogged) {
      return GlycerinLoginStatus.SOFTLOGGEDIN;
    }
    if (logged) {
      return GlycerinLoginStatus.LOGGEDIN;
    }
    return GlycerinLoginStatus.NOTLOGGEDIN;
  };

  useEffect(() => {
    if (!isLoading && customerInformation) {
      dispatchGTMEvent({
        event: 'dataLayerReady',
        Sprache: locale,
        LoginStatus: getLoginStatus(loggedIn, softLoggedIn),
        ...customerInformation,
        visitor: {
          ...customerInformation?.visitor,
          login: loggedIn,
        },
      });
    }
    // Fix for CORE-556: searchId has been added to trigger dataLayerReady on MBA/SERP changes.
    // TODO: rework events within tracking project
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, customerInformation?.visitor.login, locale, loggedIn, searchId, softLoggedIn]);

  /** Fire glycerin pageview event */
  useEffect(() => {
    if (!isLoading) {
      const event: GTMEventGlycerinPageview = {
        event: 'Pageview',
        PageviewData: {
          loginStatus: getLoginStatus(loggedIn, softLoggedIn),
          pageTypes: mapPageTypeAndTemplate(type, template),
          language,
        },
      };
      dispatchGTMEvent(event);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, pathname, template, query, isBot, language, isLoading, softLoggedIn, loggedIn]);

  useEffect(() => {
    const event: GTMEventPageMetaData = {
      event: 'pageMetaData',
      page: {
        type,
        template,
        bot: isBot,
      },
      categoryPath,
      query,
      searchId,
      pageViewLanguage: language,
    };
    if (typeof window !== 'undefined') {
      (window as any).pagetype = type;
    }
    dispatchGTMEvent(event);
    // remove categoryPath from array, it is not a primitive value and is kinda unpredictable
    // remove searchId from array, it changes for every settings change and is only relevant for MBA and SERP
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, pathname, template, query, isBot]);

  return <div data-testid="page-meta-data" />;
};
