import {
  useCookies,
  type CookieOptions,
} from '@packages/shared/src/providers/CookieProvider/CookieProvider';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { CloseIconButton, Typography, Link } from '@packages/shared';
import { Box } from '@mui/material';
import { AppIcon } from '@packages/themes/icons';
import { defineMessages, useIntl } from 'react-intl';
import { useState } from 'react';

const { title, description, action } = defineMessages({
  title: {
    id: 'appbanner.title',
    defaultMessage: 'Unsere App',
  },
  description: { id: 'appbanner.description', defaultMessage: 'Kostenlos im Store' },
  action: { id: 'appbanner.action', defaultMessage: 'Jetzt anzeigen' },
});

export const appBannerCookieName = 'bannerDisabled';

const COOKIE_OPTIONS: CookieOptions = {
  path: '/',
  maxAge: 86400, // 90 days in seconds
  secure: false, // true would be nice but this is what intershop defines,
  sameSite: 'lax',
  httpOnly: false,
};

export const AppBanner = () => {
  const { getCookies, setCookie } = useCookies();
  const {
    appBanner: { appUrl },
  } = useConfig();
  const [showBanner, setShowBanner] = useState(true);

  const { formatMessage } = useIntl();

  const handleClose = () => {
    setShowBanner(false);
    setCookie(null, appBannerCookieName, 'true', COOKIE_OPTIONS);
  };

  const cookies = getCookies();

  if (!showBanner || cookies[appBannerCookieName]) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        p: 2,
        bgcolor: 'grey.light',
      }}
      data-testid="app-banner"
    >
      <CloseIconButton
        sx={{
          position: 'absolute',
          top: '0.5rem',
          right: '0.5rem',
          backgroundColor: 'grey.main',
          color: 'common.white',
          p: 0,
        }}
        onClick={handleClose}
      />
      <Link
        href={appUrl}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
        noLinkStyle
      >
        <Box
          sx={{
            backgroundColor: 'common.white',
            borderRadius: '0.5rem',
          }}
        >
          <AppIcon sx={{ display: 'block', width: '4rem', height: '4rem' }} />
        </Box>
        <Box>
          <Typography>{formatMessage(title)}</Typography>
          <Typography sx={{ fontWeight: 'bold' }}>{formatMessage(description)}</Typography>
          <Typography sx={{ fontWeight: 'bold' }}>{formatMessage(action)}</Typography>
        </Box>
      </Link>
    </Box>
  );
};
