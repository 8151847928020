import { usePaceId } from './usePaceId';

/**
 * PaceId component is used to scope `usePaceId` hook, so it does not trigger any rerenders in Header root component.
 * @returns null
 */
export const PaceId = () => {
  usePaceId();
  return null;
};
