import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgBurgerText32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M32 32H0v-1h32zm0-8H0v-1h32zm0-9v1H0v-1zM26.47 1.908v5.471c0 .768.196 1.35.588 1.746.392.396.959.594 1.697.594.702 0 1.234-.198 1.6-.594.365-.396.548-.978.548-1.746V1.908H32v5.471c0 1.096-.283 1.918-.844 2.466-.561.548-1.362.822-2.401.822-1.062 0-1.89-.262-2.487-.786-.597-.524-.896-1.358-.896-2.502V1.908zm-24.969 0 2.597 7.199 2.609-7.199h1.5v8.567H7.168V3.348h-.023l-2.574 7.127h-.935L1.062 3.348h-.023v7.127H0V1.908zm14.223 0v.96h-4.595v2.723h4.283v.96h-4.283v2.964h4.629v.96h-5.726V1.908zm2.516 0 4.34 6.947h.023V1.908h1.039v8.567h-1.201L18.135 3.6h-.023v6.875h-1.038V1.908zM28.121 0v1.248h-1.039V0zm2.17 0v1.248h-1.039V0z" className="burger-text-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgBurgerText32);
export default Memo;