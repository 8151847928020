import { useCallback, useRef, useState } from 'react';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import type { NavigationItem } from '../navigationTypes';
import { getNavigationItemKey } from '../getNavigationItemKey';

export type TreeNavigationDirection = 'leaf' | 'root' | 'lateral';

export type UseTreeNavigationPopupOptions = {
  /**
   * Interaction mode with the main navigation
   *
   * @default 'click'
   */
  mode?: 'click' | 'hover';
  /** Fired when the user first interacts with the nav bar */
  onFirstInteraction?: () => void;
};

/**
 * Hook for managing the state of a tree navigation popup, and all related logic and workarounds.
 */
export const useTreeNavigationPopup = ({
  onFirstInteraction,
}: UseTreeNavigationPopupOptions = {}) => {
  const { isDesktop } = useDeviceType();

  const defaultNavigationDirection = isDesktop ? 'lateral' : 'leaf';
  const [navigationDirection, setNavigationDirection] = useState<TreeNavigationDirection>(
    defaultNavigationDirection,
  );
  const [selectedKey, setSelectedKey] = useState<string>();
  const [showPopup, setShowPopup] = useState(false);
  const parentStack = useRef<string[]>([]);
  const closePopup = useCallback(() => {
    setNavigationDirection(defaultNavigationDirection);
    setSelectedKey(undefined);
    setShowPopup(false);
  }, [defaultNavigationDirection]);

  const isSelected = (item: NavigationItem) => getNavigationItemKey(item) === selectedKey;

  const isFirstInteraction = useRef(true);

  const selectMainItem = (item: NavigationItem, preventToggle = false) => {
    if (isFirstInteraction.current) {
      isFirstInteraction.current = false;
      onFirstInteraction?.();
    }

    const isAlreadyOpen = showPopup && isSelected(item);
    if ((isAlreadyOpen && !preventToggle) || !item.children.length) {
      closePopup();
      return;
    }

    parentStack.current = [];
    setNavigationDirection(defaultNavigationDirection);
    setSelectedKey(getNavigationItemKey(item));
    setShowPopup(true);
  };

  const selectPopupItem = (item: NavigationItem) => {
    if (!selectedKey || !item.children.length) {
      closePopup();
      return;
    }

    parentStack.current.push(selectedKey);
    setNavigationDirection('leaf');
    setSelectedKey(getNavigationItemKey(item));
  };

  const backToParent = useCallback(() => {
    const parentKey = parentStack.current.pop();
    if (parentKey) {
      setNavigationDirection('root');
      setSelectedKey(parentKey ?? null);
    } else {
      closePopup();
    }
  }, [closePopup]);

  const isRoot = (item: NavigationItem) =>
    getNavigationItemKey(item) === (parentStack.current[0] ?? selectedKey);

  return {
    navigationDirection,
    showPopup,
    isSelected,
    isRoot,
    selectMainItem,
    selectPopupItem,
    backToParent,
    /** Memoized function, safe to use as a useEffect dependency */
    closePopup,
  };
};
