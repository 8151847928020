import { Box, Grid, Skeleton, Typography } from '@packages/shared';

export const SearchTermSuggestionsLoading = () => {
  const columns = { xs: 1, lg: 2 };

  const simplePlaceholders = (
    <Box>
      <Typography sx={{ px: 2, py: 1, bgcolor: 'grey.main' }} variant="body2">
        <Skeleton width="30%" />
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          paddingY: 0.5,
          paddingLeft: 2,
        }}
      >
        {Array.from({ length: 5 }).map((_, index) => (
          <Skeleton
            // eslint-disable-next-line react/no-array-index-key
            key={`loading-skeleton-${index}`}
            width="40%"
          />
        ))}
      </Box>
    </Box>
  );

  return (
    <Grid
      container
      sx={{ overflowY: ['scroll', 'auto', 'auto'], maxHeight: '50vh' }}
      columns={columns}
    >
      <Grid item xs={1} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {simplePlaceholders}
        {simplePlaceholders}
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          borderLeftColor: ['grey.main'],
          borderLeftWidth: { lg: [0, 1] },
          borderLeftStyle: 'solid',
        }}
      >
        <Box>
          <Typography sx={{ px: 2, py: 1, bgcolor: 'grey.main' }} variant="body2">
            <Skeleton width="30%" />
          </Typography>
          {Array.from({ length: 3 }).map((_1, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={i} sx={{ display: 'flex', paddingX: 2, paddingY: 0.5, gap: 1 }}>
              <Skeleton variant="rectangular" width="4rem" height="5rem" />
              <Box sx={{ flex: 1 }}>
                {Array.from({ length: 3 }).map((_2, j) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Skeleton key={j} width="80%" />
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};
