import { useEffect, useRef } from 'react';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useSearchParams } from 'next/navigation';

const debugModeAtom = atom(false);

const CLICKS_TO_ACTIVATE_DEBUG_MODE = 7;
const TIME_WINDOW_FOR_CONSECUTIVE_CLICKS = 5000;
const QUERY_PARAM = 'enableDebugMode';
const LOCALSTORAGE_KEY = 'emp_app_debug_mode';

const useDebugControls = () => {
  const setDebugModeActive = useSetAtom(debugModeAtom);

  const enableDebugMode = () => {
    localStorage.setItem(LOCALSTORAGE_KEY, 'true');
    setDebugModeActive(() => true);
  };

  const disableDebugMode = () => {
    localStorage.removeItem(LOCALSTORAGE_KEY);
    setDebugModeActive(() => false);
  };

  return {
    enableDebugMode,
    disableDebugMode,
  };
};

/**
 * Activates persistent debug mode for the app if a query param `enableDebugMode` is present
 *
 * @returns {handleClick} - function to handle clicks to activate debug mode on 7 consecutive clicks in a 5 second window, as an alternative to the query param
 */
export const useDebugModeActivation = () => {
  const searchParams = useSearchParams();
  const debugMode = searchParams?.has(QUERY_PARAM);

  const { enableDebugMode: activateDebugMode } = useDebugControls();

  const firstClick = useRef(-Infinity);
  const consecutiveClicks = useRef(0);

  const handleClick = () => {
    const now = Date.now();

    if (now - firstClick.current > TIME_WINDOW_FOR_CONSECUTIVE_CLICKS) {
      firstClick.current = now;
      consecutiveClicks.current = 1;
    } else {
      consecutiveClicks.current += 1;
    }

    if (consecutiveClicks.current >= CLICKS_TO_ACTIVATE_DEBUG_MODE) {
      consecutiveClicks.current = 0;
      firstClick.current = -Infinity;

      activateDebugMode();
    }
  };

  useEffect(() => {
    if (debugMode || localStorage.getItem(LOCALSTORAGE_KEY) === 'true') {
      activateDebugMode();
    }
  }, [activateDebugMode, debugMode]);

  return {
    handleClick,
  };
};

export const useDebugMode = () => {
  const { enableDebugMode, disableDebugMode } = useDebugControls();

  const debugModeActive = useAtomValue(debugModeAtom);

  return {
    debugModeActive,
    enableDebugMode,
    disableDebugMode,
  };
};
