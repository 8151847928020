import { defineMessage, useIntl } from 'react-intl';
import { Warenhaus32 } from '@packages/themes/icons';
import { Slot } from './Slot';

const title = defineMessage({
  id: 'slot.warehouse.label',
  defaultMessage: 'Warenhaus Zürich',
});

/** Warehouse slot. Jelmoli tenant specific */
export const WarehouseSlot = () => {
  const { formatMessage } = useIntl();

  return (
    <Slot
      icon={<Warenhaus32 />}
      title={formatMessage(title)}
      href="/warehouse"
      useRouterLink={false}
    />
  );
};
