import { GlycerinPageType } from '../types/enums';

/**
 * Maps the page type and template to the GlycerinPageType enum.
 */
export const mapPageTypeAndTemplate = (type: string, template?: string): GlycerinPageType[] => {
  const glycerinPageTypes: GlycerinPageType[] = [];
  switch (type) {
    case 'homepage':
      glycerinPageTypes.push(GlycerinPageType.HOMEPAGE);
      break;
    case 'detailview':
      glycerinPageTypes.push(GlycerinPageType.PRODUCTDETAIL);
      break;
    case 'productlist':
      glycerinPageTypes.push(GlycerinPageType.PRODUCTLISTING);
      break;
    case 'serp':
    case 'searchbuddy':
      glycerinPageTypes.push(GlycerinPageType.SEARCHRESULT);
      break;
    case 'sis':
      glycerinPageTypes.push(GlycerinPageType.CATEGORYOVERVIEW);
      break;
    case 'basket':
      glycerinPageTypes.push(GlycerinPageType.CART);
      break;
    case 'service_hilfe':
      glycerinPageTypes.push(GlycerinPageType.INFO);
      break;
    case 'wishlist':
      glycerinPageTypes.push(GlycerinPageType.WISHLIST);
      break;
    case 'magazin':
      glycerinPageTypes.push(GlycerinPageType.BLOG);
      break;
    case 'myaccount':
      glycerinPageTypes.push(GlycerinPageType.CUSTOMERACCOUNT);
      break;
    case 'loginPage':
      glycerinPageTypes.push(GlycerinPageType.LOGIN);
      break;
    case 'deliveryPage':
      glycerinPageTypes.push(GlycerinPageType.SHIPPING);
      break;
    case 'paymentPage':
      glycerinPageTypes.push(GlycerinPageType.PAYMENT);
      break;
    case 'checkAndOrder':
      glycerinPageTypes.push(GlycerinPageType.CHECKANDORDER);
      break;
    case 'orderConfirmation':
      glycerinPageTypes.push(GlycerinPageType.ORDERCONFIRMATION);
      break;
    case 'shipmentInfos':
      glycerinPageTypes.push(GlycerinPageType.ORDERINFO);
      break;
    case 'callcenter':
      glycerinPageTypes.push(GlycerinPageType.LOGIN);
      break;
    default:
      glycerinPageTypes.push(GlycerinPageType.OTHER);
  }
  if (template && template !== '') {
    switch (template) {
      case 'loginPage':
        glycerinPageTypes.push(GlycerinPageType.LOGIN);
        break;
      case 'deliveryPage':
        glycerinPageTypes.push(GlycerinPageType.SHIPPING);
        break;
      case 'paymentPage':
        glycerinPageTypes.push(GlycerinPageType.PAYMENT);
        break;
      case 'checkAndOrder':
        glycerinPageTypes.push(GlycerinPageType.CHECKANDORDER);
        break;
      case 'orderConfirmation':
        glycerinPageTypes.push(GlycerinPageType.ORDERCONFIRMATION);
        break;
      case 'shipmentInfos':
        glycerinPageTypes.push(GlycerinPageType.ORDERINFO);
        break;
      case 'callcenter':
        glycerinPageTypes.push(GlycerinPageType.LOGIN);
        break;
      default:
      // do nothing
    }
  }
  return glycerinPageTypes;
};
