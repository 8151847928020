import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/**
 * This hook is used to show a loading indicator when the user navigates to a new page.
 *
 * @param filter A function to determine which URLs should trigger the loading indicator. If it returns `false`, the loading indicator will not be shown. If not provided, the loading indicator will be shown for all URLs.
 */
export const useRouterTransition = (filter?: (targetUrl: string) => boolean) => {
  const { events } = useRouter();
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const handleStartLoading = (targetUrl: string) => {
      if (filter && !filter(targetUrl)) return;

      setIsTransitioning(true);
    };

    const handleStopLoading = () => {
      setIsTransitioning(false);
    };

    events.on('routeChangeStart', handleStartLoading);
    events.on('routeChangeComplete', handleStopLoading);
    events.on('routeChangeError', handleStopLoading);
    return () => {
      events.off('routeChangeStart', handleStartLoading);
      events.off('routeChangeComplete', handleStopLoading);
      events.off('routeChangeError', handleStopLoading);
    };
  }, [events, filter]);

  return {
    isTransitioning,
  };
};
