import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgSearch32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M13 21a8 8 0 1 0 0-16 8 8 0 0 0 0 16m0-17a9 9 0 0 1 7.165 14.447l8.09 7.84a.5.5 0 0 1-.696.719l-8.045-7.796A9 9 0 1 1 13 4" className="search-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgSearch32);
export default Memo;