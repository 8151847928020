import { LinearSuggestionsFallback } from '../LinearSuggestions/LinearSuggestions.Fallback';

export const InspiringSearchSuggestionsLoading = () => (
  <LinearSuggestionsFallback
    sx={{
      backgroundImage: 'url("/search-assets/inspiring-search-background.svg")',
      backgroundSize: '100% 100%',
    }}
  />
);
