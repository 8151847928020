import { filterTimeControl } from '@packages/shared/src/utils/filterTimeControl/filterTimeControl';
import type { Config } from '@packages/config/src/default/types';
import type { ParagraphType, Audience } from '../../interfaces';
import type { TaxonomyTerm } from '../../interfaces/taxonomy';

const createFilterForCurrentAudiences =
  (audiences: Audience[keyof Audience][]) =>
  (item: ParagraphType): boolean => {
    if ('taxonomy_term' in item && Array.isArray(item.taxonomy_term)) {
      const taxonomyTerms = item.taxonomy_term;
      // if we find a taxonomy_term with the name that is in audiences, we return true
      return taxonomyTerms.some(
        (t: TaxonomyTerm) => t.field_name === 'field_audience' && audiences.includes(t.name),
      );
    }
    // if there is no taxonomy_term at all, we always want to display it and return true
    return true;
  };

const createFilterForTimeControl =
  (
    testDate: string | string[] | undefined | null = undefined,
    officeTimes: Config['officeTimes'] = {},
  ) =>
  (item: ParagraphType): boolean => {
    if (
      'field_valid_from' in item ||
      'field_valid_to' in item ||
      'field_visible_from' in item ||
      'field_visible_until' in item ||
      'behavior_paragraph_time_control_valid_from' in item ||
      'behavior_paragraph_time_control_valid_until' in item ||
      'behavior_paragraph_visibility_officehours' in item
    ) {
      return filterTimeControl(item, testDate, officeTimes);
    }
    // if there is no date fields at all, we always want to display it and return true
    return true;
  };

export { createFilterForCurrentAudiences, createFilterForTimeControl };
