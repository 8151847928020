import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgSuccessCircle32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><circle cx={16} cy={16} r={12} fill="#7DAE2E" /><path fill="#fff" d="M14.536 16.243 18.778 12l2.121 2.122-6.363 6.363L11 16.95l2.121-2.121z" /></SvgIcon>;
};
const Memo = memo(SvgSuccessCircle32);
export default Memo;