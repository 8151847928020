import React, { useState } from 'react';
import Head from 'next/head';
import { Box, Typography, Accordion, AccordionDetails, AccordionSummary } from '@packages/shared';
import { Cancel32 } from '@packages/themes/icons';
import { useFilterTimeControl } from '@packages/shared/src/utils/filterTimeControl/filterTimeControl';
import { ComponentRecognizer } from '../ComponentRecognizer';
import { Html } from '../Html';
import type {
  AccordionCollapseType,
  AccordionStyleVariant,
  CmsAccordionProps,
  CmsGeneralAccordionProps,
} from './types';
import type { HtmlEditorType } from '../Html';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';

export enum CmsAccordionTestIds {
  main = 'accordion-container',
  element = 'accordion',
  summary = 'accordion-summary',
  details = 'accordion-details',
  noRenderComponentFound = 'accordion-no-component-found',
}

const AccordionCollapse = ({
  id,
  isOpen,
  headline,
  handleChange,
  children,
  highlightOpen,
  styleVariant,
}: {
  id: string;
  isOpen: boolean;
  headline?: string;
  handleChange: (isExpanded: boolean) => void;
  children: React.ReactNode;
  highlightOpen: boolean;
} & AccordionStyleVariant) => (
  <Accordion
    data-testid={CmsAccordionTestIds.element}
    key={id}
    expanded={isOpen}
    onChange={(_e, isExpanded) => handleChange(isExpanded)}
    slotProps={{ transition: { unmountOnExit: true } }} // https://mui.com/material-ui/migration/migrating-from-deprecated-apis/
    sx={{
      margin: 0,
      padding: 0,
      color: 'text.dark',
      backgroundColor: styleVariant === 'dark' ? 'primary.main' : 'background.default',
      borderTop: `0.0625rem solid`,
      '&:last-of-type': highlightOpen
        ? {
            borderBottomWidth: `0.0625rem`,
            borderBottomStyle: `solid`,
          }
        : { borderBottom: 0 },
      borderColor: 'grey.main',

      // hide default horizontal borders
      '&:before': {
        display: 'none',
      },

      // hightlighted active item
      ...(highlightOpen && {
        transition: 'border 0.2s linear',
        borderLeft: '0.25rem solid transparent',
        '&.Mui-expanded': {
          backgroundColor: 'transparent',
          borderLeft: '0.25rem solid',
          borderLeftColor: 'primary.main',
          transition: 'border 0.2s linear',
        },
      }),
    }}
    disableGutters
    elevation={0}
    square
  >
    {headline && (
      <AccordionSummary
        data-testid={CmsAccordionTestIds.summary}
        sx={{
          // on mobile the icon is right, while on desktop the icon is left
          flexDirection: { md: 'row-reverse' },

          m: 0,
          p: 1.5,
          fontSize: 'body1.fontSize',

          // rotate the icon to form a "+"" when closed and an "x" when opened
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(0deg)',
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            transform: 'rotate(-45deg)',
            marginRight: 1.5,
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            backgroundColor: 'transparent',
            '& p': {
              fontWeight: 'bold',
            },
          },
          '& .MuiAccordionSummary-content': {
            margin: 0,
            padding: 0,
          },
        }}
        expandIcon={
          <Cancel32
            sx={{
              fontSize: highlightOpen ? '1.5rem' : '1rem',
              color: highlightOpen ? 'text.contrastText' : undefined,
              '& g': {
                fill: (theme) =>
                  styleVariant === 'dark'
                    ? theme.palette.primary.contrastText
                    : theme.palette.text.dark,
              },
            }}
          />
        }
        aria-controls={`${CmsAccordionTestIds.element}-${id}-content`}
      >
        <Typography>{headline}</Typography>
      </AccordionSummary>
    )}
    <AccordionDetails
      data-testid={CmsAccordionTestIds.details}
      sx={{
        p: {
          xs: '0.75rem',
          md: '1rem 1rem 1rem 3.15rem',
        },
        color: styleVariant === 'dark' ? 'primary.contrastText' : 'text.dark',
        a: {
          color: styleVariant === 'dark' ? 'primary.contrastText' : 'text.dark',
          textDecoration: 'none',
          '&:hover': {
            color: styleVariant === 'dark' ? 'primary.contrastText' : 'text.dark',
            textDecoration: 'underline',
          },
        },
      }}
    >
      {children}
    </AccordionDetails>
  </Accordion>
);

export const CmsGeneralAccordion = ({
  entries,
  highlightOpen = true,
  styleVariant = 'default',
}: CmsGeneralAccordionProps) => {
  const [stateExpanded, setStateExpanded] = useState<string | false>('');
  if (entries?.length && entries.length > 0) {
    return (
      <>
        {entries.map(({ headline, id, isOpen, content }) => (
          <AccordionCollapse
            key={id}
            id={id}
            isOpen={stateExpanded === id || (isOpen && stateExpanded === '') || !headline}
            headline={headline}
            handleChange={(isExpanded: boolean) => {
              setStateExpanded(isExpanded ? id : false);
            }}
            highlightOpen={highlightOpen}
            styleVariant={styleVariant}
          >
            {content}
          </AccordionCollapse>
        ))}
      </>
    );
  }

  return <div data-testid={CmsAccordionTestIds.noRenderComponentFound} />;
};

export const MarkUpData = ({ entries }: CmsGeneralAccordionProps) => {
  if (entries?.length && entries.length > 0) {
    const structuredData = entries.map(({ headline, sanitizedContent }) => ({
      '@type': 'Question',
      name: headline,
      acceptedAnswer: {
        '@type': 'Answer',
        text: sanitizedContent,
      },
    }));

    return (
      <Head>
        <script key="cmsAccordionFaqMarkup" type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: structuredData,
          })}
        </script>
      </Head>
    );
  }

  return null;
};

export const CmsAccordion = ({ data }: CmsAccordionProps) => {
  const filterTimeControl = useFilterTimeControl();
  const accordions = data.paragraph
    ?.filter((item) => {
      if ('field_valid_from' in item || 'field_valid_to' in item) {
        return filterTimeControl(item);
      }
      return item;
    })
    ?.map((item: AccordionCollapseType) => ({
      id: `cms-accordion-${item.id}`,
      headline: item.field_headline,
      isOpen: item.field_is_open,
      simpleContent: item.field_html_editor,
      sanitizedContent: item.field_html_editor_sanitized,
      content: [
        item.field_html_editor && <Html key={item.id} data={item as unknown as HtmlEditorType} />,
        item.paragraph &&
          item.paragraph?.map((subItem) => <ComponentRecognizer item={subItem} key={subItem.id} />),
      ],
    }));

  return (
    <>
      {data?.field_use_as_seo_data && <MarkUpData entries={accordions} />}
      <Box
        sx={{
          width: '100%',
          margin: data?.field_margin || 0,
          padding: 0,
        }}
        data-testid={CmsAccordionTestIds.main}
      >
        <CmsGeneralAccordion entries={accordions} />
      </Box>
    </>
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.Accordion]: CmsAccordion });
registerComponents({ [ComponentTypes.AccordionLocalizable]: CmsAccordion });
