import { FlatIconButton } from '@packages/shared';
import { BurgerText32 } from '@packages/themes/icons';

export type HamburgerButtonProps = {
  onClick: () => void;
};

export const HamburgerButton = ({ onClick }: HamburgerButtonProps) => (
  <FlatIconButton
    sx={{
      margin: 0,
      paddingX: 1,
      display: {
        lg: 'none',
      },
    }}
    aria-label="Open navigation"
    onClick={onClick}
  >
    <BurgerText32 />
  </FlatIconButton>
);
