import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgArrowLargeDown32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="m15.995 20.583 9.147-9.147a.5.5 0 1 1 .707.708l-9.854 9.853-9.849-9.854a.5.5 0 0 1 .708-.706z" className="arrow-large-down-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgArrowLargeDown32);
export default Memo;