/* eslint-disable @typescript-eslint/no-unused-vars */
import dynamic from 'next/dynamic';
import type { FC, ReactNode } from 'react';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';

import type { GeoCountryCode } from '@packages/config/src/default';
import { ErrorBoundary } from '@packages/shared';
import { useDebugModeActivation } from '@packages/shared/src/hooks/useDebugMode/useDebugMode';
import { useRouterTransition } from '@packages/shared/src/hooks/useRouterTransition/useRouterTransition';
import { useCookies } from '@packages/shared/src/providers/CookieProvider/CookieProvider';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';

import { Header } from '@packages/modules/src/Header/Header';
import { PromotionBanner, Footer, Usp } from '@packages/cms-components';
import { PromotionBannerError } from '@packages/cms-components/src/modules/PromotionBanner/PromotionBannerError';
import { useSearchParams } from 'next/navigation';

const DynamicTestPanel = dynamic(() => import('@packages/modules/src/TestPanel/TestPanel'), {
  ssr: false,
});

const DynamicGeoCountryLayer = dynamic(
  () =>
    import(
      /* webpackChunkName: 'CMS_GeoCountryLayer' */ '@packages/cms-components/src/components/GeoCountryLayer/GeoCountryLayer'
    ),
  {
    ssr: false,
  },
);

export interface PageLayoutProps {
  geoCountryCode?: GeoCountryCode;
  /** React children nodes */
  children?: ReactNode;
}

const PageTransitionLoadingBar: FC = () => {
  const { isTransitioning } = useRouterTransition();

  if (!isTransitioning) return null;

  return (
    <LinearProgress
      sx={{
        position: 'fixed',
        top: '0',
        width: '100vw',
        height: '3px',
        zIndex: 'tooltip',
      }}
      color="primary"
    />
  );
};

const TestPanelWrapper: FC = () => {
  const searchParams = useSearchParams();

  return (
    typeof window !== 'undefined' &&
    window.location.href.indexOf('empirie.dev') > 0 &&
    !searchParams?.get('skipTP') && <DynamicTestPanel />
  );
};

/**
 * Global PageLayout component which includes Header, PromotionBanner, and Footer
 * */
export const PageLayout = ({ geoCountryCode, children }: PageLayoutProps) => {
  const {
    usp: { isEnabled: isUspEnabled },
  } = useConfig();
  const { getCookies } = useCookies();
  const cookies = getCookies();
  const isApp = cookies.appView === 'true';

  useDebugModeActivation();

  return (
    <>
      <TestPanelWrapper />
      <PageTransitionLoadingBar />
      {!isApp && <Header />}
      {!isApp && isUspEnabled && <Usp />}
      {!isApp && (
        <ErrorBoundary fallback={<PromotionBannerError />}>
          <PromotionBanner />
        </ErrorBoundary>
      )}
      <Container component="main" sx={{ py: 3 }}>
        {children}
      </Container>
      {!isApp && geoCountryCode && <DynamicGeoCountryLayer geoCountryCode={geoCountryCode} />}
      {!isApp && <Footer />}
    </>
  );
};
