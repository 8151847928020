'use client';

import type { ReactNode, FC } from 'react';
import { useMemo, createContext } from 'react';
import { setCookie } from 'nookies';
import useSWR from 'swr';

import { useCookieConsent } from '../../hooks/useCookieConsent/useCookieConsent';
import { LoginStateProvider } from '../LoginStateProvider/LoginStateProvider';
import { useCookies } from '../CookieProvider/CookieProvider';
import { getEcState } from '../../utils/ecState/ecState';

export const kkBRECookieName = 'kkBRE';

export interface SessionContextProps {
  jwtToken?: string;
  loading: boolean;
}

export const SessionContext = createContext<SessionContextProps>({
  jwtToken: '',
  loading: true,
});

const COOKIE_OPTIONS = {
  path: '/',
  maxAge: 86400, // 90 days in seconds
  secure: false, // true would be nice but this is what intershop defines,
  sameSite: 'lax' as 'lax',
  httpOnly: false,
};

const REVALIDATE_TIME = 60 * 30; // 30 minutes

const getExpireTimeStamp = (expire: number) => (expire - REVALIDATE_TIME) * 1000;

const isExpired = (expire: number) => getExpireTimeStamp(expire) < Date.now();

const getTimeUntilExpired = (expire: number) => getExpireTimeStamp(expire) - Date.now();

type TokenContents =
  | {
      ts: string | undefined;
      type: number;
      role: number;
      ct?: number;
      fi: string;
      cc?: number;
      exp: number;
    }
  | undefined;

export const SessionProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { getCookies } = useCookies();
  const initialState = getEcState(getCookies());

  const isCookieAllowed = useCookieConsent('C0004');

  const { data, isLoading, mutate } = useSWR(
    'session',
    async () => {
      const { apiKeyManager } = await import('@empiriecom/mybuy-session');
      const getLoginStatus = async () => {
        let apiKey = await apiKeyManager.getApiKey();
        let contents = apiKeyManager.getTokenContents(apiKey) as TokenContents;
        const expire = contents?.exp;

        if (expire && isExpired(expire)) {
          await apiKeyManager.invalidateSessionKey();
          apiKey = await apiKeyManager.getApiKey();
          contents = apiKeyManager.getTokenContents(apiKey) as TokenContents;
        }

        return {
          apiKey,
          contents,
        };
      };
      apiKeyManager.addSessionChangedListener(getLoginStatus);
      return getLoginStatus();
    },
    { revalidateOnFocus: true, revalidateOnReconnect: true },
  );

  const { apiKey, contents } = { ...data };

  if (contents) {
    setTimeout(mutate, getTimeUntilExpired(contents.exp));

    if (contents.cc && isCookieAllowed) {
      setCookie(null, kkBRECookieName, contents.cc.toString(), COOKIE_OPTIONS);
    }
  }

  const value = useMemo(
    () => ({
      jwtToken: apiKey,
      loading: isLoading,
    }),
    [apiKey, isLoading],
  );

  return (
    <SessionContext.Provider value={value}>
      <LoginStateProvider
        loggedIn={initialState.loggedIn || !!(contents && contents.type && contents.type !== 0)}
        softLoggedIn={Boolean(contents && contents.type === 1)}
      >
        {children}
      </LoginStateProvider>
    </SessionContext.Provider>
  );
};
