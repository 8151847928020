import type { GenericTrackingEvent } from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';

import type { NavigationItem } from './navigationTypes';

/**
 * Provides a function to track navigation events.
 *
 * Returned event handlers should be called every time the respective event occurs.
 */
export const useNavigationTracking = () => {
  const dispatchGTMEvent = useTracking();

  const trackNavigationTo = (item: NavigationItem) => {
    const pathSegments = item?.uri?.split('/').filter((segment) => segment);

    dispatchGTMEvent<GenericTrackingEvent>({
      event: 'Layer_Navi',
      eventValue: pathSegments,
    });

    dispatchGTMEvent<GenericTrackingEvent>({
      event: 'suche-event',
      eventValue: `navi=${encodeURIComponent(item.displayName)}`,
    });
  };

  return { trackNavigationTo };
};
