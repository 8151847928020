import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgLanguageSwitch32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 33 32" sx={{
    ...sx
  }} {...other}><defs><path id="language-switch-32_svg__a" d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11m0 1C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12M1.5 7h21v1h-21ZM12 0c3.314 0 6 5.373 6 12 0 1.403-.12 2.75-.342 4.001L22.5 16v1l-5.044.001C16.507 21.131 14.42 24 12 24c-2.421 0-4.507-2.868-5.456-6.999L1.5 17v-1l4.842.001A23.068 23.068 0 0 1 6 12C6 5.373 8.686 0 12 0m0 1C9.239 1 7 5.925 7 12s2.239 11 5 11 5-4.925 5-11-2.239-11-5-11M1.5 16h21v1h-21Z" /></defs><g fill="none" fillRule="evenodd" transform="translate(4.5 4)"><mask id="language-switch-32_svg__b" fill="#fff"><use xlinkHref="#language-switch-32_svg__a" /></mask><use xlinkHref="#language-switch-32_svg__a" fill="#000" fillRule="nonzero" /><g fill="#262729" mask="url(#language-switch-32_svg__b)"><path d="M-4-4h32v32H-4z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgLanguageSwitch32);
export default Memo;