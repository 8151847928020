import type { AccordionSummaryProps as MuiAccordionSummaryProps } from '@mui/material';
import { AccordionSummary as MuiAccordionSummary } from '@mui/material';
import { ArrowLargeDown32 } from '@packages/themes/icons';

export type AccordionSummaryProps = MuiAccordionSummaryProps;

/** Shows summary in the accordion component. Based on MUI Accordion-Summary: https://mui.com/material-ui/api/accordion-summary */
export const AccordionSummary = (props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowLargeDown32 />} {...props} />
);
