import { parseTestDate } from '../timeUtils/timeUtils';

/**
 * Checks if a date falls within a specified validity range.
 *
 * @param validFrom - The start date for the validity range. If provided, the date to compare should be equal to or later than this date. If not provided or null, there is no lower limit.
 * @param validTo - The end date for the validity range. If provided, the date to compare should be equal to or earlier than this date. If not provided or null, there is no upper limit.
 * @param testDate - An optional date to check for validity. If not specified, the current date and time are used.
 *
 * @returns {boolean} - Returns true if the specified date (or the current date if not specified) falls within the validity range. Returns true if both `validFrom` and `validTo` are null or unspecified.
 */
export const dateCompare = ({
  validFrom,
  validTo,
  testDate,
}: {
  validFrom?: string | null;
  validTo?: string | null;
  testDate?: string | null;
}): boolean => {
  // Fail early: If both validFrom and validTo are null or undefined, return true immediately
  if (!validFrom && !validTo) {
    return true;
  }

  const currentDate: Date = parseTestDate({ testDate }) || new Date();
  const compareVal = currentDate.getTime();

  if (validFrom && validTo) {
    const validFromVal = new Date(validFrom).getTime();
    const validToVal = new Date(validTo).getTime();
    return validFromVal <= compareVal && compareVal <= validToVal;
  }

  if (validFrom) {
    return new Date(validFrom).getTime() <= compareVal;
  }

  if (validTo) {
    return compareVal <= new Date(validTo).getTime();
  }

  return false; // This line should not be reached
};
