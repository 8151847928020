import { TextField as MuiTextField, FormHelperText as MuiFormHelperText } from '@mui/material';
import type {
  OutlinedTextFieldProps as MuiOutlinedTextFieldProps,
  FormHelperTextProps as MuiFormHelperTextProps,
} from '@mui/material';
import { Disadvantage32 } from '@packages/themes/icons';
import { forwardRef } from 'react';

export type TextFieldProps = Omit<MuiOutlinedTextFieldProps, 'variant' | 'ref'>;

export const CustomHelperText = ({ children, className, ...props }: MuiFormHelperTextProps) => (
  <MuiFormHelperText sx={{ display: 'flex', alignItems: 'center', gap: 0.1 }} {...props}>
    {/* temporary solution because props are not correctly propagated from mui text field */}
    {((className && className.indexOf('error') > -1) || props.error) && (
      <Disadvantage32 sx={{ width: '1rem', height: '1rem' }} />
    )}
    {children}
  </MuiFormHelperText>
);

/** TextField component with label and helper text. Based on MUI TextFiel: https://mui.com/material-ui/react-text-field/ */
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(({ ...props }, ref) => (
  // Set label shrink to true, to show permanently placeholder. Otherwise placeholder will be displayed only on focus.
  <MuiTextField
    {...props}
    InputLabelProps={{ shrink: true }}
    variant="outlined"
    FormHelperTextProps={
      { component: CustomHelperText, error: props.error } as Partial<MuiFormHelperTextProps>
    }
    inputRef={ref}
  />
));
