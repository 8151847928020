import { startTransition, useState, type ReactNode } from 'react';

import { Box, ErrorBoundary, Stack } from '@packages/shared';

import { usePathname } from 'next/navigation';
import { ResponsiveHeaderErrorFallback } from './ResponsiveHeaderErrorFallback';

export type SearchFieldSlotProps = {
  isMobileSearchAlwaysVisible: boolean;
};

export type HeaderIconsSlotProps = {
  toggleMobileSearchField: () => void;
  isMobileSearchAlwaysVisible: boolean;
  isMobileSearchVisible: boolean;
};

export type ResponsiveHeaderLayoutProps = {
  top?: ReactNode;
  logo: ReactNode;
  icons: (props: HeaderIconsSlotProps) => ReactNode;
  searchField: (props: SearchFieldSlotProps) => ReactNode;
  /** Navigation is wrapped in a `position:relative` Box, to allow full-bleed break-out of the header */
  navigation: ReactNode;
  bottom?: ReactNode;
};

export const ResponsiveHeaderLayout = ({
  top,
  logo,
  searchField,
  icons,
  navigation,
  bottom,
}: ResponsiveHeaderLayoutProps) => {
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false);
  const pathname = usePathname();

  const isMobileSearchAlwaysVisible = pathname?.startsWith('/s/') ?? false; // NOTE `?` and `?? false` is a workaround for pages/app router parallel operation, where usePathname can theoretically return null
  const toggleMobileSearchField = () =>
    startTransition(() => setIsMobileSearchVisible((prev) => !prev)); // useCallback?

  // TODO later on remove some wrapping Stacks/Boxes and just inline the slot contents with a given gridArea (like the navigation), but for backwards compatibility we wrap it for now
  // TODO later on remove top/bottom slots when AppBanner and Usp are split from the Header
  return (
    <Stack
      component="header"
      sx={(theme) => ({
        '--theme-width': `${theme.breakpoints.values.xl}px`,
        // Some backgrounds for the navigation extend outside of the default container width of the page
        // The best way would be to accurately calculate the bleed, like calc(-0.5 * (var(--page-width-without-scrollbar) - var(--theme-width)))
        // Because scrollbar width is hard to determine at the moment using CSS only, we instead use large bleed and just clip it on the body (using overflow-x: hidden)
        [theme.breakpoints.up('lg')]: { '--background-bleed': '-50vw' },
        marginX: 'auto',
        width: '100%',
        maxWidth: 'var(--theme-width)',
        display: 'grid',
        gridTemplate: `
        "top top top top" auto
        "button logo space icons" auto
        "nav nav nav nav" auto
        "bottom bottom bottom bottom" auto / auto auto 1fr auto
      `,
        alignItems: 'center',
        boxShadow: [1, 1, 1, 0],
        // zIndex: (theme) => theme.zIndex.appBar,
      })}
    >
      {/* NOTE just used as a workaround for the AppBanner currently being part of the header */}
      {top && (
        <Box sx={{ gridArea: 'top', display: { xs: 'block', md: 'none' } }}>
          <ErrorBoundary fallback={<ResponsiveHeaderErrorFallback />}>{top}</ErrorBoundary>
        </Box>
      )}
      <Box gridArea="logo" sx={{ marginX: { xs: 1, xl: 0 } }}>
        <ErrorBoundary fallback={<ResponsiveHeaderErrorFallback />}>{logo}</ErrorBoundary>
      </Box>
      <Box
        sx={[
          {
            // NOTE this is a workaround for the way the search slot currently works, as it brings its own popup (with its own searchbox included)
            // later on, this should probably be refactored so that the header is fully in control of the searchbox, including the desired backdrop (that is currently part of the Slot's popup)
            display: { xs: isMobileSearchAlwaysVisible ? 'block' : 'none', lg: 'block' },
            gridArea: {
              xs: 'nav',
              lg: 'space',
            },
            // relative positioning is needed for the overlay effect when expanding the search field on non-SERP pages
            position: 'relative',
          },
        ]}
      >
        <Stack
          sx={(theme) => ({
            [theme.breakpoints.down('lg')]: {
              display: isMobileSearchVisible || isMobileSearchAlwaysVisible ? 'flex' : 'none',
              position: isMobileSearchAlwaysVisible ? 'static' : 'absolute',
              zIndex: theme.zIndex.tooltip,
              backgroundColor: 'grey.light',
            },
            [theme.breakpoints.up('lg')]: {
              marginX: 6.5,
            },
          })}
        >
          <ErrorBoundary fallback={<ResponsiveHeaderErrorFallback />}>
            {searchField({ isMobileSearchAlwaysVisible })}
          </ErrorBoundary>
        </Stack>
      </Box>
      <Stack direction="row" gridArea="icons" overflow="hidden">
        <ErrorBoundary fallback={<ResponsiveHeaderErrorFallback />}>
          {icons({ isMobileSearchAlwaysVisible, isMobileSearchVisible, toggleMobileSearchField })}
        </ErrorBoundary>
      </Stack>
      <Box sx={{ gridArea: { xs: 'button', lg: 'nav' } }} position="relative">
        <ErrorBoundary fallback={<ResponsiveHeaderErrorFallback />}>{navigation}</ErrorBoundary>
      </Box>
      {/* NOTE just used as a workaround for the USP currently being part of the header */}
      {bottom && (
        <Box sx={{ gridArea: 'bottom' }}>
          <ErrorBoundary fallback={<ResponsiveHeaderErrorFallback />}>{bottom}</ErrorBoundary>
        </Box>
      )}
    </Stack>
  );
};
