import type { LinkData } from '@packages/cms-components/interfaces';
import { encodeToBase64 } from './encodeToBase64';

const addProtocol = (url: string): string => {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }
  return `https://${url}`;
};

const isValidUrl = (url: string, baseUrl: string, useRelativePath: boolean): boolean => {
  try {
    const urlToTest = useRelativePath ? url : addProtocol(url);
    const test = new URL(urlToTest, useRelativePath ? baseUrl : undefined);
    return !!test;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    return false;
  }
};

/**
 * Builds a link with data from drupal for the current URL
 *
 * @param currentURL The current URL including any query parameters
 * @param linkArguments LinkData from Drupal (like tracking, bonusnumber, highlight)
 * @returns Complete URL string
 */
const buildLinkWithArguments = (
  currentURL: string | null | undefined,
  linkArguments: Partial<LinkData> = {},
): string => {
  const {
    field_link: link,
    field_link_anchor: linkAnchor,
    field_link_highlight: linkHighlight,
    field_link_searchfilter: linkSearchfilter,
    field_tracking: linkTracking,
    field_bonus_number: bonusNumber,
  } = linkArguments;

  // Determine the correct URL to use based on the provided parameters
  const useRelativePath =
    !currentURL || currentURL.startsWith('/') || (link && link.startsWith('/'));
  const urlToUse = currentURL || link || '/';
  // Define a base URL for relative URLs
  const BASE_URL = 'https://dummy.com';

  if (!isValidUrl(urlToUse, BASE_URL, !!useRelativePath)) {
    return '';
  }

  // At this point, urlToUse is either a valid URL or a relative path
  const url = new URL(
    useRelativePath ? urlToUse : addProtocol(urlToUse),
    useRelativePath ? BASE_URL : undefined,
  );
  const encodedBonusCode = encodeToBase64(bonusNumber);
  const searchParams = new URLSearchParams(url.search);

  const urlPercentEncodedString = new URLSearchParams({
    ...(linkHighlight && { highlight: linkHighlight }),
    ...(linkSearchfilter && { f: linkSearchfilter }),
    ...(encodedBonusCode && { P1: encodedBonusCode }),
    ...(linkTracking && { lmPromo: linkTracking }),
    // Transform URLSearchParam instance into a plain JavaScript object
    ...Object.fromEntries(searchParams.entries()),
  }).toString();

  url.search = decodeURIComponent(urlPercentEncodedString);
  url.hash = linkAnchor || url.hash || '';

  // Remove the base URL to return the original relative URL if applicable
  const finalUrl = url.href.replace(new RegExp(`^${BASE_URL}`), '');

  return finalUrl;
};

export { buildLinkWithArguments };
