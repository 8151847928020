import useSWR from 'swr';
import { useSearchParams } from 'next/navigation';
import { useI18n } from '@packages/shared/src/hooks/useI18n/useI18n';
import { useCookies } from '@packages/shared/src/providers/CookieProvider/CookieProvider';
import { getEcState } from '@packages/shared/src/utils/ecState/ecState';
import { getMainAudience } from '../../../utils/audiences';
import type { PromotionVariantsData } from '../types';
import { promotionBannerDataFetcher } from './fetch/promotionBannerDataFetcher';

export const usePromotionBannerData = (
  fallbackPromotionData: PromotionVariantsData | undefined = undefined,
) => {
  const query = Object.fromEntries(useSearchParams()?.entries() ?? []);
  const { language, defaultLanguage } = useI18n();
  const { getCookies } = useCookies();
  const finalLanguage = language || defaultLanguage || 'de';
  const audience = getMainAudience(getEcState(getCookies()));
  const promotionBannerCacheKey = `promotionBannerData-${finalLanguage}-${audience}`;
  const { data, isLoading } = useSWR<PromotionVariantsData>(
    promotionBannerCacheKey,
    () => promotionBannerDataFetcher(query, getCookies(), undefined, finalLanguage, true),
    {
      fallbackData: fallbackPromotionData,
      revalidateIfStale: false,
      // INSPIRE-3409 - do not revalidate on focus to apply correct data when getting back to the shop-browser-tab
      revalidateOnFocus: false,
    },
  );
  return {
    data,
    isLoading,
    endpoint: promotionBannerCacheKey,
    hasExpiredData: [data?.active, data?.selected].some((promoData) => promoData?.isExpired),
  };
};
