import type { ReactNode } from 'react';

import { Box, ClickAwayListener } from '@packages/shared';

import { ResponsiveBackground } from './ResponsiveBackground';

export type ResponsiveNavigationLayoutProps = {
  children: ReactNode;
  button: ReactNode;
  hamburgerShouldBeMounted: boolean;
  hamburgerClassNames: string;
  isLevel2Visible?: boolean;
  onClickAway?: () => void;
};

export const ResponsiveNavigationLayout = ({
  children,
  button,
  hamburgerClassNames,
  hamburgerShouldBeMounted,
  isLevel2Visible,
  onClickAway,
}: ResponsiveNavigationLayoutProps) => (
  <Box
    component="nav"
    sx={{
      gridArea: 'nav',
    }}
    position="relative"
    aria-expanded={isLevel2Visible || hamburgerShouldBeMounted}
  >
    <ResponsiveBackground
      sx={{
        borderTop: '1px solid',
        borderBottom: '1px solid',
        borderColor: 'grey.main',
      }}
    />

    {button}

    <ClickAwayListener onClickAway={() => onClickAway?.()}>
      <Box
        className={hamburgerClassNames}
        sx={[
          !hamburgerShouldBeMounted &&
            ((theme) => ({
              [theme.breakpoints.down('lg')]: {
                transform: 'translateX(-100%)',
                display: 'none',
              },
            })),
          (theme) => ({
            position: { lg: 'relative' },
            display: 'grid',
            gridTemplate: `"header" auto "toggle" auto "content" 1fr "anchor" 0 / 100%`,
            [theme.breakpoints.down('lg')]: {
              '&.transition-enter-active, &.transition-leave-active': {
                transition: 'transform 0.3s ease-in-out, opacity 0.2s ease-in-out',
              },
              '&.transition-enter-from, &.transition-leave-to': {
                transform: 'translateX(-100%)',
                opacity: 0,
              },
              boxShadow: 3,
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              backgroundColor: 'white',
              overflowY: 'auto',
              zIndex: theme.zIndex.drawer,
            },
          }),
          (theme) => ({
            '@media (prefers-reduced-motion: reduce)': {
              [theme.breakpoints.down('lg')]: {
                '&.transition-enter-active, &.transition-leave-active': {
                  transition: 'none',
                },
              },
            },
          }),
        ]}
      >
        {children}
      </Box>
    </ClickAwayListener>
  </Box>
);
