import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgArrowMiniRight32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="m20.132 16.002-5.05 4.921a.304.304 0 0 1-.425 0l-.578-.562a.29.29 0 0 1 .005-.419l4.043-3.94-4.043-3.941a.289.289 0 0 1-.005-.419l.578-.563a.303.303 0 0 1 .425 0z" className="arrow-mini-right-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgArrowMiniRight32);
export default Memo;