import { defineMessage, useIntl } from 'react-intl';
import { JoeLogo } from '@packages/themes/icons';
import { Slot } from './Slot';

const title = defineMessage({
  id: 'slot.joe.label',
  defaultMessage: 'Bonus Club',
});

/** Service slot with list of helpers in popover */
export const JoeSlot = () => {
  const { formatMessage } = useIntl();

  return (
    <Slot href="/service-hilfe/joe-bonus-club" title={formatMessage(title)} icon={<JoeLogo />} />
  );
};
