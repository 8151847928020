import type { ForwardedRef } from 'react';
import React from 'react';
import type { BoxProps as MuiBoxProps, BoxTypeMap } from '@mui/system';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import type { Theme } from '@mui/material';
import { Box as MuiBox } from '@mui/material';

export type BoxProps = MuiBoxProps;

/** The Mui Box component serves as a wrapper component for most of the CSS utility needs. https://mui.com/material-ui/react-box/
 * Cast is needed to solve the issue with the ref prop: https://github.com/mui/material-ui/issues/32420
 * */
export const Box = React.forwardRef((props: BoxProps, ref: ForwardedRef<unknown | undefined>) => (
  <MuiBox {...props} ref={ref} />
)) as OverridableComponent<BoxTypeMap<{}, React.ElementType, Theme>>;
