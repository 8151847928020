import { ComponentTypes } from '../../../interfaces/components';
import { CmsImage } from '../CmsImage';
import { BlockText } from '../Footer/BlockText';
import { BlockList } from '../Footer/BlockList';
import { BlockImage } from '../Footer/BlockImage';
import { BlockBadges } from '../Footer/BlockBadges';
import { registerComponents } from './componentRegistry';
import { ImageGroup } from '../Footer/ImageGroup';

/**
 * Registers a set of components using the previously defined registerComponent function.
 * don't forget to register the dynamic components if <ComponentRecognizer /> is in use. Example:
 * ```
 *    import { registerFlexibleComponents } from '@packages/cms-components/src/components/ComponentRecognizer/registerFlexibleComponents';
 *    registerFlexibleComponents();
 * ```
 */
export const registerFlexibleComponentsFooter = (): void => {
  registerComponents({
    [ComponentTypes.SvgFile]: CmsImage,
    [ComponentTypes.ImageSimple]: CmsImage,
    [ComponentTypes.ImageFooter]: CmsImage,
    [ComponentTypes.BlockText]: BlockText,
    [ComponentTypes.BlockList]: BlockList,
    [ComponentTypes.BlockImage]: BlockImage,
    [ComponentTypes.BlockBadges]: BlockBadges,
    [ComponentTypes.FooterGroupImage]: ImageGroup,
  });
};
