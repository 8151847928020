import type { ProductConfig } from '../../default/product/product.types';
import type { DeepPartial } from '../../default/types';

export const productConfig: DeepPartial<ProductConfig> = {
  cliplister: {
    customerId: '80936',
  },
  hasSwogo: false,
  hasCarbonHint: false,
};
