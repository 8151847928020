import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgPaybackColored32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" sx={{
    ...sx
  }} {...other}><g fill="none" fillRule="nonzero"><path fill="#003EB0" d="M27.304 23.44a3.862 3.862 0 0 1-3.852 3.85H4.562a3.853 3.853 0 0 1-3.85-3.85V4.56A3.862 3.862 0 0 1 4.561.71h18.875a3.853 3.853 0 0 1 3.852 3.85v18.88z" /><path fill="#FFF" d="M4.563 1.407c-1.733 0-3.156 1.406-3.156 3.139v18.879a3.145 3.145 0 0 0 3.156 3.139h18.874c1.733 0 3.155-1.407 3.155-3.14V4.562a3.145 3.145 0 0 0-3.155-3.14H4.563zM23.452 28H4.562A4.564 4.564 0 0 1 0 23.44V4.56A4.564 4.564 0 0 1 4.563 0h18.874A4.564 4.564 0 0 1 28 4.56v18.88c.015 2.502-2.045 4.56-4.548 4.56" /><path fill="#FFF" d="M9.615 13.445A3.837 3.837 0 0 1 5.778 9.61a3.837 3.837 0 0 1 7.674 0 3.837 3.837 0 0 1-3.837 3.835m0-6.9a3.088 3.088 0 0 0-3.082 3.08 3.088 3.088 0 0 0 3.082 3.08 3.078 3.078 0 0 0 3.081-3.08 3.078 3.078 0 0 0-3.081-3.08M9.615 22.21a3.837 3.837 0 0 1-3.837-3.835 3.837 3.837 0 0 1 7.674 0 3.837 3.837 0 0 1-3.837 3.835m0-6.9a3.088 3.088 0 0 0-3.082 3.08 3.088 3.088 0 0 0 3.082 3.08 3.078 3.078 0 0 0 3.081-3.08 3.078 3.078 0 0 0-3.081-3.08M18.385 22.21a3.837 3.837 0 0 1-3.837-3.835 3.837 3.837 0 0 1 7.674 0 3.837 3.837 0 0 1-3.837 3.835m0-6.9a3.088 3.088 0 0 0-3.081 3.08 3.088 3.088 0 0 0 3.081 3.08 3.088 3.088 0 0 0 3.082-3.08 3.078 3.078 0 0 0-3.082-3.08M18.4 13.445a3.837 3.837 0 0 1-3.837-3.835 3.837 3.837 0 0 1 7.674 0c-.015 2.117-1.733 3.835-3.837 3.835" /></g></SvgIcon>;
};
const Memo = memo(SvgPaybackColored32);
export default Memo;