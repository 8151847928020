import { Suspense } from 'react';

import { Box, ErrorBoundary } from '@packages/shared';

import {
  DynamicInspiringSearchSuggestions,
  InspiringSearchSuggestionsLoading,
} from './InspiringSearchSuggestions';
import { DynamicSearchHistory, SearchHistoryLoading } from './SearchHistory';
import {
  DynamicSearchTermSuggestions,
  SearchTermSuggestionsLoading,
} from './SearchTermSuggestions';
import { useInspiringSearchSettings } from '../../useInspiringSearchSettings';
import type { SuggestionTrackingData } from './types';

export type SearchFieldPopupProps = {
  /** The current search term for suggestions */
  searchTerm?: string;
  /** 
   * Token for fetching data (will be removed when swr completely implements suspense support)
   @deprecated 
  */
  jwtToken: string;
  /** Callback that is called when the user clicks on any search suggestion */
  onAccept: (suggestionTrackingData: SuggestionTrackingData) => void;
  /** Callback that is called when the user clicks the arrow button to accept a search suggestion as the current input value without immediately triggering a navigation */
  onAdopt: (value: string) => void;
};

export const SearchFieldPopup = ({
  searchTerm,
  jwtToken,
  onAccept,
  onAdopt,
}: SearchFieldPopupProps) => {
  const { isInspiringSearchEnabled } = useInspiringSearchSettings();

  return (
    <Box
      sx={{
        py: 0,
        bgcolor: 'grey.light',
        boxShadow: 1,
        position: 'absolute',
        zIndex: (theme) => theme.zIndex.appBar + 2, //  higher than the flyout navigation (filter popover is appbar, appbar + 1 is flyout navigation)
        top: '100%',
        left: 0,
        width: searchTerm ? ['100%', '100%', '170%'] : '100%',
      }}
    >
      {!searchTerm && isInspiringSearchEnabled && (
        <ErrorBoundary>
          <Suspense fallback={<InspiringSearchSuggestionsLoading />}>
            <DynamicInspiringSearchSuggestions onAdopt={onAdopt} onAccept={onAccept} />
          </Suspense>
        </ErrorBoundary>
      )}
      {!searchTerm && (
        <ErrorBoundary>
          <Suspense fallback={<SearchHistoryLoading />}>
            <DynamicSearchHistory jwtToken={jwtToken} onAdopt={onAdopt} onAccept={onAccept} />
          </Suspense>
        </ErrorBoundary>
      )}
      {searchTerm && (
        <ErrorBoundary>
          <Suspense fallback={<SearchTermSuggestionsLoading />}>
            <DynamicSearchTermSuggestions
              jwtToken={jwtToken}
              onAccept={onAccept}
              searchTerm={searchTerm}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </Box>
  );
};
