import { ArrowLargeLeft32, ArrowLargeRight32 } from '@packages/themes/icons';
import type { DrupalIconTypes } from '../../../interfaces';

export const Icon = ({ type }: { type: DrupalIconTypes }) => {
  switch (type) {
    case 'ArrowLeft':
      return <ArrowLargeLeft32 />;
    default:
      return <ArrowLargeRight32 />;
  }
};
