import type { GeoCountryCode } from '@packages/config/src/default';
import type { NextPageContext } from 'next';

/**
 * Gets the GeoCountryCode from the NextPageContext and returns it, unless /?skipCountryLayer=true
 * is set
 */
export const getGeoCountryCode = (ctx: NextPageContext): GeoCountryCode =>
  !Array.isArray(ctx.req?.headers['x-geo-country-code']) && ctx.query?.skipCountryLayer !== 'true'
    ? ctx.req?.headers['x-geo-country-code']
    : undefined;
