import type { GetServerSidePropsContext } from 'next';
import type { ParsedUrlQuery } from 'querystring';
import type { DestroyCookie, SetCookie } from '../../providers/CookieProvider/CookieProvider';

import { buildTestCookies } from './buildTestCookies/buildTestCookies';
import { getParticipantCookieName, getTestCookieName } from './cookieNames/cookieNames';

type SetTestCookiesParameters = {
  context?: GetServerSidePropsContext<ParsedUrlQuery>;
  testId: string;
  setCookie: SetCookie;
  value: {
    participant: string;
    variant: Record<string, string>;
  };
};

export const setTestCookies = ({ context, testId, setCookie, value }: SetTestCookiesParameters) => {
  const testCookies = buildTestCookies({ testId, ruleset: value });

  testCookies.forEach((cookie) => {
    setCookie(context, cookie.name, cookie.value, {
      expires: cookie.expires,
      path: cookie.path,
    });
  });
};

type RemoveTestCookieParameters = {
  context?: GetServerSidePropsContext<ParsedUrlQuery>;
  testId: string;
  removeCookie: DestroyCookie;
};

export const removeTestCookies = ({
  context,
  testId,
  removeCookie,
}: RemoveTestCookieParameters) => {
  removeCookie(context, getParticipantCookieName(testId), {
    path: '/',
  });
  removeCookie(context, getTestCookieName(testId), {
    path: '/',
  });
};

export const getTestParticipation = (
  cookies: Record<string, string>,
  testId: string,
):
  | {
      participantId: string;
      variant?: Record<string, string>;
    }
  | undefined => {
  const fullCookieName = getTestCookieName(testId);
  const participantCookieName = getParticipantCookieName(testId);

  const fullValue = cookies[fullCookieName];

  if (fullValue) {
    const { participant: participantId, variant } = JSON.parse(fullValue) as {
      participant: string;
      variant: Record<string, string>;
    };

    return { participantId, variant };
  }

  const participantId = cookies[participantCookieName];

  if (participantId) {
    return { participantId, variant: undefined };
  }

  return undefined;
};

export const isAbTestParticipant = ({
  testCookie,
  customTestRecognizerFunction,
}: {
  testCookie: string;
  customTestRecognizerFunction?: () => boolean;
}) => {
  if (testCookie) {
    return !!testCookie;
  }
  if (customTestRecognizerFunction) {
    return customTestRecognizerFunction();
  }
  return false;
};
