import { Alert as MuiAlert } from '@mui/material';
import type { AlertProps as MuiAlertProps } from '@mui/material';
import {
  ErrorCircle32,
  InformationCircle32,
  SuccessCircle32,
  WarningCircle32,
} from '@packages/themes/icons';
import { forwardRef } from 'react';
import { CloseIconButton } from '../CloseIconButton';

export type AlertProps = MuiAlertProps;

export const Alert = forwardRef<HTMLDivElement, AlertProps>(
  ({ severity, onClose, ...props }, ref) => (
    <MuiAlert
      ref={ref}
      data-testid="notification-alert"
      role={severity === 'error' || severity === 'warning' ? 'alert' : 'status'}
      severity={severity}
      iconMapping={{
        error: <ErrorCircle32 color="error" />,
        warning: <WarningCircle32 color="warning" />,
        info: <InformationCircle32 color="info" />,
        success: <SuccessCircle32 color="success" />,
      }}
      action={onClose && <CloseIconButton onClick={onClose} />}
      {...props}
    />
  ),
);
