'use client';

import { useCallback, useEffect } from 'react';
import { type NotificationProps, Notification } from './Notification';

export type ToastNotificationProps = {
  toastId: number;
  message?: string;
  duration?: number;
  title?: string;
} & Pick<NotificationProps, 'severity' | 'onClose'>;

export const ToastNotification = ({
  toastId,
  title,
  message,
  duration = 5000,
  severity,
  onClose,
  removeNotification,
}: ToastNotificationProps & { removeNotification: (toastId: number) => void }) => {
  const closeNotification = useCallback(() => {
    removeNotification(toastId);
    onClose && onClose();
  }, [toastId, onClose, removeNotification]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (duration) {
      setTimeout(() => {
        closeNotification();
      }, duration);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [duration, closeNotification]);

  return (
    <Notification
      severity={severity}
      variant="default"
      onClose={onClose ? closeNotification : undefined}
    >
      {title && <p className="text-size-body1">{title}</p>}
      <p className="text-size-body2">{message}</p>
    </Notification>
  );
};
