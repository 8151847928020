import type { PaperProps } from '@mui/material';
import { Box, Paper } from '@mui/material';
import type { FC, ReactNode } from 'react';

export type DialogFooterProps = {
  /** Content of the footer, should usually be one or two buttons (one with color="primary" and the other with variant="text", in that order).
   *
   * No wrapper around the buttons is needed, gap is set automatically.
   */
  children?: ReactNode;
  /** Determines if the element should get position: sticky to be always visible at bottom of screen */
  sticky?: boolean;
} & PaperProps;

/**
 * A dialog footer implementing the visual layout of the empiriecom design system
 * */
export const DialogFooter: FC<DialogFooterProps> = ({
  children,
  sx,
  sticky = false,
  ...otherProps
}) => (
  <Paper
    elevation={4}
    square
    sx={{
      padding: 2,
      marginTop: 'auto',
      ...sx,
      ...(sticky && { position: 'sticky', bottom: '0', left: '0', width: '100%' }),
    }}
    {...otherProps}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row-reverse' },
        gap: 1,
        alignItems: { xs: 'stretch', md: 'center' },
      }}
    >
      {children}
    </Box>
  </Paper>
);
