'use client';

import type { ReactNode } from 'react';
import { useContext, useMemo, createContext } from 'react';
import { setCookie, parseCookies, destroyCookie } from 'nookies';

export type SetCookie = typeof setCookie;
export type DestroyCookie = typeof destroyCookie;

export interface CookieContextProps {
  getCookies: () => { [key: string]: string };
  // see https://www.npmjs.com/package/nookies
  setCookie: SetCookie;
  destroyCookie: DestroyCookie;
}

// nookies does not reexport CookieOptions type from cookies package
// so we use typescript conditional type to infer type of 4th function argument
export type CookieOptions = SetCookie extends (
  arg1: any,
  arg2: any,
  arg3: any,
  options: infer U,
) => any
  ? U
  : unknown;

export interface CookieProviderProps {
  cookies: { [key: string]: string };
  children: ReactNode;
}

export const CookieContext = createContext<CookieContextProps>({
  getCookies: () => ({}),
  setCookie: () => ({}),
  destroyCookie: () => ({}),
});

const isClient = typeof window !== 'undefined';

export const CookieProvider = ({ cookies, children }: CookieProviderProps): JSX.Element => {
  const value = useMemo(
    () => ({
      getCookies: isClient ? parseCookies : () => cookies,
      setCookie,
      destroyCookie,
    }),
    [cookies],
  );

  return <CookieContext.Provider value={value}>{children}</CookieContext.Provider>;
};

export const useCookies = () => useContext(CookieContext);

export const nookieSetCookie = setCookie;
export const nookieDestroyCookie = destroyCookie;
export const nookieParseCookies = parseCookies;
