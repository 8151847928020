'use client';

/* eslint-disable react/no-unused-prop-types */
import type { ReactNode, ErrorInfo } from 'react';
import { Component } from 'react';
import { logger } from '../../utils/logger/logger';

interface ErrorBoundaryProps {
  /** Subtree component */
  children: ReactNode;
  /**
   * Fallback when error occured in subtree
   @default null
   */
  fallback?: ReactNode | ((reset: () => void) => ReactNode);
  /**
   * Callback function when error thrown
   */
  errorCallback?: (error: Error, errorInfo: ErrorInfo) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    logger.error({ error, info }, `Error catched in ErrorBoundary`);
    const { errorCallback } = { ...this.props };
    if (errorCallback) {
      errorCallback(error, info);
    }
  }

  /* can be used in fallback component to reset error state and try to rerender the sub tree */
  reset = () => {
    this.setState({ hasError: false });
  };

  render() {
    const { hasError } = this.state;
    const { fallback, children } = { ...this.props };

    if (hasError) {
      return typeof fallback === 'function' ? fallback(this.reset) : fallback || null;
    }

    return children;
  }
}
