import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Box } from '@packages/shared';
import { keyframes } from '@mui/system';
import { NavListItem } from './NavItem';
import type { NavItem } from '../types';

const fadeIn = keyframes`
  0% { left: 100%; }
`;

export const SubNavigation: FC<{
  item: NavItem;
}> = ({ item, item: { children = [] } }) => {
  const [activeItem, setActiveItem] = useState<NavItem>();

  // reset subnavigation
  useEffect(() => setActiveItem(undefined), [item]);

  return (
    <div>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          width: '100%',
          left: 0,
          overflow: 'auto',
          transition: 'left 0.25s ease-in-out',

          'nav > div > div > &': {
            animation: 'none',
          },

          '&:not(:only-child)': {
            left: '-100%',
          },

          animation: `${fadeIn} 0.25s 0s ease-in-out`,
        }}
        data-testid="sub-nav-list"
      >
        {children.map((subItem) => (
          <NavListItem key={subItem.uri} item={subItem} setActiveItem={setActiveItem} />
        ))}
      </Box>
      {activeItem && children.includes(activeItem) && <SubNavigation item={activeItem} />}
    </div>
  );
};
