import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgDisadvantage32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M16 4c6.627 0 12 5.373 12 12s-5.373 12-12 12S4 22.627 4 16 9.373 4 16 4m5 11H11a1 1 0 0 0 0 2h10a1 1 0 0 0 0-2" className="disadvantage-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgDisadvantage32);
export default Memo;