import { Box } from '@packages/shared';
import type { ReactNode } from 'react';

interface ArrowBoxProps {
  /** Left position of arrow above the box */
  left: string | number;
  /** Content displayed in the box */
  children: ReactNode;
}

/** Component with arrow above the box wrapper. Left arrow position is a necessary property */
export const ArrowBox = ({ left, children }: ArrowBoxProps) => (
  <Box
    sx={{
      position: 'relative',
      bgcolor: 'grey.light',
      px: 1.5,
      py: 1,
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: '100%',
        left,
        height: 0,
        width: 0,
        border: 'solid transparent',
        borderBottomColor: 'grey.light',
        borderWidth: '10px',
        pointerEvents: 'none',
      },
    }}
  >
    {children}
  </Box>
);
