'use client';

import React from 'react';
import clsx from 'clsx';
import { format } from 'url';
import { usePathname, useRouter } from 'next/navigation';
import type { LinkProps as NextLinkProps } from 'next/link';
import NextLink from 'next/link';
import type { LinkProps as MuiLinkProps } from '@mui/material/Link';
import MuiLink from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { useIsBot } from '@packages/tracking/src/hooks/useIsBot/useIsBot';
import type { Language } from '@packages/config';
import { getCanonicalUrl } from '../../utils/getCanonicalUrl/getCanonicalUrl';
import { useConfig } from '../../hooks/useConfig/useConfig';
import { useCookies } from '../../providers/CookieProvider/CookieProvider';
import { localizeUrl } from '../../utils/localizeUrl/localizeUrl';
import { useI18n } from '../../hooks/useI18n/useI18n';
import { usePageTransition } from '../../providers/PageTransitionProvider/usePageTransition';

// Add support for the sx prop for consistency with the other branches.
const Anchor = styled('a')({
  color: 'inherit',
  textDecoration: 'none',
});

interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Omit<NextLinkProps, 'href' | 'as' | 'onMouseEnter' | 'onClick' | 'onTouchStart'> {
  to: NextLinkProps['href'];
  linkAs?: NextLinkProps['as'];
  href?: NextLinkProps['href'];
}

export const NextLinkComposed = React.forwardRef<
  HTMLAnchorElement,
  Omit<NextLinkComposedProps, 'href'>
>((props, ref) => {
  const { to, linkAs, replace, scroll, shallow, prefetch, locale, onClick, onKeyDown, ...other } =
    props;
  const pageTransition = usePageTransition();
  // when pages router is not needed any more, uncomment this part
  // if (!pageTransition) {
  //   throw new Error('usePageTransition() must be used within a PageTransitionProvider');
  // }
  const router = useRouter();

  return (
    <NextLink
      href={to}
      prefetch={false}
      as={linkAs}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      locale={locale}
      passHref
      legacyBehavior
    >
      <Anchor
        ref={ref}
        onClick={
          // prevent default only when no page transition provider detected (actually only in app folder)
          pageTransition
            ? (e) => {
                // give the consuming components a chance to prevent the default behavior and do something else, like in the navigation where clicks don't always directly change pages
                onClick?.(e);
                if (e.defaultPrevented) {
                  return;
                }

                e.preventDefault();
                pageTransition.setUrl(to.toString());
                pageTransition.start(() => {
                  router.push(to.toString());
                });
              }
            : onClick
        }
        onKeyDown={(event) => {
          if (event.key === 'Enter' && pageTransition) {
            onKeyDown?.(event);
            if (event.defaultPrevented) {
              return;
            }

            event.preventDefault();
            pageTransition.setUrl(to.toString());
            pageTransition.start(() => {
              router.push(to.toString());
            });
          } else {
            onKeyDown?.(event);
          }
        }}
        {...other}
      />
    </NextLink>
  );
});

export type LinkProps = {
  activeClassName?: string;
  linkAs?: NextLinkProps['as']; // Useful when the as pro p is shallow by styled().
  noLinkStyle?: boolean;
  openInNewTab?: boolean;
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
  Omit<MuiLinkProps, 'href'> &
  Pick<NextLinkProps, 'href' | 'as'>;

const regAbsolute = /^([a-z]+:\/\/|\/\/)/i;

/**
 * Helper hook to get pathname of href if internal link and hostname included. Otherwise get complete href
 * @param {NextLinkProps['href']} href provided href for image link
 */
const useClearedHref = (href: NextLinkProps['href']) => {
  const {
    host: { domain },
  } = useConfig();
  const tenantHost = `www.${domain}`;
  let linkHref = typeof href === 'string' ? href : (href.pathname ?? '/');
  let external = false;

  // if href string, check  if absolute url
  if (typeof href === 'string') {
    if (regAbsolute.test(href)) {
      // if protocol-relative-url, add protocol
      const absoluteHref = href.startsWith('//') ? `https:${href}` : href;
      const { hostname: hostNameLink } = new URL(absoluteHref);
      const hostNameClient = typeof window !== 'undefined' ? window?.location?.hostname : undefined;

      // if host equals testing systems or tenant domain, it is an internal link, but set linkhref to pathname
      if (
        [tenantHost, hostNameClient, 'localhost'].some(
          (testHost) => testHost && hostNameLink.indexOf(testHost) > -1,
        )
      ) {
        // TODO: check if it is a requirement that query params are not in linkHref
        linkHref = new URL(absoluteHref).pathname;
      } else {
        external = true;
      }
    }
    // if href urlObject, che ck if host exists
  } else if (href.host) {
    // if host does not equal to tenant domain, it is an external link
    if (href.host.indexOf(tenantHost) === -1) {
      linkHref = href.href ?? '/';
      external = true;
    }
  }

  return { linkHref, external };
};

/**
 * A styled version  of the Next.js Link component: https://nextjs.org/docs/api-reference/next/link
 *
 * Automatically adds the current language to all `href`s, unless the `locale` prop is set explicitly.
 * */
export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const {
    activeClassName = 'active',
    as: linkAs,
    className: classNameProps,
    href = '',
    noLinkStyle,
    openInNewTab,
    role, // Link don't have roles.
    locale,
    ...other
  } = props;

  // NOTE: this currently strips out query params and hash for object urls, but keeps them for string urls
  const { linkHref, external } = useClearedHref(href);
  let linkTarget = href;
  const isBot = useIsBot();
  if (isBot) {
    linkTarget = getCanonicalUrl(typeof href === 'string' ? href : format(href));
  }

  // if we are in an appview, we can not render the next link. we need to use an default anchor so the redirect is not done via javascript
  const { getCookies } = useCookies();
  const { appView } = getCookies();
  const isApp = appView === 'true';

  const pathname = usePathname();

  const className = clsx(classNameProps, {
    // startsWith instead of equality to avoid false negatives with query params, and work around trailing slash inconsistencies between server and client
    // NOTE: null check is for pages/app router compat mode, later for pure app router this can never be null
    [activeClassName]: pathname && linkHref.startsWith(pathname) && activeClassName,
  });

  const target = openInNewTab ? '_blank' : '_self';

  const config = useConfig();
  const { language } = useI18n();

  if (external) {
    if (noLinkStyle) {
      return <Anchor className={className} href={linkHref} ref={ref} target={target} {...other} />;
    }

    return <MuiLink className={className} href={linkHref} ref={ref} target={target} {...other} />;
  }

  if (isApp) {
    const stringifiedAppHref = typeof linkTarget === 'string' ? linkTarget : format(linkTarget);

    // NOTE: currently app links are never localized, this is probably a bug

    if (noLinkStyle) {
      return (
        <Anchor
          className={className}
          href={stringifiedAppHref}
          ref={ref}
          target={target}
          {...other}
        />
      );
    }

    return (
      <MuiLink
        className={className}
        href={stringifiedAppHref}
        ref={ref}
        target={target}
        {...other}
      />
    );
  }

  const stringifiedHref = typeof linkTarget === 'string' ? linkTarget : format(linkTarget);

  const targetLanguage = (locale ?? language) as Language;

  // always add the correct language prefix to the href, as the app router does not magically do this for us anymore like the pages router did
  // in cases where the link is intentionally for a different language, the `locale` prop has to be used for now
  const localizedHref =
    // ignore some cases of hrefs for backwards compatibility
    stringifiedHref.startsWith('/')
      ? localizeUrl(stringifiedHref, targetLanguage, config)
      : stringifiedHref;

  if (noLinkStyle) {
    return (
      <NextLinkComposed
        className={className}
        ref={ref}
        to={localizedHref}
        target={target}
        locale={targetLanguage}
        {...other}
      />
    );
  }

  return (
    <MuiLink
      data-testid="link"
      component={NextLinkComposed}
      linkAs={linkAs}
      className={className}
      ref={ref}
      to={localizedHref}
      target={target}
      prefetch={false}
      {...other}
    />
  );
});
