import DOMPurify from '@packages/config/utils/dompurify';
import { Box } from '@packages/shared';
import { footerListStyles } from '../styles';
import { registerComponents } from '../../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../../interfaces';

import type { FooterBlockTextType } from '../types';

type BlockTextProps = {
  data: FooterBlockTextType;
};

export enum BlockTextTestIds {
  main = 'block-text',
}

export const BlockText = ({ data }: BlockTextProps) => (
  <Box
    sx={{
      '& h1, & h2, & h3, & h4, & h5, & h6, & ul, & ol, & li, & p': {
        m: 0,
        p: 0,
      },
      '& p': {
        m: 0,
        p: 0,
        pb: 2,
        '&:last-of-type': {
          pt: 0,
          mt: -2,
        },
        '& img': {
          display: 'inline-flex',
          alignContent: 'center',
          flexShrink: 0,
          verticalAlign: 'baseline',
          m: 0,
          ml: 0.5,
          p: 0,
        },
      },
      '& h6, & p:last-of-type': {
        fontSize: 'body3.fontSize',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
      },
      ...footerListStyles,
    }}
    data-testid={BlockTextTestIds.main}
    key={`${BlockTextTestIds.main}-${data.id}`}
    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.field_html_editor) }}
  />
);

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.BlockText]: BlockText });
