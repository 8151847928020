import type { HtmlEditor } from '../../interfaces';

/**
 * Returns the value of the HtmlEditor object if it's an object, the string itself if it's a string, or null if it's neither.
 *
 * @param {HtmlEditor | undefined} htmlContent - The HtmlEditor object or string to process.
 * @returns {string | null} The value of the HtmlEditor object, the string itself, or null.
 */
export const getFinalDangerouslyHTML = (htmlContent: HtmlEditor | undefined): string | null => {
  if (typeof htmlContent === 'object' && htmlContent !== null) {
    return htmlContent.value;
  }
  if (typeof htmlContent === 'string') {
    return htmlContent;
  }
  return null;
};
