import { InformationCircleFilled32 } from '@packages/themes/icons';
import { buttonClasses } from '@mui/material/Button';
import { forwardRef } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { Button } from '../Button';
import type { TooltipVariant } from './types';

type TooltipButtonProps = TooltipVariant & {
  handleTooltip?: () => void;
  ariaLabelledBy?: string;
};

const tooltipAriaLabel = defineMessage({
  id: 'navigation.tooltip.cms.back.aria-label',
  defaultMessage: 'Weitere Informationen anzeigen',
});

/**
 * TooltipButton component - A button specifically designed to trigger a tooltip.
 *
 * @param {TooltipButtonProps} props - The properties for the TooltipButton.
 * @param {() => void} [props.onTooltipOpen] - An optional callback function that is called when the button is clicked. This is typically used to control the display of the tooltip.
 * @param {React.Ref<HTMLButtonElement>} ref - A ref that points to the DOM element of the button.
 * @returns A React element that renders a button with an information icon, primarily used to trigger a tooltip.
 */
export const TooltipButton = forwardRef<HTMLButtonElement, TooltipButtonProps>(
  ({ handleTooltip, variant, ariaLabelledBy, ...props }, ref) => {
    const { formatMessage } = useIntl();

    return (
      <Button
        aria-labelledby={ariaLabelledBy}
        aria-label={formatMessage(tooltipAriaLabel)}
        className="tooltip-button"
        ref={ref}
        {...(handleTooltip && { onClick: handleTooltip })}
        startIcon={
          <InformationCircleFilled32
            sx={{
              color: 'grey.dark',
            }}
          />
        }
        sx={{
          p: variant === 'inline' ? 0 : '0.5rem',
          m: 0,
          minWidth: 'auto',
          minHeight: 'auto',
          width: 'auto',
          height: 'auto',
          lineHeight: 'unset',
          [`.${buttonClasses.startIcon}`]: {
            m: 0,
          },
          '&:hover': {
            backgroundColor: 'transparent',
            [`.${buttonClasses.startIcon} svg`]: {
              filter: 'drop-shadow(0 0 2px rgb(0 0 0 / 0.4))',
            },
          },
        }}
        variant="text"
        disableRipple
        {...props}
      />
    );
  },
);
