import { Box } from '../Box';
import { TooltipItem } from './Tooltip.Item';
import type { TooltipType } from './types';

/**
 * Tooltip component - A customized HTML tooltip for displaying content based on provided data.
 *
 * @param {TooltipType} props - The properties for the tooltip.
 * @param {React.ReactNode} props.children - Children elements to be rendered within the tooltip. This can be any valid React node.
 * @param {string | React.ReactNode} props.content - The HTML content or React component to be displayed inside the tooltip.
 * @param {'top-start' | 'top-end' | 'bottom-start' | 'bottom-end'} [props.positioning='top-start'] - The placement of the tooltip relative to the target element. Defaults to 'top-start'.
 *    - 'top-start', 'top-end': Positions the tooltip at the top of the target element, aligned either to the start or end.
 *    - 'bottom-start', 'bottom-end': Positions the tooltip at the bottom, aligned to the start or end.
 * @returns A React component that renders a customized Tooltip based on the specified configuration.
 */
export const Tooltip = ({
  variant = 'default',
  children,
  content,
  positioning = 'top-start',
}: TooltipType) => (
  <Box
    className="tooltip"
    component="span"
    sx={{
      display: 'inline-flex',
      position: 'relative',
      ...(variant === 'inline' && {
        '& .tooltip-cms': {
          position: 'relative',
          '& [variant="inline"]': {
            lineHeight: 1,
          },
          ...((positioning === 'left' ||
            positioning === 'top-start' ||
            positioning === 'bottom-start') && {
            mr: 1.5,
            order: -1,
          }),
          ...((positioning === 'right' ||
            positioning === 'top-end' ||
            positioning === 'bottom-end') && {
            ml: 1.5,
          }),
          ...((positioning === 'top-start' || positioning === 'top-end') && {
            top: '-0.55rem',
          }),
          ...((positioning === 'bottom-start' || positioning === 'bottom-end') && {
            bottom: '-0.55rem',
          }),
        },
      }),
      ...(variant === 'default' &&
        positioning && {
          '& .tooltip-cms': {
            position: 'absolute',
            ...((positioning === 'top-start' || positioning === 'top-end') && {
              top: 0,
            }),
            ...((positioning === 'left' || positioning === 'right') && {
              top: '50%',
              transform: 'translateY(-50%)',
            }),
            ...((positioning === 'bottom-start' || positioning === 'bottom-end') && {
              bottom: 0,
            }),
            ...((positioning === 'left' ||
              positioning === 'top-start' ||
              positioning === 'bottom-start') && {
              left: 0,
            }),
            ...((positioning === 'right' ||
              positioning === 'top-end' ||
              positioning === 'bottom-end') && {
              right: 0,
            }),
          },
        }),
    }}
  >
    {children && children}
    <TooltipItem
      variant={variant}
      content={content}
      {...((positioning === 'left' || positioning === 'right') && { direction: positioning })}
    />
  </Box>
);
