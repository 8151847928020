import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import { Box } from '@packages/shared';

export type PrimaryNavigationContainerProps = {
  children: ReactNode;
  onMouseLeave: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isVisible: boolean;
};

export const PrimaryNavigationContainer = forwardRef<
  HTMLDivElement | null,
  PrimaryNavigationContainerProps
>(({ children, onMouseLeave, isVisible }, ref) => (
  <Box
    ref={ref}
    component="ul"
    gridArea="content"
    sx={(theme) => ({
      margin: 0,
      padding: 0,
      display: 'flex',
      overflow: 'hidden',
      [theme.breakpoints.up('lg')]: {
        // NOTE wrap and min/max height are used to hide full items on overflow, when the navigation items are too wide
        flexWrap: 'wrap',
        minHeight: `calc(${theme.spacing(5.5)} + 2px)`,
        maxHeight: `calc(${theme.spacing(5.5)} + 2px)`,
        listStyle: 'none',
      },
      [theme.breakpoints.down('lg')]: {
        margin: 0,
        padding: 1.5,
        paddingTop: 4.5,
        gap: 2,
        display: isVisible ? 'grid' : 'none',
        width: '100%',
        gridTemplateColumns: 'repeat(auto-fill, minmax(10rem, 1fr))',
        gridAutoRows: '7.5rem',
        placeItems: 'stretch',
        container: 'grid / size',
        overflowY: 'auto',
        overscrollBehavior: 'contain',
      },
    })}
    onMouseLeave={onMouseLeave}
  >
    {children}
  </Box>
));
