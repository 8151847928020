import DOMPurify from '@packages/config/utils/dompurify';
import { Box } from '@packages/shared';
import { registerComponents } from '../../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../../interfaces';
import { footerListStyles } from '../styles';

import type { FooterBlockListType } from '../types';

type BlockListProps = {
  data: FooterBlockListType;
};

export enum BlockListTestIds {
  main = 'block-list',
}

export const BlockList = ({ data }: BlockListProps) => (
  <Box
    sx={footerListStyles}
    data-testid={BlockListTestIds.main}
    key={`${BlockListTestIds.main}-${data.id}`}
    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.field_html_editor) }}
  />
);

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.BlockList]: BlockList });
