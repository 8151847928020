import { defineMessage, useIntl } from 'react-intl';
import { Service32 } from '@packages/themes/icons';
import { ErrorBoundary } from '@packages/shared';
import { useI18n } from '@packages/shared/src/hooks/useI18n/useI18n';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { Suspense } from 'react';
import { Slot } from './Slot';
import { ServiceSlotMenuList } from './ServiceSlotMenuList';

const title = defineMessage({
  id: 'slot.service.label',
  defaultMessage: 'Service & Hilfe',
});

/** Service slot with list of helpers in popover */
export const ServiceSlot = () => {
  const { formatMessage } = useIntl();
  const { locale } = useI18n();
  const { headerLinks, lifeSupportForBasicallyDeadTenant } = useConfig();

  return (
    <Slot
      useRouterLink={false}
      icon={<Service32 />}
      title={formatMessage(title)}
      href={headerLinks[locale].serviceLink}
    >
      {lifeSupportForBasicallyDeadTenant ? null : (
        <ErrorBoundary>
          <Suspense>
            <ServiceSlotMenuList />
          </Suspense>
        </ErrorBoundary>
      )}
    </Slot>
  );
};
