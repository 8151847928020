import type { ImageLoaderProps } from 'next/image';
import config from '@packages/config';

// https://nextjs.org/docs/api-reference/next.config.js/custom-image-loader-config#akamai
// https://techdocs.akamai.com/ivm/reference/test-images-on-demand
export function akamaiLoader({ src, width }: ImageLoaderProps) {
  if (!src.includes(`//bilder.${config.host.domain}`) || !width || src.includes('imwidth'))
    return src;

  if (src.includes('#')) return `${src}&imwidth=${width}`;

  return `${src}#imwidth=${width}`;
}
