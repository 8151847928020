import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWarenhaus32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M21.009 12.764V6.946h-6.004v.529h1.836V10.928l-.062 9.738v1.991c0 .467-.062.871-.187 1.244-.218.591-.56.964-1.089 1.151a2.634 2.634 0 0 1-1.4.093c-.436-.093-.778-.249-.996-.529a2.153 2.153 0 0 1-.467-.871c-.218-.716-.187-1.493.062-2.271.187-.622.093-1.182-.342-1.649-.778-.84-2.116-.964-3.018-.28-.529.404-.809.933-.809 1.587 0 .28.062.591.218.964a6.024 6.024 0 0 0 1.276 1.867c1.027.964 2.302 1.556 3.827 1.742.093 0 .218.031.311.031h1.028c.093 0 .218-.031.342-.031 1.524-.187 2.831-.778 3.827-1.742a5.684 5.684 0 0 0 1.649-3.267c.031-.28.062-.56.062-.84v-4.511c-.062-.871-.062-1.742-.062-2.582z" /><path d="M16 30.251c-7.871 0-14.251-6.38-14.251-14.251S8.129 1.749 16 1.749c7.871 0 14.251 6.38 14.251 14.251S23.871 30.251 16 30.251m0-1c7.318 0 13.251-5.933 13.251-13.251S23.318 2.749 16 2.749 2.749 8.682 2.749 16 8.682 29.251 16 29.251" /></SvgIcon>;
};
const Memo = memo(SvgWarenhaus32);
export default Memo;