import { MenuItem as MuiMenuItem } from '@mui/material';
import type { MenuItemProps as MuiMenuItemProps, MenuItemTypeMap } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';

export type MenuItemProps = MuiMenuItemProps;

/** Menu item component to render items in the menu list. Gets primary background on hover. Based on MUI MenuItem: https://mui.com/material-ui/api/menu-item/ */
export const MenuItem: OverridableComponent<MenuItemTypeMap & MuiMenuItemProps> = (
  props: MenuItemProps,
) => <MuiMenuItem {...props} />;
