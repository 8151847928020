import { Stack as MuiStack } from '@mui/material';
import type { StackProps as MuiStackProps, StackTypeMap } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { forwardRef } from 'react';

export type StackProps = MuiStackProps;

/** Shows children along vertical or horizontal axis with optional spacing or dividers between each child. Based on MUI Stack: https://mui.com/material-ui/react-stack/ */
export const Stack: OverridableComponent<StackTypeMap & MuiStackProps> = forwardRef(
  (props, ref) => <MuiStack ref={ref} {...props} data-testid="stack" />,
) as OverridableComponent<StackTypeMap & MuiStackProps>;
