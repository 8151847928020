export type BuildUrlOptions = {
  pathname?: string | null; // null check is necessary because in pages/app router compatibility mode, pathname can be null
  searchParams?: URLSearchParams | null; // null check is necessary because in pages/app router compatibility mode, pathname can be null
  fragment?: string;
};

/**
 * Construct a URL from a pathname and search params
 *
 * Defaults to the root URL if no pathname is provided
 */
export const buildUrl = ({ pathname, searchParams, fragment }: BuildUrlOptions) => {
  const serializedSearchParams = searchParams?.toString();

  return `${pathname ?? '/'}${serializedSearchParams ? `?${serializedSearchParams}` : ''}${fragment ? `#${fragment}` : ''}`;
};
