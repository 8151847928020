import { useSearchHistoryUpdate } from './useSearchHistoryUpdate';
import { useSearchQuery } from './useSearchQuery';

/** This component writes every visited /s/ URL to the search history. A separate component is used to scope re-renders and prevent re-renders of the full header. */
export const SearchHistoryUpdater = () => {
  const searchQuery = useSearchQuery();
  useSearchHistoryUpdate(searchQuery);

  return null;
};
