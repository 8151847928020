'use client';

import type { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import type { IntlConfig } from 'react-intl';

export interface LanguageProviderProps extends IntlConfig {
  children: ReactNode;
}

export const LanguageProvider = ({ children, ...props }: LanguageProviderProps) => (
  <IntlProvider
    // temporary disable 'MissingTranslationError' in development until the locales bundler is available
    onError={() => null}
    // enables global usage of elements in messages
    defaultRichTextElements={{
      b: (chunks) => <b>{chunks}</b>,
      br: () => <br />,
    }}
    wrapRichTextChunksInFragment
    {...props}
  >
    {children}
  </IntlProvider>
);
