import type { FC } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GlobalStyles } from '@mui/system';
import { Box } from '@packages/shared';

export const NavigationDrawer: FC<{
  open: boolean;
  onUpdate: (isOpen: boolean) => void;
  children: React.ReactNode;
}> = ({ open, onUpdate, children }) => {
  const startOffset = useRef<Touch>();
  // Ref for not needing the state as dependency for event registration
  const progress = useRef<number>(0);
  const [renderProgress, setRenderProgress] = useState<number>(0);
  const element = useRef<HTMLDivElement>(null);
  const isOpen = useRef<boolean>(false);

  // sync ref to state
  useEffect(() => {
    progress.current = renderProgress;
  }, [renderProgress]);

  // sync state to open property
  useEffect(() => {
    isOpen.current = open;
    setRenderProgress(open ? 1 : 0);
  }, [open]);

  const touchStart = useCallback<(evt: TouchEvent) => void>(({ touches }) => {
    const touch = touches[0];

    // only start if you touch on the left side of window
    if ((touch && touch.clientX < window.innerWidth * 0.2) || isOpen.current) {
      startOffset.current = touch;
    }
  }, []);

  const touchMove = useCallback<(evt: TouchEvent) => void>(({ touches }) => {
    if (!element.current || !startOffset.current) return;

    // abort if swiping vertically
    if (
      !progress.current &&
      Math.abs(startOffset.current.clientY - touches[0].clientY) * 1.5 >
        Math.abs(startOffset.current.clientX - touches[0].clientX)
    ) {
      startOffset.current = undefined;
      return;
    }

    setRenderProgress(
      Math.min(
        1,
        Math.max(
          0,
          (isOpen.current ? 1 : 0) +
            (touches[0].clientX - startOffset.current.clientX) / element.current.clientWidth,
        ),
      ),
    );
  }, []);

  const touchEnd = useCallback<(evt: TouchEvent) => void>(() => {
    startOffset.current = undefined;
    if ((!isOpen.current && progress.current > 0.2) || progress.current > 0.8) {
      setRenderProgress(1);
      onUpdate(true);
    } else {
      setRenderProgress(0);
      onUpdate(false);
    }
  }, [onUpdate]);

  useEffect(() => {
    document.addEventListener('touchstart', touchStart, { passive: true });
    document.addEventListener('touchmove', touchMove, { passive: true });
    document.addEventListener('touchend', touchEnd, { passive: true });
    document.addEventListener('touchcancel', touchEnd, { passive: true });

    return () => {
      document.removeEventListener('touchmove', touchMove);
      document.removeEventListener('touchstart', touchStart);
      document.removeEventListener('touchend', touchEnd);
      document.removeEventListener('touchcancel', touchEnd);
    };
  }, [touchStart, touchMove, touchEnd]);

  const clientWidth = element.current?.clientWidth;

  return (
    <>
      {renderProgress > 0 && (
        <GlobalStyles
          styles={`
	    body {
		  overflow: hidden
	    }
	  `}
        />
      )}
      {renderProgress > 0 && (
        <Box
          sx={{
            position: 'fixed',
            zIndex: 'drawer',
            inset: 0,
          }}
          data-testid="prevent-outside-click"
          onClick={() => onUpdate(false)}
        />
      )}
      <Box
        sx={{
          marginLeft: clientWidth
            ? `${-clientWidth + renderProgress * clientWidth}px`
            : 'max(-80vw, -20rem)',
          transition:
            renderProgress === 1 || renderProgress === 0 ? '0.25s ease-in-out' : undefined,
          transitionProperty: 'margin-left, box-shadow',
          willChange: 'margin-left, box-shadow',
          boxShadow: `0 2px 4px -1px rgba(0, 0, 0, ${
            renderProgress * 0.2
          }), 0 1px 10px 0 rgba(0, 0, 0, ${renderProgress * 0.12}), 0 4px 5px 0 rgba(0, 0, 0, ${
            renderProgress * 0.14
          }),  max(100vh, 100vw) 0 0 max(100vh, 100vw) rgba(0, 0, 0, ${renderProgress * 0.3})`,
          position: 'fixed',
          zIndex: 'drawer',
          top: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'common.white',
          width: '20rem',
          maxWidth: '80vw',
        }}
        ref={element}
        data-testid="navigation-drawer"
        onScroll={() => {
          // prevent horizontal swipeOut during scroll
          startOffset.current = undefined;
        }}
      >
        {children}
      </Box>
    </>
  );
};
