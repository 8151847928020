import type { SxProps } from '@mui/system';
import { type FC } from 'react';
import { Box } from '../Box';

export interface DynamicYieldProps {
  dataDyId?: string;
  sx?: SxProps;
  margin?: string;
  padding?: string;
}

/**
 * This component is used to render DynamicYield content.
 * You can style it with sx props. If the content is empty, it will be hidden, so the styling wont apply.
 * Otherwise you might have margins or paddings for an empty element.
 */
export const DynamicYield: FC<DynamicYieldProps> = ({
  dataDyId = 'empty',
  margin,
  padding,
  sx,
}): JSX.Element => (
  <Box
    data-dy={dataDyId}
    sx={[
      Boolean(margin) && {
        margin,
      },
      Boolean(padding) && {
        padding,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
      {
        // ...sx,
        '&:empty': {
          display: 'none',
        },
      },
    ]}
    data-testid="dy"
  />
);
