import { useSession } from '@packages/shared/src/hooks/useSession/useSession';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

/**
 * This hook calls the Reco service, which will set the so called pace_id cookie.
 * This cookie contains a hashed id, where the id varies based on the current user status.
 *
 * This ID is needed to personalize prudsys recommendations.
 * The hook sends all important data, like cookies and the JWT to the backend service,
 * which will then decide, what the value of the pace_id cookie is going to be.
 *
 * For further documentation, please refer to this link: https://empiriecom.atlassian.net/wiki/spaces/PACE/pages/41551138/Benutzeridentifikation+prudsys
 * @returns error
 */
export const usePaceId = () => {
  const searchParams = useSearchParams();
  const [error, setError] = useState<Error>();
  const [isFetched, setIsFetched] = useState(false);
  const { loading, jwtToken } = useSession();

  // getting the N_id or K_id from URL, if there is one
  // might look something like this ?userid=userid187
  // NOTE: null check is for pages/app router compat mode, later for pure app router this can never be null
  const paceId = searchParams?.get('userid') || null;

  useEffect(() => {
    const body = paceId ? { paceId } : {};

    // just a temporary solution to prevent next requests in development... the endpoint has to be updated to an api endpoint
    if (!loading && !isFetched && process.env.NEXT_PUBLIC_NODE_ENV !== 'development') {
      // fetch will execute set-cookie directive sent from server
      fetch('/paceid', {
        headers: {
          'Content-Type': 'application/json',
          ...(jwtToken ? { 'x-ec-token': jwtToken } : {}),
        },
        body: JSON.stringify(body),
        method: 'POST',
      })
        .then(() => {
          setIsFetched(true);
        })
        .catch((err: Error) => {
          setError(err);
          setIsFetched(false);
        });
    }
  }, [loading, isFetched, jwtToken, paceId]);

  return { error };
};
