import { createTheme } from '@mui/material/styles';
import type { ThemeOptions } from '@mui/material/styles';

export const DEFAULTCOLOR_TEXT_DARK = '#262729';
export const DEFAULTCOLOR_TEXT_LIGHT = '#ffffff';
export const DEFAULTCOLOR_TEXT_DISABLED = '#4e5051';
export const DEFAULTCOLOR_TEXT_TRANSPARENT_DARK = 'rgba(38, 39, 41, 0.65)';
export const DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT = 'rgba(255, 255, 255, 0.65)';
export const DEFAULTCOLOR_COMMON_LIGHT = '#eef3f7';

export const DEFAULT_TONAL_OFFSET = 0.25;

const { palette: basePalette } = createTheme();
export const { augmentColor } = basePalette;

export const palette: ThemeOptions['palette'] = {
  primary: {
    main: '#068099',
    textTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
  },
  secondary: {
    main: '#C44F4F',
    textTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
  },
  additional: augmentColor({
    color: {
      main: '#3C9ADD',
      textTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
    },
  }),
  grey: {
    main: '#909498',
    light: DEFAULTCOLOR_COMMON_LIGHT,
    dark: '#4e5051',
  },
  text: {
    disabled: DEFAULTCOLOR_TEXT_DISABLED,
    primary: DEFAULTCOLOR_TEXT_DARK,
    secondary: DEFAULTCOLOR_TEXT_TRANSPARENT_DARK,
    dark: DEFAULTCOLOR_TEXT_DARK,
    darkTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_DARK,
    light: DEFAULTCOLOR_TEXT_LIGHT,
    lightTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
  },
  sale: augmentColor({
    color: {
      main: '#b71739',
      contrastText: DEFAULTCOLOR_TEXT_LIGHT,
      textTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
    },
  }),
  info: {
    main: '#8B969C',
    light: '#f1f2f3',
    dark: '#626566',
    textTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
  },
  success: {
    main: '#7dae2e',
    light: '#deebcb',
    dark: '#49661b',
    textTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
  },
  warning: {
    main: '#f5a623',
    light: '#fce9c8',
    dark: '#66450f',
    textTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_DARK,
  },
  error: {
    main: '#d0021b',
    light: '#f3c0c6',
    dark: '#66010d',
    textTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
  },
  sustainability: {
    main: '#577F5B',
    light: '#c3e6c7',
    dark: '#425C45',
    contrastText: DEFAULTCOLOR_TEXT_LIGHT,
  },
  footer: {
    main: DEFAULTCOLOR_COMMON_LIGHT,
    contrastText: DEFAULTCOLOR_TEXT_DARK,
  },
  payback: {
    main: '#0046aa',
    light: '#ebf5fb',
    dark: '#002a66',
  },
  joe: {
    main: '#d6ab53',
    light: '#f2e6ce',
    dark: '#665228',
  },
  action: {
    disabled: '#b1b4b7',
  },
  kim: {
    main: '#fdc300',
  },
  // base value for calculating light and dark for every palette instance
  tonalOffset: DEFAULT_TONAL_OFFSET,
};
