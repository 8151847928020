import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCancel32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="m17.128 16 5.637-5.638a.803.803 0 0 0 0-1.128.796.796 0 0 0-1.127 0L16 14.873l-5.638-5.637a.803.803 0 0 0-1.128 0 .796.796 0 0 0 0 1.127L14.873 16l-5.637 5.638a.803.803 0 0 0 0 1.128.796.796 0 0 0 1.127 0L16 17.127l5.638 5.637a.803.803 0 0 0 1.128 0 .796.796 0 0 0 0-1.127z" className="cancel-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgCancel32);
export default Memo;