import { Box } from '@packages/shared';
import config from '@packages/config';
import type { CmsData } from '../../../interfaces/webapp_json';
import { CmsAccordionTestIds, CmsGeneralAccordion } from '../../components';
import { ComponentRecognizer } from '../../components/ComponentRecognizer';
import type { FooterBlockType } from '../../components/Footer/types';

type FooterAccordionProps = {
  data: CmsData;
};

export const FooterAccordion = ({ data }: FooterAccordionProps): JSX.Element => {
  const comp = (data.paragraph as FooterBlockType[])
    ?.filter((item) => item?.parent_field_name === 'field_footer')
    ?.map((item) => ({
      id: `footer-accordion-${item.id}`,
      headline: item.field_headline,
      isOpen: item.field_is_open,
      content: <ComponentRecognizer item={item} />,
    }));

  return (
    <Box
      sx={{
        width: '100%',
        margin: 0,
        padding: 0,
        '& .layout-variant-inline': {
          width: 'auto',
          display: 'flex',
          alignContent: 'center',
          flexShrink: 0,
          verticalAlign: 'top',
        },
        '& .footer_block_badges': {
          display: 'flex',
          gap: 1.6,
        },
      }}
      data-testid={CmsAccordionTestIds.main}
    >
      <CmsGeneralAccordion
        styleVariant={config.styleVariant}
        entries={comp}
        highlightOpen={false}
      />
    </Box>
  );
};
