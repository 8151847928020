import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  nowShopping: {
    id: 'PromotionBanner.NowShopping',
    defaultMessage: 'Jetzt shoppen!',
  },
  newsletterSubscribeLink: {
    id: 'PromotionBanner.NewsletterSubscribeLink',
    defaultMessage: '/service-hilfe/newsletter/anmeldung',
  },
  newsletterTeaserText: {
    id: 'PromotionBanner.NewsletterTeaserText',
    defaultMessage:
      'Schnell & einfach zu unserem Newsletter anmelden und keine Aktionen & Angebote mehr verpassen!',
  },
  choiceSummary: {
    id: 'PromotionBanner.ChoiceLayer.Summary',
    defaultMessage: 'Du kannst nur einen Vorteil nutzen',
  },
  choiceSelect: {
    id: 'PromotionBanner.ChoiceLayer.Select',
    defaultMessage: 'Welchen Vorteil möchtest du nutzen?{br} Bitte wähle aus',
  },
  choicePersist: {
    id: 'PromotionBanner.ChoiceLayer.Persist',
    defaultMessage: 'Meinen gewählten Vorteil einlösen',
  },
  expiredTitle: {
    id: 'PromotionBanner.Expired.Title',
    defaultMessage: 'Dein Vorteilscode ist nicht mehr gültig',
  },
});
