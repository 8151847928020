'use client';

import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { type VariantProps } from 'class-variance-authority';

import type {
  GlycerinButtonClickPayload,
  GlycerinFormSubmitClickPayload,
} from '@packages/tracking/src/types/payloads';
import type {
  GTMEventGlycerinButtonClick,
  GTMEventGlycerinFormSubmitClick,
} from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { buttonVariants } from './buttonVariants';
import { cn } from '../../utils/cn/cn';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  clickTrackingProps?: GlycerinButtonClickPayload | GlycerinFormSubmitClickPayload;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { clickTrackingProps, onClick, type, className, variant, size, asChild = false, ...props },
    ref,
  ) => {
    const dispatchGTMEvent = useTracking();
    const { category, target, detail, label, placement, variantIds, custom } =
      clickTrackingProps ?? {};
    const onClickTrackingHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (type === 'submit') {
        dispatchGTMEvent<GTMEventGlycerinFormSubmitClick>({
          event: 'FormSubmitClick',
          FormSubmitClickData: {
            category: category ?? 'form-submit-click',
            target,
            detail,
            label,
            placement,
            formId:
              clickTrackingProps && 'formId' in clickTrackingProps
                ? clickTrackingProps.formId
                : undefined,
            variantIds,
            custom,
          },
        });
      } else {
        dispatchGTMEvent<GTMEventGlycerinButtonClick>({
          event: 'ButtonClick',
          ButtonClickData: {
            category: category ?? 'button-click',
            target,
            detail,
            label,
            placement,
            variantIds,
            custom,
          },
        });
      }
      // Call the original onClick handler
      onClick?.(event);
    };
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        onClick={onClickTrackingHandler}
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      >
        {props.children}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
