import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { useCookies } from '@packages/shared/src/providers/CookieProvider/CookieProvider';
import { searchParamsToObject } from '@packages/shared/src/utils/searchParamsToObject/searchParamsToObject';
import { useAbTest } from '@packages/shared/src/abtesting/useAbTest/useAbTest';

import {
  getInspiringSearchSettingsInternal,
  getTestParticipation,
  INSPIRING_SEARCH_TEST_ID,
  INSPIRING_SEARCH_V2_TEST_ID,
} from './useInspiringSearchSettings.shared';

/**
 * Get the activation status for Inspiring Search features (client-side)
 */
export const useInspiringSearchSettings = () => {
  const config = useConfig();
  const searchParams = useSearchParams();
  const { getCookies } = useCookies();

  useAbTest(INSPIRING_SEARCH_TEST_ID);
  useAbTest(INSPIRING_SEARCH_V2_TEST_ID);

  const cookies = getCookies();

  const testParticipation = getTestParticipation(cookies);

  return useMemo(
    () =>
      getInspiringSearchSettingsInternal(
        searchParamsToObject(searchParams),
        config,
        testParticipation,
      ),
    [config, searchParams, testParticipation],
  );
};
