import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgArrowMediumLeft32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M19.522 10.801a.49.49 0 0 0-.006-.699l-.958-.958a.503.503 0 0 0-.7-.005L11 15.998l6.858 6.858a.491.491 0 0 0 .7-.005l.958-.958a.499.499 0 0 0 .006-.7l-5.196-5.195z" className="arrow-medium-left-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgArrowMediumLeft32);
export default Memo;