import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgNewsletter32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M28.757 10.071a.5.5 0 1 1-.514.858l-1.9-1.14a.5.5 0 0 1 .514-.858zM17.933 3.577 23.638 7H26.5a.5.5 0 0 1 .5.5v4.816l1-.6V10.5a.5.5 0 1 1 1 0v15c0 1.383-1.116 2.5-2.504 2.5H5.504A2.498 2.498 0 0 1 3 25.5V10.48c.001-.022.004-.045.008-.068L3 10.5a.501.501 0 0 1 .243-.429L5 9.016V7.5a.5.5 0 0 1 .5-.5h2.88l5.693-3.425c1.083-.65 2.775-.65 3.86.002M28 12.882l-8.382 5.029 6.736 6.735a.5.5 0 0 1 .057.638l-.057.07a.5.5 0 0 1-.708 0l-6.911-6.912-.766.46c-1.106.663-2.833.663-3.938 0l-.767-.461-6.912 6.916a.5.5 0 1 1-.708-.707l6.736-6.74L4 12.884V25.5c0 .832.67 1.5 1.504 1.5h20.992c.835 0 1.504-.669 1.504-1.5zM26 8H6v4.917l4.631 2.78a5.515 5.515 0 0 1-.131-1.2C10.5 11.29 12.863 9 16.5 9c3.479 0 4.956 1.95 4.344 4.523-.234.987-.775 1.924-1.425 2.474-.791.671-1.705.711-2.315-.077l-.035-.056c-.16-.155-.254-.007-.414.139-.389.355-.817.577-1.561.577-1.167 0-2.094-.847-2.094-2.208 0-1.613 1.224-2.872 2.563-2.872.79 0 1.259.343 1.579.778l.126-.606 1.35.217-.538 3.078a2.056 2.056 0 0 0-.034.355c0 .046.004.09.011.134.186.104.412.036.716-.222.479-.405.911-1.155 1.098-1.942C20.336 11.338 19.323 10 16.5 10c-3.091 0-5 1.85-5 4.496 0 .661.142 1.3.413 1.886a.502.502 0 0 1 .035.103l2.597 1.56c.789.472 2.12.473 2.91 0L26 12.914zm-10.208 4.678c-.332 0-.641.138-.893.39-.297.297-.48.72-.48 1.224 0 .71.446 1.11 1.075 1.11.32 0 .63-.138.87-.378.297-.297.492-.755.492-1.27 0-.652-.446-1.076-1.064-1.076M5 10.182l-1 .6v.935l1 .6zm9.589-5.75L10.319 7h11.377l-4.277-2.566c-.769-.46-2.065-.462-2.83-.002" className="newsletter-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgNewsletter32);
export default Memo;