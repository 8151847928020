import type { NavigationData } from '../types';

import { chunk } from './chunk';

// TODO: completely refactor this, it's a mess
// TODO: add tests
// TODO: add more docs
/**
 * Group items into columns with limited number of items per column (defined as `newColumnAfter` in an item, for its children)
 *
 * @param navigation a subtree of the full navigation tree, corresponding to a single L2 (if the tenant has a single nav bar) or L3 (if the tenant has a double nav bar) category and its children
 * @returns navigation items grouped into columns, and sorted by `colPosition`
 */
export const getNavigationCluster = (navigation: NavigationData) =>
  navigation
    // split item into multiple columns if `newColumnAfter` exists and there are too many children for one column
    .reduce((items: NavigationData, item) => {
      if (!item.newColumnAfter || !item.children) return [...items, item];

      const maxItemsPerColumn = item.newColumnAfter ?? item.children.length;

      const columns = chunk(item.children, maxItemsPerColumn);

      return [
        ...items,
        ...columns.map((children, i) => ({
          // the first column should keep the original item, to display the header, subsequent columns have no header
          ...(i === 0 ? item : {}),
          // since the original `colPosition` is an integer, we add a small fraction to each new synthetic item to make sure the columns are sorted correctly
          // e.g. original items had positions [1, 2], with 2 additional columns inserted in-between we now have [1, 1.01, 1.02, 2]
          colPosition: (item.colPosition ?? 1) + 0.01 * i,
          children,
        })),
      ];
    }, [])
    // navigations to cols
    .reduce((clusters: NavigationData[], item) => {
      const existingCluster = clusters.find(
        ([{ colPosition }]) => colPosition === item.colPosition,
      );

      if (!item.colPosition || !existingCluster) return [...clusters, [item]];

      return clusters.map((cluster) =>
        cluster === existingCluster ? [...existingCluster, item] : cluster,
      );
    }, [])
    // sort clusters by colPosition
    .sort(
      (
        [{ colPosition: a = navigation.length * 2 }],
        [{ colPosition: b = navigation.length * 2 }],
      ) => a - b,
    );
