import type { ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import type { CSSProperties } from 'react';
import type { Device } from './components';
import { createComponents } from './components';
import { palette } from './palette';
import { createTypography } from './typography';

export type { Device } from './components';

export const FONT_PRELOADS = [];

// extend types to satisfy styleguide: https://app.zeplin.io/project/5d493736066e193158b2d910/dashboard
declare module '@mui/material/styles' {
  // extend palette
  interface Palette {
    additional?: Palette['primary'];
    sale?: Palette['primary'];
    sustainability?: Palette['primary'];
    footer?: Palette['primary'];
    payback?: Palette['primary'];
    joe?: Palette['primary'];
    kim?: Palette['primary'];
  }

  interface PaletteOptions {
    additional?: PaletteOptions['primary'];
    sale?: PaletteOptions['primary'];
    sustainability?: PaletteOptions['primary'];
    footer?: PaletteOptions['primary'];
    payback?: PaletteOptions['primary'];
    joe?: PaletteOptions['primary'];
    kim?: PaletteOptions['primary'];
  }

  interface PaletteColor {
    textTransparent?: string;
  }

  interface SimplePaletteColorOptions {
    textTransparent?: string;
  }

  interface TypeText {
    dark: string;
    darkTransparent: string;
    light: string;
    lightTransparent: string;
  }

  interface TypographyVariants {
    body3: CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: CSSProperties;
  }

  interface FontStyleOptions {
    fontWeightBlack: number;
  }

  interface FontStyleOverrides {
    fontWeightBlack: true;
  }
}

declare module '@mui/material' {
  interface ButtonPropsColorOverrides {
    sale: true;
    additional: true;
  }

  export interface Color {
    main?: string;
    light?: string;
    dark?: string;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    subtitle1: false;
    subtitle2: false;
    button: false;
    caption: false;
    overline: false;
  }
  interface FontStyleOverrides {
    fontWeightBlack: true;
    fontWeightSemiBold: true;
  }
}

declare module '@mui/material/styles/createTypography' {
  interface FontStyle {
    fontWeightBlack: number;
    fontWeightSemiBold: number;
  }
}

declare module '@mui/material/Slider' {
  interface SliderPropsColorOverrides {
    additional: true;
  }
}

const themeOptions = (device: Device): ThemeOptions => ({
  spacing: (factor: number) => `${factor * 0.5}rem`,
  palette,
  transitions:
    process.env.NODE_ENV === 'test'
      ? {
          // So we have `transition: none;` everywhere
          create: () => 'none',
        }
      : undefined,
  typography: createTypography(),
  components: createComponents(device),
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 600,
      lg: 900,
      xl: 1140,
    },
  },
  // TODO: fill or overwrite types
  shadows: [
    'none',
    '0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3)',
    '0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3)',
    '0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3)',
    '0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3)',
    'none',
    '0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px 0 rgba(0, 0, 0, 0.3)',
    'none',
    '0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px 0 rgba(0, 0, 0, 0.3)',
    '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0 2px 3px 0 rgba(0, 0, 0, 0.3)',
    'none',
    'none',
    '0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px 0 rgba(0, 0, 0, 0.3)',
    'none',
    'none',
    'none',
    '0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px 0 rgba(0, 0, 0, 0.3)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    '0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px 0 rgba(0, 0, 0, 0.3)',
  ],
});

export const generateDefaultThemeOptions = (device: Device) => themeOptions(device);
const theme = (device: Device) => createTheme(generateDefaultThemeOptions(device));

// eslint-disable-next-line import/no-default-export
export default theme;
