import { forwardRef } from 'react';

import type { StackProps } from '@packages/shared';
import { Stack } from '@packages/shared';

import { ResponsiveBackground } from './ResponsiveBackground';

export type SecondaryNavigationContainerProps = Pick<StackProps, 'children' | 'onMouseLeave'> & {
  isVisible: boolean;
};
export const SecondaryNavigationContainer = forwardRef<
  HTMLDivElement | null,
  SecondaryNavigationContainerProps
>(({ children, onMouseLeave, isVisible }, ref) => (
  // Desktop needs two nested divs for the flyout background to work properly, on mobile it would also work with a single container div.
  <Stack
    sx={{
      display: isVisible ? 'flex' : 'none',
      // `anchor` is a zero-height area below the main navigation, which is used as an anchor point for the flyout on desktop
      // `content` is the main content area of the nav dialog on mobile, the same area where the primary nav items are displayed
      gridArea: { xs: 'content', lg: 'anchor' },
      // overflow: hidden is necessary on mobile, to act as a scroll container (overflow can't work if the parent doesn't constrain the size)
      overflow: { xs: 'hidden', lg: 'visible' },
      // both z-index and background-color are necessary to hide the L1 categories when using iOS rubber-band scrolling beyond the limit of the container, which shows the content below.
      zIndex: (theme) => theme.zIndex.tooltip,
      backgroundColor: 'white',
    }}
  >
    {/* On desktop, this overflows the outer div to show the flyout. On mobile, it acts as the scroll container for the nav link items. */}
    <Stack
      ref={ref}
      sx={{
        position: 'relative',
        flex: 1,
        zIndex: (theme) => theme.zIndex.tooltip,
        // overflow-x: visible is necessary on desktop, to allow the background of the flyout to reach to the edge of the viewport
        overflowX: { xs: 'hidden', lg: 'visible' },
        // overflow-y: auto is necessary on mobile, to allow the nav link items to scroll inside the dialog
        overflowY: { xs: 'auto', lg: 'visible' },
        overscrollBehavior: 'contain',
      }}
      onMouseLeave={onMouseLeave}
    >
      <ResponsiveBackground
        sx={{
          backgroundColor: { xs: 'transparent', lg: 'grey.light' },
        }}
      />

      {children}
    </Stack>
  </Stack>
));
