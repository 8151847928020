'use client';

import { createContext, type ReactNode, useMemo } from 'react';
import type { Device } from '@packages/themes/src/default';
import { useDevice, type DeviceType } from './useDevice';

export const MediaContext = createContext<DeviceType & { isTouch: boolean }>({
  isDesktop: false,
  isMobile: false,
  isTablet: false,
  isTouch: false,
});

const isTouchEnabled = () =>
  typeof window !== 'undefined' && ('ontouchstart' in window || navigator.maxTouchPoints > 0);

export const MediaProvider = ({
  initialMedia,
  children,
}: {
  initialMedia: Device;
  children: ReactNode;
}) => {
  const deviceType = useDevice({
    isMobile: initialMedia === 'mobile',
    isTablet: initialMedia === 'tablet',
    isDesktop: initialMedia === 'desktop',
  });

  return (
    <MediaContext.Provider
      value={useMemo(() => ({ ...deviceType, isTouch: isTouchEnabled() }), [deviceType])}
    >
      {children}
    </MediaContext.Provider>
  );
};
