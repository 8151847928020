import { fetchPromoData } from './fetchPromoData';
import type { PromotionDataFetchInformation } from './fetch.types';

export const fetchActivePromotionData = async ({
  cookies,
  query,
  callOwnApi,
  locale,
}: Pick<PromotionDataFetchInformation, 'query' | 'locale' | 'callOwnApi' | 'cookies'>) => {
  if (!cookies || (!cookies.bonuscode && !cookies.loyaltyCode)) {
    return undefined;
  }
  const { TestDate: testDate } = query;
  const { bonuscode, loyaltyCode, textsBonusCode } = cookies;
  const cookieCode = (bonuscode || loyaltyCode) as string;
  return fetchPromoData(
    { cart: cookieCode, texts: textsBonusCode || cookieCode },
    locale,
    callOwnApi,
    testDate,
  );
};
