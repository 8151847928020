import type { KeyboardEventHandler, FC } from 'react';

import { Box, Image, Typography } from '@packages/shared';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';

import type { ResponsiveNavigationItemBaseProps } from './ResponsiveNavigationItemBase';
import { ResponsiveNavigationItemBase } from './ResponsiveNavigationItemBase';

export type ResponsiveNavigationMainItemProps = Omit<
  ResponsiveNavigationItemBaseProps,
  'sxListItem' | 'sxLink'
> & {
  /** If `true`, the category corresponding to the item is the current page */
  isActive?: boolean;
  /** If `true`, the children of the item are displayed in the nav popup */
  isOpen?: boolean;
  imageUrl?: string;
};

/**
 * Navigation item for the main navigation (always visible)
 */
export const ResponsiveNavigationMainItem: FC<ResponsiveNavigationMainItemProps> = ({
  isActive,
  isOpen,
  imageUrl,
  children,
  onClick,
  ...baseProps
}) => {
  const {
    header: { hasSmallerFontSize },
  } = useConfig();
  const handleEnterKeyPress: KeyboardEventHandler<HTMLAnchorElement> = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onClick?.();
    }
  };

  return (
    <ResponsiveNavigationItemBase
      onKeyDown={handleEnterKeyPress}
      onClick={onClick}
      {...baseProps}
      sxListItem={[
        (theme) => ({
          [theme.breakpoints.up('lg')]: {
            justifyContent: 'center',
            alignItems: 'center',
            flex: { xs: '0 1 50%', lg: 1 },
            position: 'relative',
            cursor: 'pointer',
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderColor: 'grey.main',
            transition: 'background-color 0.25s borderColor 0.25s',
            '&:hover': {
              backgroundColor: 'grey.light',
            },
          },
        }),

        isActive && !isOpen
          ? (theme) => ({
              [theme.breakpoints.up('lg')]: {
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '0.25rem',
                  borderRadius: '0.25rem 0.25rem 0 0',
                  backgroundColor: 'primary.main',
                },
              },
            })
          : {},
        isOpen
          ? (theme) => ({
              [theme.breakpoints.up('lg')]: {
                borderBottomColor: 'transparent',
                backgroundColor: 'grey.light',
              },
            })
          : {},
        (theme) => ({
          [theme.breakpoints.down('lg')]: {
            width: '100%',
            cursor: 'pointer',
            boxShadow: 1,
            borderRadius: '3px',
            overflow: 'hidden',
          },
        }),
      ]}
      sxLink={{
        alignSelf: 'stretch',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: { lg: 'none' },
          alignSelf: 'stretch',
          flex: '1 1 0',
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: 'grey.light',
        }}
      >
        {imageUrl && (
          <Image
            src={imageUrl}
            // decorative image in line with WAI standards
            alt=""
            fill
            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
          />
        )}
      </Box>
      <Typography
        sx={(theme) => ({
          lineHeight: '1.25rem',
          paddingX: 1,
          [theme.breakpoints.up('lg')]: {
            fontSize: {
              lg: hasSmallerFontSize ? '0.93rem' : '1rem',
              xl: hasSmallerFontSize ? '1.06rem' : '1.125rem',
            },
            paddingY: 1.5,
            whiteSpace: 'nowrap',
            textAlign: 'center',
          },
          [theme.breakpoints.down('lg')]: {
            paddingY: 0.5,
          },
        })}
      >
        {children}
      </Typography>
    </ResponsiveNavigationItemBase>
  );
};
