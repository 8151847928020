import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { useCookies } from '@packages/shared/src/providers/CookieProvider/CookieProvider';

/**
 * Determines if the new navigation should be used, based on combined config settings and cookies.
 *
 * Distinguishes between desktop and mobile navigation, both can be toggled separately.
 *
 * Cookies: `feature/newDesktopNavigation` and `feature/newMobileNavigation` respectively, content does not matter as long as the cookie is present.
 */
export const useHasNewNavigation = () => {
  const {
    header: { hasNewNavigation },
  } = useConfig();

  const { getCookies } = useCookies();
  const cookies = getCookies();

  const hasDesktopCookie = 'feature/newDesktopNavigation' in cookies;
  const hasMobileCookie = 'feature/newMobileNavigation' in cookies;

  return {
    desktop: hasNewNavigation || hasDesktopCookie,
    mobile: hasNewNavigation || hasMobileCookie,
  };
};
