import type { ReactNode } from 'react';
import { useId } from 'react';
import type { SxProps } from '@mui/system';

import { Box, Typography } from '@packages/shared';

export const LinearSuggestionsGroup = ({
  heading,
  children,
  sx,
}: {
  heading: ReactNode;
  children: ReactNode;
  sx?: SxProps;
}) => {
  const headingId = useId();

  return (
    <>
      <Box id={headingId} sx={{ p: 1, backgroundColor: 'grey.main' }}>
        <Typography role="heading" sx={{ fontWeight: 600, mt: 0.5, mr: 4, mb: 0.75, ml: 0.75 }}>
          {heading}
        </Typography>
      </Box>
      <Box
        component="ul"
        role="menu"
        aria-labelledby={headingId}
        sx={{ margin: 0, padding: 0, ...sx }}
      >
        {children}
      </Box>
    </>
  );
};
