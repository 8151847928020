import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgErrorCircle32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><circle cx={16} cy={16} r={12} fill="#D0021B" /><path fill="#fff" d="M10.5 14.5v3h11v-3z" /></SvgIcon>;
};
const Memo = memo(SvgErrorCircle32);
export default Memo;