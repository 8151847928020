import { Typography as MuiTypography } from '@mui/material';
import type { TypographyProps as MuiTypographyProps } from '@mui/material';
import type { ElementType } from 'react';

export interface TypographyProps extends MuiTypographyProps {
  component?: ElementType;
}

/** Component to display text and headlines. Based on MUI Typography: https://mui.com/material-ui/react-typography/ */
export const Typography = (props: TypographyProps) => <MuiTypography {...props} />;
