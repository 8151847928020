import type { Locale } from '@packages/config';

export const getNavigationDataEndpoint = (
  clientId: string,
  channel: string,
  locale: Locale,
  sliced: boolean,
): string => {
  const environment = process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging' ? 'dev' : 'cloud';
  const isFr = locale.includes('fr') ? '_fr' : '';
  const formattedClientID = clientId.toLowerCase();
  const version = '0';
  const slicedVersion = sliced ? '_sliced' : '';

  return `https://storage.googleapis.com/emp-search-nav/${environment}/${formattedClientID}_${channel}${isFr}_${version}${slicedVersion}.json`;
};
