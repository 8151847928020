import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { useI18n } from '@packages/shared/src/hooks/useI18n/useI18n';
import { getCmsDataCacheKey } from '../utils/getCmsDataCacheKey';

/**
 * Hook to get the final language and the cache key for the footer CMS.
 *
 * @param {string} prefix - The prefix for the cache key.
 *
 * @property {string} finalLanguage - The final language, which is either the current language, the default language, or 'de' if both are undefined.
 * @property {string} cacheKey - The cache key for the footer CMS, which is a string that starts with the provided prefix followed by the final language.
 * @property {boolean} isDesktop - A Boolean value that indicates whether the current device is a desktop or not.
 * @returns An object containing the final language and the footer CMS cache key.
 */
export const useCmsFetcherDetails = (prefix: string) => {
  const { isDesktop } = useDeviceType();
  const { locale, language: currentLanguage, defaultLanguage } = useI18n();
  const language = currentLanguage || defaultLanguage;
  const cacheKey = getCmsDataCacheKey(prefix, isDesktop ? 'web' : 'mob', locale);

  return { language, cacheKey };
};
