import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import type { FC } from 'react';

import { ArrowMediumLeft32, Cancel32 } from '@packages/themes/icons';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { type GTMEventGlycerinButtonClick } from '@packages/tracking/src/types/events';

import { useDeviceType } from '../../hooks/useDeviceType/useDeviceType';

export type DialogHeaderProps = {
  /** Title that should be displayed in the header */
  title?: string;
  /** Callback fired when the header requests to return to the previous page */
  onBack?: () => void;
  /** Callback fired when the header requests to close the containing dialog */
  onClose?: () => void;
  /** Apply a workaround for a graphical glitch with the close button documented in https://github.com/mui/material-ui/issues/12879 */
  applyCloseButtonPaddingWorkaround?: boolean;
};

/**
 * A dialog header implementing the visual layout of the empiriecom design system
 * */
export const DialogHeader: FC<DialogHeaderProps> = ({
  title,
  onBack,
  onClose,
  applyCloseButtonPaddingWorkaround,
}) => {
  // https://github.com/mui/material-ui/issues/12879 seems to still exist, results in undesired padding-right of 15px for the close button, counteracted here by the transform
  const paddingWorkaround = applyCloseButtonPaddingWorkaround
    ? { '& .MuiButtonBase-root:last-child': { transform: 'translateX(15px)' } }
    : {};

  const { isDesktop } = useDeviceType();
  const dispatchGTMEvent = useTracking();

  return (
    <AppBar
      color="default"
      elevation={0}
      square
      sx={{
        position: 'relative',
        ...paddingWorkaround,
        borderBottom: 1,
        backgroundColor: 'transparent',
        borderColor: 'grey.main',
      }}
    >
      <Toolbar>
        {onBack && (
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              dispatchGTMEvent<GTMEventGlycerinButtonClick>({
                event: 'ButtonClick',
                ButtonClickData: {
                  target: '',
                  category: 'button-click',
                  detail: 'Dialog Header Back',
                },
              });
              if (onBack) {
                onBack();
              }
            }}
            aria-label="back"
          >
            <ArrowMediumLeft32 />
          </IconButton>
        )}
        <Typography
          sx={{ flex: 1, display: 'flex', justifyContent: onBack ? 'center' : 'flex-start' }}
          variant={isDesktop ? 'h4' : 'h5'}
        >
          {title}
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => {
            dispatchGTMEvent<GTMEventGlycerinButtonClick>({
              event: 'ButtonClick',
              ButtonClickData: {
                target: '',
                category: 'button-click',
                detail: 'Dialog Header Close',
              },
            });
            if (onClose) {
              onClose();
            }
          }}
          aria-label="close"
        >
          <Cancel32 />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
