import type { SxProps, Theme } from '@mui/material';
import type { ButtonProps } from '@packages/shared';
import type { EpsRecommendationType } from '../src/components/CmsEpsRecommendation';
import type { HermesShopFinderType } from '../src/components/HermesShopFinder/types';
import type { FooterBlockType } from '../src/components/Footer/types';
import type {
  AccordionType,
  CarouselType,
  CmsImageType,
  CmsInstallmentCalculatorType,
  DynamicYieldType,
  ExternalScriptType,
  GroupType,
  HeadlineType,
  HtmlEditorType,
  HtmlTableEditorType,
  LinkByButtonType,
  LinkByTextType,
  ModalByButtonType,
  ModalByTextlinkType,
  ModalType,
  NewsletterUnsubscribeFormType,
  NewsletterLegacyUnsubscribeFormType,
  PlaceholderType,
  RecommendationType,
  SectionSeperatorType,
  VideoType,
  VoucherType,
  LinkBoxType,
  CategoryTeaserType,
} from '../src/components';
import type { OverlayRectangleType } from '../src/components/OverlayRectangle/types';
import type { OverlayCircleType } from '../src/components/OverlayCircle/types';
import type { JsonApiParagraphMeta } from './jsonapi';
import type { TaxonomyTerm, TaxonomyTermMeta } from './taxonomy';
import type { WebappJsonParagraphMeta } from './webapp_json';
import type { PromotionBannerType } from './promotion';
import type {
  NewsletterSubscribeFormType,
  NewsletterSubscriptionFormType,
} from '../src/components/Forms/NewsletterSubscribeForm/types';

/** Defines the types (components) that are possible for usage */
export enum ComponentTypes {
  Accordion = 'accordion',
  AccordionLocalizable = 'accordion_localizable',
  Button = 'button',
  ButtonModal = 'buttonlink_modal',
  BlockText = 'footer_block_text',
  BlockList = 'footer_block_list',
  BlockImage = 'footer_block_image',
  BlockBadges = 'footer_block_badges',
  DynamicYield = 'dynamic_yield',
  ExternalScript = 'externalscript',
  ExternalScriptLoadbee = 'external_script_loadbee',
  ExternalScriptHappyContest = 'external_script_happycontest',
  OverlayRectangle = 'flexible_teaser',
  OverlayCircle = 'overlay_circle',
  Footer = 'footer',
  FooterGroupImage = 'footer_group_image',
  FromLibrary = 'from_library',
  Group = 'group',
  GroupLocalizable = 'group_localizable',
  GroupApp = 'app_group',
  HermesShopFinder = 'modal_hermes_shopfinder',
  CategoryTeaser = 'category_teaser',
  Headline = 'headline',
  Html = 'html',
  HtmlParagraph = 'paragraph--html',
  Image = 'image',
  ImageGroup = 'imagegroup',
  ImageFooter = 'image_footer',
  ImageSimple = 'image_simple',
  ImageSimpleParagraph = 'paragraph--image_simple',
  InstallmentCalculator = 'installment_calculator',
  LinkByButton = 'link_by_button',
  LinkByText = 'link_by_text',
  List = 'list',
  LinkBox = 'linkbox',
  Liveshopping = 'video_liveshopping',
  Movex = 'movex_mediathek',
  Newsletter = 'newsletter',
  NewsletterSubscription = 'newsletter_subscription',
  NewsletterSubscribe = 'subscribe',
  NewsletterUnsubscribe = 'newsletter_unsubscribe',
  Placeholder = 'placeholder',
  RecoPrudsys = 'reco_prudsys',
  RecoEps = 'reco_eps',
  SectionSeperator = 'section_seperator',
  Carousel = 'slider',
  Socket = 'socket',
  SvgFile = 'svg_file',
  Text = 'text',
  TextlinkModal = 'textlink_modal',
  VoucherList = 'promotion_list',
  Video = 'video_youtube',
}

export type Languages = 'en' | 'de' | 'fr';
export type TextAlignment = 'center' | 'left' | 'right';
export type Size = 'small' | 'medium' | 'large';
export type CtaAlignment = 'below-image' | 'on-image';

export type ElementTypes =
  | 'textlink-below-image'
  | 'textlink-on-image'
  | 'button-below-image'
  | 'button-on-image'
  | 'caption';

export type ButtonType =
  | 'button-default'
  | 'button-primary'
  | 'button-secondary'
  | 'textlink'
  | 'caption'
  | null;

export type LinkTarget = '_none' | '_self' | '_blank' | '_top' | null;

export type Layout =
  | 'base'
  | 'primary'
  | 'secondary'
  | 'greyDark'
  | 'greyLight'
  | 'error'
  | 'warning'
  | 'success'
  | null;

export type Alignment =
  | 'space-between'
  | 'left'
  | 'center'
  | 'right'
  | 'stretch'
  | 'flex-start'
  | 'flex-end'
  | 'bottom'
  | 'top';

export type VisibilityOfficeHours = 'unset' | 'during-office-hours' | 'outside-office-hours';

export type HtmlEditor =
  | {
      value: string;
      format: string;
      processed: string;
    }
  | string
  | null;

export type ImageType = ParagraphMeta & {
  fid?: number;
  type?: 'file--file';
  filename?: string;
  uri?:
    | string
    | {
        url?: string;
        value?: string;
      };
  url?: string;
  data?:
    | {
        width?: string;
        height?: string;
        alt?: string;
        title?: string;
        target_id?: string;
      }
    | []
    | null;
  meta?: {
    width?: string | number;
    height?: string | number;
    alt?: string;
    title?: string;
    target_id?: string;
  };
  filemime?: 'image/png' | 'image/jpeg' | 'image/gif' | 'image/webp';
  filesize?: number;
  uid?: {
    type: 'user--user';
    id: string;
    meta?: { drupal_internal__target_id: number };
  };
  webpUrl?: string;
};

export type ImageFileType = {
  fid: number;
  langcode: Languages;
  filename: string;
  uri: string;
  url: string;
  filemime: string;
  filesize: number;
  status: boolean;
  data: {
    target_id: string;
    display?: string;
    description?: string;
    width?: string;
    height?: string;
    alt?: string;
    title?: string;
  };
  lang: Languages;
  field_name: 'field_svg_file' | 'field_image_default' | 'field_image_mobile';
};

export type Distance = 'variation-1' | 'fullwidth' | 'default' | null;

export type TimeControl = {
  field_valid_from?: string | null;
  field_valid_to?: string | null;
  field_valid_until?: string | null;
  field_visible_from?: string | null;
  field_visible_until?: string | null;
  behavior_paragraph_time_control_valid_from?: string | null;
  behavior_paragraph_time_control_valid_until?: string | null;
  behavior_paragraph_visibility_officehours?: VisibilityOfficeHours;
};

export type MarginVariant = 'none' | 'small' | 'medium' | 'big';

export type Positioning =
  | 'left'
  | 'right'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | null;

type BehaviorTrackingProps = {
  behavior_paragraph_campaign_type?: string | null;
  behavior_paragraph_campaignid?: string | null;
  // for flyout-menu and usp-bar the campaign-id is underscored
  behavior_paragraph_campaign_id?: string | null;
  behavior_paragraph_category?: string | null;
  behavior_paragraph_detail?: string | null;
  behavior_paragraph_name?: string | null;
};

export type ParagraphBehaviors = BehaviorTrackingProps & {
  behavior_paragraph_adserver_id?: string | null;
  behavior_paragraph_alignment?: Alignment | null;
  behavior_paragraph_columns_mobile?: string;
  behavior_paragraph_columns_default?: string;
  behavior_paragraph_cta_alignment?: CtaAlignment | null;
  behavior_paragraph_cta_icon?: boolean | number | null;
  behavior_paragraph_cta_size?: Size | null;
  behavior_paragraph_cta_type?: ButtonType;
  behavior_paragraph_cta_type_and_alignment?: ElementTypes;
  behavior_paragraph_custom_id?: string | null;
  behavior_paragraph_outer_spacing?: string | null;
  behavior_paragraph_outline?: string | null;
  behavior_paragraph_margin?: string | null;
  behavior_paragraph_link_target?: LinkTarget;
  behavior_paragraph_variant?: 'standard' | 'boxed' | 'circle' | 'reactangle' | 'type_only' | null;
  behavior_paragraph_size?: Size | null;
  behavior_paragraph_tooltip_activation?: number | boolean | null;
  behavior_paragraph_tooltip_positioning?: Positioning;
  behavior_paragraph_layout?: ButtonProps['color'] | null;
  behavior_paragraph_time_control_valid_from?: string | null;
  behavior_paragraph_time_control_valid_until?: string | null;
  behavior_paragraph_visibility_officehours?: VisibilityOfficeHours;
  behavior_paragraph_lazy_load?: boolean | null;
  paragraph_campaign_type?: {
    paragraph_campaign_typeTabPlacement: 'group_tracking';
    paragraph_campaign_typeTabPosition: '2';
    paragraph_campaign_type?: string;
  } | null;
  paragraph_campaignid?: {
    paragraph_campaignidTabPlacement: 'group_tracking';
    paragraph_campaignidTabPosition: '1';
    paragraph_campaignid?: string;
  } | null;
  paragraph_category?: {
    paragraph_categoryTabPlacement: 'group_tracking';
    paragraph_categoryTabPosition: '3';
    paragraph_category?: string;
  } | null;
  paragraph_detail?: {
    paragraph_detailTabPlacement: 'group_tracking';
    paragraph_detailTabPosition: '4';
    paragraph_detail?: string;
  } | null;
  paragraph_name?: {
    paragraph_nameTabPlacement: 'group_tracking';
    paragraph_nameTabPosition: '5';
    paragraph_name?: string;
  } | null;
  behavior_paragraph_background_color?: string;
  behavior_paragraph_invert_font_color?: boolean;
  behavior_paragraph_overlay_layout?: string;
  behavior_paragraph_overlay_scale?: '1' | '0.75';
};

export type ParagraphMeta = {
  langcode?: Languages;
  status?: boolean;
  parent_field_name?: string;
  content_translation_changed?: string;
  field_display_app?: boolean;
  field_display_web?: boolean;
  label?: string;
  title?: string;
  description?: string | null;
  behavior_settings?: ParagraphBehaviors | [];
  // GLYCERIN-280 adding helper parameter to setup track component on load not on viewport
  trackOnLoad?: boolean;
} & (JsonApiParagraphMeta | WebappJsonParagraphMeta);

export type ParagraphLibraryType = ParagraphMeta & {
  type: 'from_library';
  paragraphs_library_item: (ParagraphMeta & {
    label: string;
    paragraph: ParagraphType[];
  })[];
};

// Please note that the order of keys in audiences reflects priority from high to low and should NOT be changed
export enum Audiences {
  UserOhnePasswort = 'User ohne Passwort',
  creditAffinity = 'creditAffinity',
  bonusProgram = 'bonusProgram',
  loggedIn = 'logged in',
  anonymous = 'anonymous',
}
export type Audience =
  | 'anonymous'
  | 'logged in'
  | 'bonusProgram'
  | 'creditAffinity'
  | 'User ohne Passwort';

/**
 * Taxonomy describing the Audience for a paragraph
 *
 * Each element of the array is an object that extends the TaxonomyTerm type, and:
 * - Has a 'name' property of type Audience.
 * - Has a 'field_name' property with the value 'field_audience'.
 * - includes an array of type TaxonomyTermMeta with the key that is the value of field 'name'
 */
export type AudienceTaxonomy = {
  taxonomy_term?: (TaxonomyTerm & {
    name: Audience;
    field_name: 'field_audience';
  } & {
    [K in Audience]?: TaxonomyTermMeta[];
  })[];
};

export interface LinkData {
  field_link?: string | null;
  field_link_anchor?: string | null;
  field_link_highlight?: string | null;
  field_link_searchfilter?: string | null;
  field_link_target?: LinkTarget;
  field_link_title?: string | null;
  field_persist_target?: boolean | null;
  field_tracking?: string | null;
  field_bonus_number?: string | null;
  behavior_paragraph_link_target?: LinkTarget;
}

export interface ImageData {
  file?: ImageFileType[];
  image_default?: ImageType;
  image_mobile?: ImageType;
  image_tablet?: ImageType;
  field_image_default?: ImageType;
  field_image_mobile?: ImageType;
  field_image_paragraphs?: ImageType;
  field_image_size_type?: string | null;
  field_lazyload?: boolean;
  field_alternate_text?: string | null;
}

export type ParagraphType =
  | AccordionType
  | CarouselType
  | CategoryTeaserType
  | CmsImageType
  | CmsInstallmentCalculatorType
  | DynamicYieldType
  | ExternalScriptType
  | FooterBlockType
  | OverlayRectangleType
  | OverlayCircleType
  | GroupType
  | HeadlineType
  | HermesShopFinderType
  | HtmlEditorType
  | HtmlTableEditorType
  | LinkByButtonType
  | LinkByTextType
  | LinkBoxType
  | ModalByButtonType
  | ModalByTextlinkType
  | ModalType
  | NewsletterUnsubscribeFormType
  | NewsletterLegacyUnsubscribeFormType
  | NewsletterSubscribeFormType
  | NewsletterSubscriptionFormType
  | PromotionBannerType
  | RecommendationType
  | EpsRecommendationType
  | ParagraphLibraryType
  | PlaceholderType
  | SectionSeperatorType
  | VoucherType
  | VideoType;

export type DrupalIconTypes = 'ArrowLeft' | 'ArrowRight' | null;
export type RecognizerComponentProps<T extends ParagraphType> = {
  data: T;
  sx?: SxProps<Theme>;
  component?: 'div' | 'span' | 'p';
};

export type DrupalGlycerinCampaignTracking = {
  behavior_paragraph_campaign_type?: string | null;
  behavior_paragraph_campaignid?: string | null;
  // for flyout-menu and usp-bar the campaign-id is underscored
  behavior_paragraph_campaign_id?: string | null;
  behavior_paragraph_category?: string | null;
  behavior_paragraph_detail?: string | null;
  behavior_paragraph_name?: string | null;
};
