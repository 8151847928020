import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgAdressbook32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M28.458 8c.299 0 .542.243.542.542v15.916a.542.542 0 0 1-.542.542h-3.25c-.299 0-.569-.704-.632-.807a.544.544 0 0 1 0-.554.508.508 0 0 0 .091-.264.542.542 0 0 0-1.084 0 .52.52 0 0 0 .09.265.54.54 0 0 1 0 .554c-.062.102-.332.806-.631.806h-3.25c-.3 0-.57-.704-.632-.807a.544.544 0 0 1 0-.554.515.515 0 0 0 .09-.264.542.542 0 1 0-1.083 0 .52.52 0 0 0 .09.265.544.544 0 0 1 0 .554c-.062.102-.333.806-.632.806h-3.25c-.299 0-.57-.704-.632-.807a.544.544 0 0 1 0-.554.515.515 0 0 0 .09-.264.542.542 0 1 0-1.083 0 .52.52 0 0 0 .09.265.54.54 0 0 1 0 .554c-.062.102-.333.806-.632.806h-3.25c-.299 0-.569-.704-.631-.807a.54.54 0 0 1 0-.554.512.512 0 0 0 .089-.264.542.542 0 1 0-1.083 0 .52.52 0 0 0 .09.265.54.54 0 0 1 0 .554c-.062.102-.332.806-.632.806h-3.25A.542.542 0 0 1 3 24.458V8.542C3 8.243 3.243 8 3.541 8ZM28 9H4v15h2.405l.04-.083a1.4 1.4 0 0 1-.112-.542c0-.85.692-1.542 1.542-1.542.85 0 1.541.692 1.541 1.542 0 .183-.036.362-.111.542.011.025.026.053.041.083h2.475l.041-.083a1.396 1.396 0 0 1-.112-.542c0-.85.691-1.542 1.541-1.542.851 0 1.542.692 1.542 1.542 0 .183-.037.362-.111.542l.04.083h2.476a1.97 1.97 0 0 0 .04-.083 1.398 1.398 0 0 1-.111-.542c0-.85.691-1.542 1.541-1.542.851 0 1.542.692 1.542 1.542 0 .183-.037.362-.112.542.011.025.026.053.041.083h2.475c.016-.03.03-.058.041-.083a1.4 1.4 0 0 1-.112-.542c0-.85.692-1.542 1.542-1.542.85 0 1.542.692 1.542 1.542 0 .183-.037.362-.112.542.011.025.025.053.041.083H28zm-15.471 6.837S15 18.328 15 21.03H5.046V21c.007-.352.13-3.817 2.506-5.163 0 0 2.271 1.925 4.977 0M7.57 17.044c-.423.372-.76.88-1.014 1.503-.191.467-.325.971-.41 1.482h7.721c-.17-.67-.496-1.37-.945-2.067-.19-.297-.392-.576-.596-.832-1.573.808-3.14.712-4.526.033-.08-.04-.157-.08-.23-.119M25.5 18a.5.5 0 1 1 0 1h-8a.5.5 0 1 1 0-1zM10 10a3 3 0 1 1 0 6 3 3 0 0 1 0-6m12.5 5a.5.5 0 1 1 0 1h-5a.5.5 0 1 1 0-1zM10 11a2 2 0 1 0 0 4 2 2 0 0 0 0-4m15.5 1a.5.5 0 1 1 0 1h-8a.5.5 0 1 1 0-1z" className="adressbook-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgAdressbook32);
export default Memo;