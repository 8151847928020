import type { InstallmentCalculatorConfig } from './installmentCalculator.types';

export const installmentCalculatorConfig: InstallmentCalculatorConfig = {
  hasLegalHints: false,
  legalHintsImage: null,
  hasInfoCallToAction: true,
  InstallmentCalculatorCallToActionLayout: { layout: 'Layout1' },
  hasSpecialCreditColors: false,
  resetInstallmentsOnPriceChange: true,
};
