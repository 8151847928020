import { useIntl } from 'react-intl';
import useSWR from 'swr';
import { headerDefaultFetcher } from '../../api';
import type { ServiceMenuData } from './ServiceMenuTypes';

export const useServiceMenuData = (serviceApi: string | undefined) => {
  const { locale } = useIntl();
  const { data } = useSWR<ServiceMenuData>(
    serviceApi!.replace('<locale>', locale.split('-')[0]),
    headerDefaultFetcher,
    { suspense: true },
  );
  // after swr correctly implements suspense pattern, remove non-null assertion operator
  return Object.values(data!).slice(0, -1);
};
